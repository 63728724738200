@font-face {
  font-family: 'icons';
  src: url('../fonts/icons/icons.eot?51503659');
  src: url('../fonts/icons/icons.eot?51503659#iefix') format('embedded-opentype'),
       url('../fonts/icons/icons.woff2?51503659') format('woff2'),
       url('../fonts/icons/icons.woff?51503659') format('woff'),
       url('../fonts/icons/icons.ttf?51503659') format('truetype'),
       url('../fonts/icons/icons.svg?51503659#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icons';
    src: url('../fonts/icons/icons.svg?51503659#icons') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-info:before { content: '\e800'; } /* '' */
.icon-left-open-big:before { content: '\e801'; } /* '' */
.icon-right-open-big:before { content: '\e802'; } /* '' */
.icon-search:before { content: '\e803'; } /* '' */
.icon-cancel-1:before { content: '\e804'; } /* '' */
.icon-tag:before { content: '\e805'; } /* '' */
.icon-download-alt:before { content: '\e806'; } /* '' */
.icon-videocam:before { content: '\e807'; } /* '' */
.icon-picture:before { content: '\e808'; } /* '' */
.icon-calendar:before { content: '\e809'; } /* '' */
.icon-info-circled:before { content: '\e80a'; } /* '' */
.icon-home:before { content: '\e80b'; } /* '' */
.icon-basket:before { content: '\e80c'; } /* '' */
.icon-pause:before { content: '\e80f'; } /* '' */
.icon-play:before { content: '\e810'; } /* '' */
.icon-stop:before { content: '\e811'; } /* '' */
.icon-to-end:before { content: '\e812'; } /* '' */
.icon-to-start:before { content: '\e813'; } /* '' */
.icon-heart:before { content: '\e814'; } /* '' */
.icon-heart-broken:before { content: '\e815'; } /* '' */
.icon-star:before { content: '\e816'; } /* '' */
.icon-star-empty:before { content: '\e817'; } /* '' */
.icon-thumbs-up:before { content: '\e818'; } /* '' */
.icon-thumbs-down:before { content: '\e819'; } /* '' */
.icon-thumbs-up-alt:before { content: '\e81a'; } /* '' */
.icon-thumbs-down-alt:before { content: '\e81b'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-tumblr:before { content: '\f173'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */
.icon-angle-double-right:before { content: '\f193'; } /* '' */
.icon-angle-double-up:before { content: '\f194'; } /* '' */
.icon-angle-double-down:before { content: '\f195'; } /* '' */
.icon-angle-double-left:before { content: '\f196'; } /* '' */
.icon-stumbleupon:before { content: '\f1a4'; } /* '' */
.icon-delicious:before { content: '\f1a5'; } /* '' */
.icon-digg:before { content: '\f1a6'; } /* '' */
.icon-soundcloud:before { content: '\f1be'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-audio:before { content: '\f1c7'; } /* '' */
.icon-circle-thin:before { content: '\f1db'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */
.icon-reddit-alien:before { content: '\f281'; } /* '' */