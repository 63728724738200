.paragraphs-item-component {
  .component-title {
    font-weight: bold;
    margin: 0 0 .5em;
    font-family: $heading-font;
    font-size: 1.75em;
    color: $secondary-color;
  }

  .field-name-field-image {
    text-align: center;
  }

  .field-name-field-body {

  }
  .field-name-field-link {
    text-align: center;
    margin: 1em auto;
    a {
      @include cta;
    }
  }
}