@import 'icons';

@mixin icon() {

  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  //width: 1em;
  //margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  font-size:1rem;
  line-height: 1;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  //margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  font-size: 1rem;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

}

$icon-info: '\e800'; /* '' */
$icon-left-open-big: '\e801'; /* '' */
$icon-right-open-big: '\e802'; /* '' */
$icon-search: '\e803'; /* '' */
$icon-cancel-1: '\e804'; /* '' */
$icon-tag: '\e805'; /* '' */
$icon-download-alt: '\e806'; /* '' */
$icon-videocam: '\e807'; /* '' */
$icon-picture: '\e808'; /* '' */
$icon-calendar: '\e809'; /* '' */
$icon-info-circled: '\e80a'; /* '' */
$icon-home: '\e80b'; /* '' */
$icon-basket: '\e80c'; /* '' */
$icon-pause: '\e80f'; /* '' */
$icon-play: '\e810'; /* '' */
$icon-stop: '\e811'; /* '' */
$icon-to-end: '\e812'; /* '' */
$icon-to-start: '\e813'; /* '' */
$icon-twitter: '\f099'; /* '' */
$icon-facebook: '\f09a'; /* '' */
$icon-menu: '\f0c9'; /* '' */
$icon-gplus: '\f0d5'; /* '' */
$icon-linkedin: '\f0e1'; /* '' */
$icon-doc-text: '\f0f6'; /* '' */
$icon-angle-left: '\f104'; /* '' */
$icon-angle-right: '\f105'; /* '' */
$icon-angle-up: '\f106'; /* '' */
$icon-angle-down: '\f107'; /* '' */
$icon-circle-empty: '\f10c'; /* '' */
$icon-youtube: '\f167'; /* '' */
$icon-youtube-play: '\f16a'; /* '' */
$icon-instagram: '\f16d'; /* '' */
$icon-tumblr: '\f173'; /* '' */
$icon-dot-circled: '\f192'; /* '' */
$icon-angle-double-right: '\f193'; /* '' */
$icon-angle-double-up: '\f194'; /* '' */
$icon-angle-double-down: '\f195'; /* '' */
$icon-angle-double-left: '\f196'; /* '' */
$icon-stumbleupon: '\f1a4'; /* '' */
$icon-delicious: '\f1a5'; /* '' */
$icon-digg: '\f1a6'; /* '' */
$icon-soundcloud: '\f1be'; /* '' */
$icon-file-pdf: '\f1c1'; /* '' */
$icon-file-word: '\f1c2'; /* '' */
$icon-file-audio: '\f1c7'; /* '' */
$icon-circle-thin: '\f1db'; /* '' */
$icon-pinterest: '\f231'; /* '' */
$icon-reddit-alien: '\f281'; /* '' */

$icon-heart: '\e814'; /* '' */
$icon-heart-broken: '\e815'; /* '' */

$icon-star: '\e816'; /* '' */
$icon-star-empty: '\e817'; /* '' */

$icon-thumbs-up: '\e818'; /* '' */
$icon-thumbs-down: '\e819'; /* '' */
$icon-thumbs-up-alt: '\e81a'; /* '' */
$icon-thumbs-down-alt: '\e81b'; /* '' */