// _base.scss imports _custom.scss (variables and mixins) and other partials.
//@import "base";

/**
 * @file
 * global.styles
 *
 * Styles you add here will load for all device sizes, they are "global", as
 * opposed to "responsive" styles, which only load for a given breakpoint (media
 * query) that you set in theme settings. See the README for more details.
 *
 *
 * How to use this file:
 * --------------------
 *
 * There are many empty selectors and some basic styles to act as a guide.
 * Nothing is set in stone and you can change anything - even delete all of it
 * and start with your own clean slate.
 *
 * To FORCE PRINT all selectors you can search and replace a single { (opening
 * curly brace) and insert an empty comment. This is very useful in conjuction
 * with FireSass: https://addons.mozilla.org/en-US/firefox/addon/firesass-for-firebug/
 *
 * See global.base also which includes normalize.css and some additional
 * helper classes and base styles.
 */

/* =============================================================================
 *   Base
 * ========================================================================== */

/**
 * To preserve theme settings never apply font properties to the HTML element.
 * This is critically important if you are using the Responsive JavaScript
 * feature as this relies on being able to set a pseudo font family on the HTML
 * element. If you need to set default font properties of any kind use the BODY
 * element as these can be overridden using font theme settings.
 */

html {
  background: $page;
  font-size: 100%;
  min-height: 100%;

  .cke_editable {
    box-sizing: border-box;
    padding: 1em;
    background: $white;
    min-height: 200px;
  }
}

body {
  font-family: $base-font; // Set from _custom.scss font variables
  color: $black;
  min-width: 18em;
  overflow-x: hidden;
}

/* =============================================================================
 *   HTML Elements
 * ========================================================================== */
h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
  color: $black;

  a {
    color: inherit;

    :hover {
      text-decoration: none;
    }
  }
}

h1 {
  font-size: 2.5em;
  line-height: 1.2;
  font-weight: normal;

  .site-name-long & {
    font-size: 20px;
    line-height: 25px;
  }

  .site-name-long-2-lines & {
    font-size: 20px;
    line-height: 25px;
  }
}

h2 {
  //color: $primary-color;
  font-size: 2em;
}

h3 {
  //color: $primary-color;
  font-size: 1.4em;

  .sidebar & {
    color: $gray;
  }
}

pre,
code,
tt,
samp,
kbd,
var {
  font-family: $mono;
}

/* =============================================================================
 *   Wrappers
 * ========================================================================== */

/**
 * Page wrapper, includes the .container class which sets the overall page or section width
 * Main wrapper in page.tpl.php, the .container class sets the width of the page, do not add width to it!
 */

/**
 * Wraps the sidebars the content column
 */

#columns {
  margin-right: $gutter-width;
  margin-left: $gutter-width;
}

/**
 * Default credit block
 */

#block-block-1 {
  .block-title,
  .block-content {
    color: $light_gray;
    text-align: center;
  }

  .block-title {
    margin: $gutter-width 0 $gutter-width / 2;
  }

  .block-content {
    font-size: .88em;
  }

  #footer & {
    margin-bottom: 0;
  }
}

/**
 * Generic pod wrappers
 */

.commons-pod {
  @extend %pod;
  @extend %pod-content;

  /* Prevent pod nesting. */
  .commons-pod {
    margin: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
}

/* =============================================================================
 *   Misc Global Styles
 * ========================================================================== */

/**
 * Generic styles for nodes.
 */

.node {
  &.node-event,
  &.node-poll,
  &.node-question {
    @extend %node-space-right;
  }
}

.node-actions {
  box-sizing: border-box;
  float: right;
  width: 10em;
  list-style: none;
  margin: .5em -10em 0 0;
  padding-left: 1em;
  text-align: center;

  [class*="action-item"] {
    &,
    &[type="submit"] {
      margin: 0;
    }
  }

  @include respond(small-medium) {
    float: none;
    width: auto;
    margin: 1.7em 0;
    text-align: left;
  }
}

.node-teaser {
  &.node-promoted {
    //background-image: url('../img/node-promoted-star.png');
    //background-position: top right;
    //background-repeat: no-repeat;
    position: relative;
    background-color: darken($white, 3%);
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      @include icon;
      content: $icon-star;
      color: $secondary-color;
      margin: .5rem;
      font-size: 2rem;
    }
    .flag-wrapper {
      top: 2em;
    }

    &.user-picture-available {
      //@include place-picture(50px, 10px);
    }

    &.node-event,
    &.node-poll,
    &.node-question {
      @extend %node-space-right;
    }
  }
}

.user-picture,
.views-field-picture,
.profile-badge-picture {
  background: $black;
  display: inline-block;
  overflow: hidden;
  border-radius: 100%;
  line-height: 0;

  .node &,
  .comment &,
  .privatemsg-message & {
    &:after {
      content: "";
      width: 5px;
      height: 8px;
      position: absolute;
      right: -5px;
      top: 10px;
      background: url('../img/user_img_arrow.png');

    }

    img {
      width: 50px;
    }
  }
}

/**
 * Form styles
 *
 * These styles are extracted from the Formalize library by Nathan Smith. The
 * specific support for IE below 8 is trimmed along with references to font
 * families and the extra buttons styles.
 */

input,
button,
select,
textarea,
[class*=" action-item"] {
  // Suppress red glow that Firefox
  // adds to form fields by default.
  &:invalid {
    box-shadow: none;
  }
  // Add blue glow.
  &:focus {
    box-shadow: #0066ff 0 0 5px 0;
    // for Opera
    z-index: 1;
  }
}

label {
  margin-top: .5em;
  margin-bottom: .5em;
}

input[type="file"],
input[type="radio"],
input[type="checkbox"] {
  &:focus,
  &:active {
    box-shadow: none;
  }
}

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  box-sizing: border-box;
  @include background-clip(padding-box);
  border-radius: 2px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid $border;
  color: black;
  outline: 0;
  margin: 0;
  padding: .5em;
  text-align: left;
  vertical-align: top;
  line-height: 1.2em;

  &[disabled] {
    background-color: #eeeeee;
  }
}

input,
textarea {
  &::-webkit-input-placeholder {
    color: #888888;
  }
}

input,
textarea {
  &:-moz-placeholder {
    color: #888888;
  }
}

textarea,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  width: 100%;
  max-width: 100%;
}

textarea,
select[size],
select[multiple] {
  height: auto;
}

// Tweaks for Safari + Chrome.
@media (-webkit-min-device-pixel-ratio: 0) {
  select[size],
  select[multiple],
  select[multiple][size] {
    background-image: none;
    padding-right: 3px;
  }

  select,
  select[size="0"],
  select[size="1"] {
    border-radius: 3px;
    // Base64 encoded "../images/select_arrow.gif"
    background-image: url(data:image/png;base64,R0lGODlhDQAEAIAAAAAAAP8A/yH5BAEHAAEALAAAAAANAAQAAAILhA+hG5jMDpxvhgIAOw==);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
  }
  ::-webkit-validation-bubble-message {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #666666), color-stop(1, black));
    border: 1px solid;
    border-color: #747474 #5e5e5e #4f4f4f;
    color: white;
    overflow: hidden;
    padding: 15px 15px 17px;
    text-shadow: black 0 0 1px;
    height: 16px;
  }
  ::-webkit-validation-bubble-arrow,
  ::-webkit-validation-bubble-top-outer-arrow,
  ::-webkit-validation-bubble-top-inner-arrow {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #666666;
    border: 0;
  }
}

textarea {
  overflow: auto;
  resize: vertical;
  width: 100%;
}

optgroup {
  color: black;
  font-style: normal;
  font-weight: normal;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

/* Simulate a chosen select form value as inline text. */
.hidden-select-wrapper {
  position: relative;
  display: inline-block;
  @include single-transition(width, .2s);
}

.select-status {
  display: inline-block;
  white-space: nowrap;
  @include single-transition(opacity, .2s);

  &:after {
    content: "";
    position: relative;
    top: .2em;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .2em;
    border: .4em solid transparent;
    border-top-color: $primary-color;
  }
}

.hidden-select {
  position: absolute;
  top: -.35em;
  left: -.35em;
  margin: 0;
  @include opacity(0);

  .select-inactive & {
    width: 100%;
  }
}

/* Replace the default Drupal throbber with a custom one. */
html.js {
  input.form-autocomplete {
    background-image: none;
    background-position: right center;
    padding-right: 20px;

    &.throbbing {
      background-image: image-url("commons-throbber.gif");
      background-position: right center;
    }
  }
}

.general-settings {
  padding-top: 0;
  padding-bottom: 0;
}

.fieldset-outer-wrapper {
  margin: 1.7em 0;
  padding: 1.7em 0;
  border-top: 1px solid $border;
  border-color: $border;
  border-style: solid;
  border-width: 1px 0;

  + .fieldset-outer-wrapper {
    border-top-width: 0;
  }

  .general-settings & {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: $gutter-width;
    padding-right: $gutter-width;
  }

  .general-settings > & {
    margin-left: -$gutter-width;
    margin-right: -$gutter-width;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom-width: 0;
    }
  }

  /* Some form items have nested fieldsets that mess with the sibling
     selectors and need some extra love. */
  .field-name-og-group-ref & {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  .field-name-og-group-ref &,
  .field-type-datetime-form & {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

fieldset {
  border: none;
  margin: 0;
  padding: .01em 0;
  box-sizing: border-box;
}

legend {
  -webkit-padding-start: 0;
  -webkit-padding-end: 0;
  line-height: 1.2em;

  // Keep legends from hopping around in Safari.
  + .fieldset-wrapper {
    -webkit-margin-top-collapse: separate;
    margin-top: 1.2em;
  }
}

.fieldset-legend {
  display: block;
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: light;

  html.js fieldset.collapsed &,
  html.js fieldset.collapsible & {
    padding: 0;
    background: none;
  }

  span.summary {
    display: block;
    margin: 0;
    font-size: .76em;
    font-weight: normal;
  }

  .collapsible > legend & {
    display: block;
    text-indent: -.5em;
    margin-left: .6em;

    span.summary {
      margin-left: .7em;
    }

    a:before {
      display: inline-block;
      position: relative;
      margin-right: .2em;
      width: 0;
      height: 0;
      overflow: hidden;
      border: .2em solid transparent;
      border-top-color: $dark_gray;
      content: "";
      top: -.1em;
      left: -.1em;
    }
  }

  .collapsed > legend & {
    a:before {
      border-top-color: transparent;
      border-left-color: $dark_gray;
      top: -.2em;
      left: auto;
    }
  }
}

.form-item {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.filter-wrapper {
  margin: 0;
  padding: 0;
}

/**
 * Button styles
 */

// Generate the invisible classes that we will extend.
@include buttons;

.button,
button,
[type="reset"],
[type="submit"],
[type="button"],
[class*="action-item"] {
  @extend %button-default-layout;
  @extend %button-default;
  max-width: 100%;

  &:not(.action-item-inline, .action-item-merge) {
    @include respond(only-small) {
      display: block;
      width: 100%;
      margin: .5em 0;
    }
  }

  #panels-ipe-control-container & {
    text-shadow: none;
  }
}

[class*="action-item-small"] {
  @extend %button-small-layout;
}

[class*="action-item-large"] {
  @extend %button-large-layout;
}

.action-item-active,
.action-item-small-active,
.action-item-large-active {
  &,
  &[type="reset"],
  &[type="submit"],
  &[type="button"] {
    @extend %button-active;
  }
}

.action-item-primary,
.action-item-small-primary,
.action-item-large-primary {
  &,
  &[type="reset"],
  &[type="submit"],
  &[type="button"] {
    @extend %button-primary;
  }
}

.action-item-primary-active,
.action-item-small-primary-active,
.action-item-large-primary-active {
  &,
  &[type="reset"],
  &[type="submit"],
  &[type="button"] {
    @extend %button-primary-active;
  }
}

.action-item-merge, {
  .action-item-merge + & {
    @include border-top-left-radius(0);
    @include border-bottom-left-radius(0);
    margin-left: -.5em;
  }
}

.action-item-append,
.action-item-small-append,
.action-item-large-append {
  position: relative;
  padding-right: .5em;
  padding-left: .5em;

  &,
  &:active,
  &:focus,
  &:hover {
    background: $border;
    border-color: $light_gray;
    color: $white;
    cursor: default;
    text-shadow: none;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    border-color: transparent;
    border-style: solid;
  }

  &:before {
    left: -10px;
    margin-top: -5px;
    border-width: 5px;
    border-right-color: $light_gray;
  }

  &:after {
    left: -8px;
    margin-top: -4px;
    border-width: 4px;
    border-right-color: $border;
  }
}

/* Reset some styles for the admin toolbar. */
.drupal-navbar {
  .icon.handle {
    margin: 0;
    padding: 0;

    &:hover {
      background: none;
    }
  }
}

/* Make the disabled buttons subdued. */
[disabled] {
  &,
  & option,
  & optgroup {
    @include opacity(.6);
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    cursor: normal;
  }
}

/**
 * Specialty buttons
 */

/* Like widget */
.rate-widget-commons_like {
  white-space: nowrap;
}

.rate-commons-like-btn {

  background: $white;

  &:before {
    @include icon;
    content: $icon-heart;
    margin-top: -2px;
    margin-bottom: -2px;
    color: $secondary-color;
    text-shadow: none;
  }
  &:hover {
    &:before {
      color: $white;
    }
  }
}

/* Follow buttons */
[class*="flag-commons-follow-"] {
  .flag-action {
    white-space: nowrap;

    &:before {
      content: "";
      @include sprite_url_height_width($icons, follow-large);
    }
  }

  a.unflag-action {
    @extend %button-active;
  }

  .flag-throbber {
    display: none;
  }

  .flag-message {
    display: none !important;
  }
}

/* Report as inappropriate */
.flag-inappropriate-node,
.flag-inappropriate-comment, {
  display: block;
  clear: both;
  margin: 1em 0 0;

  .flag-action {
    &:before {
      content: "";
      @include sprite_url_height_width($icons, spam);
    }
  }
}

/* ShareThis */
.sharethis-buttons {
  position: relative;

  &:before {
    content: "";
    @include sprite_url_height_width($icons, share);
  }
}

.sharethis-wrapper {
  position: absolute;
  left: 0;
  bottom: 80%;
  @include opacity(0);
  visibility: hidden;
  white-space: nowrap;
  background: $white;
  border: 1px solid $green;
  border-radius: 2px;
  padding: $gutter_width;
  margin-bottom: $gutter_width;
  @include transition(opacity .3s ease .4s, bottom .3s ease .4s, visibility .3s ease .4s);
  z-index: 9;

  &:before,
  &:after {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    position: absolute;
    top: 100%;
    z-index: 10;
  }

  &:before {
    border-width: $gutter_width;
    border-top-color: $green;
    left: $gutter_width;
  }

  &:after {
    border-width: ($gutter_width - 1);
    border-top-color: $white;
    left: ($gutter_width + 1);
  }

  > span {
    display: inline-block;
    margin-top: -5px;
  }

  .sharethis:hover &,
  .sharethis-buttons:active & {
    @include opacity(1);
    visibility: visible;
    bottom: 100%;
  }

  .sharethis-buttons:active & {
    @include transition-delay(0s);
  }
}

/* Comments */
.comment-comments {
  white-space: nowrap;

  .action-item-small {
    /*padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;*/

    &:before {
      //content: "";
      //@include sprite_url_height_width($icons, comments-dark);
    }
  }

  .action-item-small-append {
    /*margin: -1px -1px -1px 1em;
    font-size: 1em;
    // min-height: 1.8em;
    @include border-top-left-radius(0);
    @include border-bottom-left-radius(0);*/
  }
}

/* Drupal specific field styling. */
.field-label-inline {
  margin: 1.7em 0;

  .field-label,
  .field-items,
  .field-item {
    display: inline;
    float: none;
  }

  .field-item {
    margin: 0 .1em;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:after {
      content: ", ";
    }

    &:last-child {
      &:after {
        content: "";
      }
    }
  }

  .field-label {
    font-weight: bold;
    color: $dark_gray;
  }
}

/**
 * User avatar
 */

.user-picture-available {
  //position: relative;
  //@include place-picture;
}

/**
 * Filter drawer accessories
 */

.filter-trigger {
  display: none;
  background: white;
  box-sizing: border-box;
  padding: 5px;
  position: absolute;
  top: 0;
  left: -39px;
  width: 40px;
  border: 1px solid $border;
  border-right: none;
  border-radius: 3px 0 0 3px;
  text-align: center;
  font: 0/0 a;
  line-height: 0;
  color: transparent;
  box-shadow: rgba($dark_gray, .4) -1px 1px 2px;
  cursor: pointer;
  z-index: 1;

  &:after {
    content: "\00ab";
    display: block;
    font-size: $base-font-size * 2.4;
    line-height: .8em;
    padding-bottom: .15em;
    color: $primary-color;

    .expanded & {
      content: "\00bb";
    }
  }

  &.following {
    position: fixed;
    right: 0;
    left: auto;
  }
}

.filters-processed {
  &.expanded {
    .region-inner {
      position: relative;
      border: 1px solid $border;
      border-right: none;
      z-index: 0;
      box-shadow: rgba($dark_gray, .4) -1px 1px 2px;
    }
  }
}

.filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, .8);
}

/**
 * Aggregator feed source
 */

#aggregator .feed-source .feed-icon {
  display: inline;
  float: none;
  margin-right: 10px;
}

.feed-details dt,
.feed-details dd {
  display: inline;
  margin: 0;
}

/**
 * Generic styles for links. See the ul.links declaration in node and comment stylesheets
 */

ul.links {
  margin: 0;
  padding: 0;

  &.inline {
    display: block;
  }

  li {
    display: inline-block;
    list-style: none;
  }
}

.quicktabs-style-commons-pills ul.quicktabs-tabs,
ul.primary.tabs {
  border-bottom: none;
  padding: 0;

  li {
    margin-bottom: 5px;
    margin-top: 5px;

    a {
      background-color: $tab_background;
      border-radius: 3px;
      padding: .3em .8em;
      display: inline-block;
      border: none;

      &,
      span {
        color: $tab_text;
      }

      &:hover {
        text-decoration: none;
        background-color: $tab_background;
        color: $tab_text_hover;
      }
    }

    &.active a {
      position: relative;
      background-color: $tab_active_background;
      border: none;

      &,
      &:hover {
        background-color: $tab_active_background;
      }

      &,
      span {
        color: $tab_active_text;
      }

      &:after {
        position: absolute;
        bottom: -.7em;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
        margin-left: -.6em;
        content: "";
        border-width: .4em .6em;
        border-style: solid;
        border-color: $tab_active_background transparent transparent;
      }
    }
  }
}

.quicktabs-style-commons-pills ul.quicktabs-tabs li {
  text-transform: uppercase;
  font-size: .8em;
}

/**
 * Main wrapper for most blocks, block_system_main does not have it
 */

.block {
  margin-bottom: 20px;
}

.block-title {
  font-size: 1.5em;
  line-height: 1.2em;
  margin-top: 0;
  margin-bottom: .4em;
  color: $black;
  font-family: $heading-font;
}

/**
 * Match item list and block menu margin and padding
 */

.block-content {
  ul,
  ol {
    padding: 0 0 0 15px;
  }

  li {
    margin: 0;
    padding: 0;
  }
}

/* Views exposed form */
.views-exposed-form {
  .views-exposed-widget {
    float: none;
    padding: 0;
    margin: 1.7em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .form-submit {
      margin-top: 0;
    }
  }
}

.keyword-filter {
  .views-widget-filter-keys,
  .views-submit-button {
    float: left;
    box-sizing: border-box;
    margin-top: 0;
  }

  .views-widget-filter-keys {
    margin-right: -6.5em;
    margin-bottom: 0;
    padding-right: 6.75em;
    width: 100%;

    label {
      margin-top: 0;
    }

    @include respond(small-medium) {
      margin-right: 0;
      padding-right: 0;
    }
  }

  .views-submit-button {
    padding-top: 2.15em;

    @include respond(small-medium) {
      margin-top: .5em;
      padding-top: 0;
    }
  }
}

/* Generic row styles */
.views-row {

  position: relative;

  .views-row {

    padding-bottom: 0;
  }

  & .views-row,
  &.views-row-last {

  }

  &.views-row-last {

  }

  .view-plain & {

    &:first-child {
    }

    &:last-child {
    }
  }
}

// Load the post icon styles.
@import 'post-icons';

/* Titles fields */
.views-field-title {
  font-weight: 500;
  line-height: 1.4em;
}

/* Meta data */
.views-field-field-date,
.views-field-timestamp,
.views-field-field-location,
.views-field-capacity-used,
.views-field-comment-count {
  font-size: 0.88em;
}

.views-field-timestamp,
.views-field-field-location,
.views-field-capacity-used,
.views-field-comment-count {
  color: $light_gray;

  strong {
    color: $dark_gray;
  }
}

.views-field-timestamp,
.views-field-field-location,
.views-field-capacity-used,
.views-field-comment-count {
  display: inline-block;
  margin-right: .3em;
}

/* User picture wrapping and shadow */
.view-commons-homepage-content,
.view-commons-activity-streams-activity,
.view-activity-group,
.view-commons-activity-streams-user-activity {
  .content,
  .message,
  .views-field-timestamp,
  .views-field-nothing {
    @include place-picture(40px);
  }
}

.view-activity-group,
.view-commons-activity-streams-activity,
.view-commons-activity-streams-user-activity {
  .views-row {
    h2 {
      display: none;
    }

    .user-picture {
      top: 5px;

      img {
        width: 40px;
      }
    }
  }
}

.page-node {
  .field-label {
    color: #369;
  }

  .field-name-field-topics,
  .field-name-og-group-ref {
    margin: 0;

    .field-label {
      display: block;
    }

    &.view-mode-full {
      float: left;
      width: 50%;

      .field-items {
        margin: 0;
        list-style: none;
        padding: 0;

        .field-item {
          display: inline-block;
          margin: 0 .3em;
          padding: 0;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &:after {
            content: "";
          }
        }
      }

      @include respond(only-small) {
        float: none;
        width: auto;
        margin: 1.7em 0;
      }
    }
  }

  .field-name-field-topics {
    .field-item:before {
      content: "";
      @include sprite_url_height_width($icons, tags);
      top: -1px;
    }
  }

  .field-name-og-group-ref {
    .field-item:before {
      content: "";
      @include sprite_url_height_width($icons, groups);
      top: -1px;
    }
  }

  .node nav {
    clear: both;
    margin-top: 1em;
    margin-bottom: 2em;
    position: relative;

    .links {
      margin-top: 0;
      padding-left: 0;
      .en {
        display: none;
      }
    }
  }
}

#comments {
  margin: 0;

  .form-item-subject input {
    width: 100%;
  }

  .rate-widget-commons_like {
    position: absolute;
    right: 5px;
    top: 15px;
  }
}

.comments-title,
.comment-form-title {
  margin-top: 0;
  font-size: 2.2em;
  line-height: 1.2em;
  color: $dark_gray;
}

.comments-title {
  &:before {
    content: "";
    @include sprite_url_height_width($icons, comments-large);
  }
}

.comment-form {
  margin-bottom: 0;
  &:before {
    display: none;
  }
}

.comment {
  @include place-picture;
  border-bottom: 1px solid $lighter_gray;
  padding-bottom: 20px;
  position: relative;

  .author-datetime {
    margin-bottom: 1.4em;
  }
}

.comment-title,
.comment .author-datetime {
  margin-right: 90px;
}

.comment-title {
  margin-bottom: .2em;
}

ul.comment-links {
  margin: .7em 0;

  li {
    padding-left: 0;
  }
}

.comment-reply {
  float: right;

  &:before {
    content: "";
    @include sprite_url_height_width($icons, comments);
    margin-right: 8px;
    top: 3px;
  }
}

.comment-form {
  .form-actions {
    margin-bottom: 0;
  }
}

.primary-fields,
.supplementary-fields {
  @include inline-block;
  box-sizing: border-box;
  float: left;

  .view-commons-question-answers & {
    display: block;
    float: none;
    width: auto;
    padding: 0;
  }
}

.primary-fields {
  padding-right: $gutter-width;
  width: 66.666%;
}

.supplementary-fields {
  padding-left: $gutter-width;
  width: 33.333%;
}

.form-actions {
  clear: both;
}

/* Format the date range form items. */
.start-date-wrapper,
.end-date-wrapper {
  clear: none;
  width: auto;
  max-width: 49%;

  &,
  input,
  .form-item .form-type-textfield,
  .date-padding,
  .form-type-textfield {
    float: none;
    display: inline-block;
    min-width: inherit;
  }

  .form-item {
    input {
      margin-right: auto;
    }
  }

  .form-type-textfield {
    max-width: 60%;

    &:last-child {
      max-width: 30%;
    }
  }

  .form-type-date-popup {
    margin: 0;
  }

  @include respond(small-medium) {
    display: block;
    max-width: inherit;
    margin: $gutter-width auto;
  }
}

.addressfield-container-inline {
  > div.form-item {
    display: inline-block;
    float: none;
  }

  input[type="text"] {
    width: auto;
  }

  &:after {
    content: "";
    clear: none;
    display: none;
  }
}

.logo-available {
  /*.field-name-field-date,
  .field-name-field-address,
  .field-name-field-offsite-url {
    margin-left: 65px;

    @include respond(only-small) {
      margin-left: 0;
    }
  }*/
}

.field-name-field-logo {

  max-width: 100%;
  position: relative;

  img {

  }

}

.field-name-body {
}

.field-name-field-date {
  font-weight: bold;
}

.adr {
  .region {
    &,
    .node-type-event &,
    .panel-display .region & {
      float: none;
      padding: 0;
    }
  }
}

ul.node-action-links {
  display: inline-block;
  margin-top: 1.4em;

  li {
    padding: 0;
    vertical-align: baseline;
  }

  li,
  a {
    margin: 0;
  }
}

.view-commons-contributors-group {
  .views-field-picture {
    margin: 0 0 10px;
    position: static;
    line-height: 0;

    img {
      width: 40px;
    }
  }

  td {
    padding: 0;
  }

  tbody tr {
    background-color: transparent;
  }
}

/**
 * Author/Submit line
 */

.author-datetime {
  color: $light_gray;
  font-size: 0.88em;
}

.node-content-type {
  color: $dark_gray;
}

/**
 * Featured/New Tags
 */

.tag.featured {
  width: 58px;
  height: 22px;
  display: inline-block;
  background: {
    image: url('../img/tag-featured.png');
    repeat: no-repeat;
  }
  margin: 6px 0 0 5px;
}

.tag.new {
  width: 27px;
  height: 22px;
  display: inline-block;
  background: {
    image: url('../img/tag-new.png');
    repeat: no-repeat;
  }
  margin: 4px 0 0 5px;
}

/**
 * Group nodes
 */

.node-group {

}

/* =============================================================================
 *   Regions
 * ========================================================================== */

/* =============================================================================
 *   Links
 * ========================================================================== */

a {
  text-decoration: none;
  color: $primary-color;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

/* =============================================================================
 *   Breadcrumbs
 * ========================================================================== */

#breadcrumb {
  margin: 10px 0;

  /* If the label is set to show in theme settings the label class is added */
  .breadcrumb-label {
    font-size: 1em;
    display: inline;
    padding-right: 10px;

    &:after {
      content: ":";
    }
  }

  ol {
    margin: 0;
    padding: 0;
  }

  .with-breadcrumb-label ol {
    display: inline;
  }

  li {
    list-style: none;
    display: inline;
  }
}

/* =============================================================================
 *   Pagers
 * ========================================================================== */

ul.pager {
  clear: both;
  margin: 0;
  text-align: center;
}

.item-list ul.pager li {
  margin: 0;
}

ul.pager li {
  background-image: none;
  display: inline;
  list-style-type: none;
  padding: .5em;

  &.pager-current {
    font-weight: 700;
  }
}

.block ul.pager li {
  margin: 0;
}

/* =============================================================================
 *   Skip Navigation
 * ========================================================================== */

#skip-link {
  left: 50%;
  margin-left: -6.5em;
  margin-top: 0;
  padding: 0 0.5em;
  position: absolute;
  width: 12em;
  z-index: 50;

  a {
    background: #444;
    background: rgba(0, 0, 0, 0.6);
    color: $page;
    display: block;
    line-height: 2;
    padding: 0;
    text-align: center;
    text-decoration: none;

    &:link,
    &:visited {
      background: #444;
      background: rgba(0, 0, 0, 0.6);
      color: $page;
      display: block;
      line-height: 2;
      padding: 0;
      text-align: center;
      text-decoration: none;
    }
    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }
  }
}

/* =============================================================================
 *   Tabs (local tasks)
 * ========================================================================== */

#tasks {
  margin-bottom: 15px;
}

ul.primary {
  border-bottom-color: $border;
  margin: 20px 0;
  padding: 0 0 0 5px;

  li {
    display: block;
    float: left;
    margin: 0 1px -1px;

    a {
      background-color: $ultralight;
      border-color: $border;
      margin-right: 1px;
      padding: 0 10px;
      display: block;
      float: left;
      height: 1.5em;
      line-height: 1.5em;

      &:hover,
      &:focus {
        background-color: $light;
        border-color: $border;
      }
    }
  }

  li.active {
    a,
    a:hover,
    a:focus {
      background-color: $page;
      border-bottom-color: $page;
    }
  }
}

ul.secondary {
  border-bottom: 1px solid $border;
  margin: 1em 0 0;
  padding: 0 .3em 1em;

  li {
    border-right: 0;
    list-style: none;
    padding: 0 10px 0 0;

    a {
      &:hover,
      &.active {
        border-bottom: none;
        text-decoration: underline;
      }
    }
  }
}

/* =============================================================================
 *   Action links
 * ========================================================================== */

ul.action-links {
  margin: 20px 0 0;
  list-style: none;
}

/* =============================================================================
 *  Field Styling
 * ========================================================================== */

/**
 * Labels are h2 in Adaptivetheme. Use a strong selector to mitigate unwanted ineritance issues
 */

.field-label {
  font-size: 1em;
  font-weight: 700;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0;
}

/**
 * Taxonomy
 */

.field-type-taxonomy-term-reference {
  /* The same bottom margin as p, blockquote, ul, ol and dl */
  margin-bottom: 1.5em;

  &.field-label-inline .field-items {
    margin: 0;
    padding: 0;
  }

  &.field-label-inline .field-item {
    display: inline;
    list-style: none;
    padding: 0 10px 0 0;
  }
}

/**
 * Float left setting
 */

.ia-l .field-type-image figure,
.iat-l .field-type-image figure {
  margin: 5px 20px 15px 0;
}

/**
 * Centered setting
 */

.ia-c .field-type-image figure,
.iat-c .field-type-image figure {
  margin: 5px auto 15px;
}

/**
 * Float right setting
 */

.ia-r .field-type-image figure,
.iat-r .field-type-image figure {
  margin: 5px 0 15px 20px;
}

/* =============================================================================
 *   Panels Styling
 * ========================================================================== */

.node-title,
.pane-title {
  font-size: 1.6em;
  font-weight: bold;
}

.panel-display {
  min-width: 100%;
  /* margin-right: -$gutter-width;
   margin-left: -$gutter-width;*/

  .region {
    box-sizing: border-box;
    padding-right: $gutter-width;
    padding-left: $gutter-width;
  }
}

.commons-sign-up,
.commons-login {
  @extend %button-common;
  @extend %button-small-layout;
}

.commons-sign-up {
  @extend %button-primary;
}

.commons-login {
  @extend %button-default;
}

.pane-views-panes,
.pane-views,
body.page-node .pane-views-panes {
  .front.not-logged-in & {
    @extend %pod;

    > div {
      @extend %pod-content;
    }
  }
}

.nodes {
  margin-top: 1em;
}

.page-events {
  .view-commons-events-upcoming {
    .view-content {
      @extend %pod;
      @extend %pod-content;
    }
  }
}

.pane-commons-featured-panel-pane-1,
.pane-commons-events-upcoming {
  .block-inner {
    margin: 0;

    .views-row-last {
      border-bottom: none;
    }
  }
}

/* =============================================================================
 *   Views Styling
 * ========================================================================== */



.views-view-grid {
  border: none;
  margin: 0;

  tbody {
    tr {
      border-top: none;
      background: none;
    }
  }

  td {
    padding: $gutter-width / 2;
  }
}

.grid-grouping-title {
  font-size: 1em;
  color: $light_gray;
  margin-bottom: $gutter-width;
}

.pager a {
  // The li parent will handle spacing.
  margin: 0;
  // We do not need as much horizontal space on these items.
  padding-right: .75em;
  padding-left: .75em;
}

.pane-commons-contributors-group-panel-pane-1 {
  table, tbody {
    margin: 0;
    border: none;
  }

  tbody tr {
    background-color: transparent;
    border: none;
  }

  td {
    padding: 0;
    margin: 0 1px 1px 0;
    float: left;
    line-height: 0;

    a {
      position: relative;
      display: block;

      img {
        @include opacity(0.5);
      }

      span {
        display: none;
        position: absolute;
        top: -100%;
        background-color: rgba(#000, 0.85);
        white-space: nowrap;
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
        padding: 5px 8px;
        color: $white;
        border-radius: 4px;
        z-index: 15;

        &:after {
          content: "";
          display: block;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: rgba(#000, 0.85) transparent transparent transparent;
          position: absolute;
          left: 10px;
          top: 100%;
        }
      }

      &:hover {
        img {
          @include opacity(1);
        }

        span {
          display: block;
        }
      }
    }
  }
}

.region-two-66-33-second .view-commons-contributors-group {
  table,
  tbody {
    margin: 0;
    border: none;
  }

  tbody tr {
    background-color: transparent;
    border: none;
  }

  td {
    padding: 0;
    line-height: 0;

    &.col-last a {
      margin-right: 0;
    }

    .user-picture {
      margin-bottom: 10px;

      img {
        width: 40px;
      }
    }
  }
}

#views-exposed-form-commons-bw-all-page-1 {
  .views-submit-button {
    margin-left: 20px;
  }

  .views-exposed-widget {
    padding: 0;

    .form-submit {
      margin-top: 0;
    }
  }
}

.widget-changed {
  .views-submit-button {
    position: static;
    visibility: visible;
  }
}

.commons-bw-create-choose {
  cursor: pointer;

  span {
    @include sprite_url_height_width($icons, ico_arrow_wht);
    margin-right: 0;
  }
}

.commons-bw-create-choose-bg {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#fff, 0.7);
  z-index: 10;

  .create-choose-open & {
    display: block;
  }
}

.commons-bw-create-choose-holder {
  position: relative;
}

.commons-bw-create-all-widget-types {
  position: absolute;
  display: none;
  left: -50px;
  top: -50px;
  z-index: 11;
  width: 210px;
  background: $white;
  border: 1px solid $green;
  border-radius: 6px;
  list-style: none;

  .block-content &,
  .item-list & {
    margin: 0;
    padding: 0;
  }

  li {
    border-bottom: 1px solid lighten($light_gray, 20%);
    position: relative;
    font-size: 0.85em;
    color: $light_gray;

    .last {
      border-bottom: none;
    }

    .item-list & {
      margin: 0;
      padding: 10px 10px 10px 27px;
    }

    .commons-polls-create:before {
      @include sprite_url_height_width($icons, polls);
      content: "";
      position: absolute;
      left: 10px;
      top: 13px;
    }

    .commons-posts-create:before {
      @include sprite_url_height_width($icons, posts);
      content: "";
      position: absolute;
      left: 10px;
      top: 13px;
    }

    .commons-wikis-create:before {
      @include sprite_url_height_width($icons, wikis);
      content: "";
      position: absolute;
      left: 10px;
      top: 13px;
    }

    .commons-events-create:before {
      @include sprite_url_height_width($icons, events);
      content: "";
      position: absolute;
      left: 10px;
      top: 13px;
    }

    .commons-q-a-create:before {
      @include sprite_url_height_width($icons, qa);
      content: "";
      position: absolute;
      left: 10px;
      top: 13px;
    }

    a {
      display: block;
      color: $green;
      font-size: 1.3em;
    }
  }

  .create-choose-open & {
    display: block;
  }
}

.quicktabs-style-commons-pills {
  ul.quicktabs-tabs {
    border-top: 2px dashed $border;
    margin-bottom: 0;
    text-align: center;
    li {
      a {
        &:before {
          display: none !important;
        }
        .commons-bw-result-count {
          font-size: 1em;
          font-weight: bold;
          &:before {
            content: '';
            margin: 0 2px;
          }
        }
      }
    }
  }
}

.commons-bw-partial-node-form {
  margin-bottom: 1.7em;
  padding-left: 68px;

  &.compact-form {
    .form-item {
      margin: 0;
    }

    textarea {
      resize: none;
    }
  }

  .user-picture {
    margin-top: 2.6em;
  }

  @include respond(only-small) {
    padding-left: 0;

    .user-picture {
      display: none;
    }
  }

  .field-type-image {
    margin-top: 1.7em;
  }

  .field-name-og-group-ref {
    .fieldset-outer-wrapper {
      margin-top: 1.7em;
      margin-bottom: 1.7em;
      padding-top: 1.7em;
      border-top: 1px solid $border;
    }
  }
}

.partial-node-form-title {
  margin-top: 0;
  margin-bottom: .2em;
  margin-left: -68px;
  color: $secondary-color;

  @include respond(only-small) {
    margin-left: 0;
  }
}

.trigger-field {
  &,
  .form-item {
    margin-top: 0;
  }

  input,
  textarea {
    .compact-form & {
      height: 2.2em;
      overflow: hidden;
    }
  }
}

.expandable-form-toggle {
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: .6em;
  overflow: hidden;

  &,
  &:before {
    width: 1.4em;
    height: 1.4em;
    text-align: center;
  }

  .expanded-form & {
    display: block;

    &:before {
      content: 'x';
      display: block;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.full-form {
  .compact-form & {
    float: right;
    margin-top: .5em;

    &:after {
      content: '\2192' #{"/*rtl:'\2190'*/"};
      display: inline-block;
      margin-left: .2em;
    }
  }
}

/* =============================================================================
 *   Block Styling
 * ========================================================================== */

/* =============================================================================
 *   Node Styling
 * ========================================================================== */

.featured-node-tooltip {
  position: absolute;
  left: 100%;
  top: 1px;
  background-color: rgba(#000, 0.85);
  white-space: nowrap;
  padding: 3px 5px;
  color: $white;
  border-radius: 3px;
  display: none;
  margin-left: 5px;
  z-index: 1;

  &:before {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent rgba(#000, 0.85) transparent transparent;
    position: absolute;
    left: -5px;
    top: 50%;
    margin-top: -5px;
  }

  .node-teaser.node-promoted:hover & {
    display: block;
  }
}

.node {
  .node-title {
    margin: 0;
  }
}

/**
 * Node forms
 */

.node-form {
  /* Polls have some bad, over specific, styles so this is what needs to be
     done. */
  #poll-choice-table .form-text {
    width: 100%;
  }
}

/* =============================================================================
 *   Comment Styling - Comments, comment wrapper, comment form
 * ========================================================================== */

/**
 * Wrapper for a single comment
 */

.comment {
  margin-bottom: 20px;
}

/**
 * "New" marker for comments that are new for the current user
 */

.new {
  color: $highlight;
}

/**
 * Nested comments are indented
 */

.indented {
  margin-left: 40px;
}

.has-rate-widget {
  min-height: 4em;
  margin-right: 6em;
  position: relative;

  @include respond(small-medium) {
    min-height: 0;
    margin-right: 0;
  }
}

.rate-widget-commons_thumbs_up_down {
  float: right;
  position: absolute;
  top: 0;
  right: -6em;
  margin-top: 0;

  @include respond(small-medium) {
    position: static;
    float: left;
    margin: ($gutter-width * 2) auto;
  }
}

.related-information {
  clear: both;
  margin: ($gutter-width * 2) 0;

  .field {
    float: left;
  }
}

/* =============================================================================
 *   Forms
 * ========================================================================== */

html.js input.form-autocomplete {
  background-position: 100% 5px;
}

html.js input.throbbing {
  background-position: 100% -15px; /* LTR */
}

/**
 * Forms in tables.
 */

tr {
  &.odd,
  &.even {
    .form-item {
      white-space: normal;
    }
  }
}

/**
 * Wrapper for a form element (or group of form elements) and its label
 */

.form-item {
  input.error,
  textarea.error,
  select.error {
    border: 1px solid $highlight;
  }

  label {
    font-weight: 700;
  }

  .description {
    font-size: 0.85em;
  }
}

/**
 * Highlight marks and form required mark
 */

.marker,
.form-required {
  color: $highlight;
}

/**
 * Password confirmation
 */

.password-parent,
.confirm-parent {
  margin: 0;
}

#edit-field-topics-und {
  border-top: 2px groove #8A8A8A;
  border-left: 2px groove #8A8A8A;
}


/* =============================================================================
 *   Tables
 * ========================================================================== */

table {
  margin: 10px 0;
  padding: 0;
  width: 100%;

  &.sticky-header {
    z-index: 10;
  }
}

table,
thead,
tbody,
tr,
th,
td {
  border-color: $border;
}

table,
td,
th {
  vertical-align: middle;
}

caption,
th,
td {
  text-align: left;
}

thead tr {
  font-weight: 700;
  background-color: $medium_dark;
}

td,
th {
  border-bottom: 0;
  margin: 0;
  padding: 5px 7px;
}

tbody tr {
  border-top: 1px solid $border;
}

tr {
  &.odd {
    background: $page;
  }

  /* Table row striping */
  &.info,
  &.even,
  &:nth-child(2n+2) {
    border-bottom: 0;
    background-color: $ultralight;
  }

  &.odd td.active {
    background-color: $light;
  }

  &.even td.active {
    background-color: $medium_light;
  }
}

/**
 * Forum tables
 * Core sets white-space to nowrap, which makes no sense
 */

#forum td {
  .created,
  .posts,
  .topics,
  .last-reply,
  .replies,
  .pager {
    white-space: normal;
  }
}

/* =============================================================================
 *   Messages
 * ========================================================================== */

div {
  &.messages {
    // Left and right margin are set by the global gutter width
    margin-bottom: 10px;
    margin-top: 10px;

    ul {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

/**
 * Unpublished nodes
 */

.node-unpublished,
.comment-unpublished {
  p.unpublished {
    @include unpublished;
  }
}

/* =============================================================================
 *    Maintenance pages
 * ========================================================================== */

.maintenance-page {
  .container {
    padding: 40px 0;
  }
}

.db-offline {
  .container {
    margin: 0 auto;
    padding: 40px 0;
    width: 100%;
    max-width: 960px;
  }

  div.messages {
    margin: 20px 0 0;
  }

  #content {
    padding: 20px 0;
  }
}

/* =============================================================================
 *   Misc overrides for contrib modules
 * ========================================================================== */

/**
 * Admin menu overrides
 */

#admin-menu {
  margin: 0;
  padding: 0;
}

/**
 * Devel Module
 */

.dev-query {
  background: $light;
  padding: 30px;
}

/**
 * Styleguide module tweaks
 */

#styleguide-header {
  padding: 0 10px;

  .item-list {
    font-family: inherit;
    margin: 0 20px 20px 0;
    min-height: 260px;
    width: auto;
  }
}

/* =============================================================================
 *   Front Page specific
 * ========================================================================== */

body.front {
  .region-three-33-top {
    font-size: 24px;
    font-weight: 300;

    h1 {
      color: $dark_gray;
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
    }

    .commons_home-welcome-image {
      float: right;
    }
  }

  &.not-logged-in {
    #block-system-main {
      .pane-1 {
        .pane-title {
          font-weight: 700;
          font-size: 38px;
          color: $dark_gray;
        }
      }
    }
  }
}



body.front.logged-in {
  .view-commons-homepage-content {
    > .view-content {
      @extend %pod;
      padding: $gutter-width;

      > .views-row {
        position: relative;
      }
    }

    .article.node, .node-teaser.node-group {
      .node-content {
        form {

        }

        .field-name-body {
        }
      }

      .author-datetime {
        margin-bottom: 1.5em;
        clear: both;
      }
    }

    .views-row-first {
      border-top: none;
    }

    .item-list {
      padding: 10px 0 0 0;
    }
  }

  #views-exposed-form-commons-homepage-content-panel-pane-1 .views-exposed-widgets {
    padding: $gutter-width 0;
    margin-bottom: 0;

    &.widgets-active {
      @extend %pod;
      @extend %pod-content;
    }
  }
}

body.front.logged-in .view-commons-homepage-content .article.node {
  &.no-user-picture,
  &.node-page {
    .node-header,
    .node-content,
    .author-datetime,
    .links {
      margin-left: 0;
    }
  }
}

#quicktabs-commons_follow_ui {
  .flag-email-group,
  .flag-email-node,
  .flag-email-user,
  .flag-email-term {
    a {
      cursor: default;

      input {
        cursor: default;
      }
    }

    a,
    span {
      span {
        display: none;
      }
    }
  }
}

/**
 *  Placeholder styles
 */

#menu-bar.nav {
  .menu-item-193 a {
    //background-image: url('../img/icons/icon-home.png');
  }

  .menu-item-194 a {
    //background-image: url('../img/icons/icon-groups.png');
  }

  .menu-item-195 a {
    //background-image: url('../img/icons/icon-events.png');
  }
}

/**
 * Search
 */

.search-form-page {
  .form-type-textfield {
    label {
      white-space: nowrap;
    }

    input {
      width: auto;
    }

    @include respond(only-small) {
      &,
      input {
        display: block;
        width: 100%;
      }
    }
  }

  @include respond(medium) {
    .form-item {
      margin-bottom: 0;
    }
  }
}

// Build the search button base styling.
@include button_with_icon(nth($buttons, 3), icon-search);

%button-primary-icon-search {
  &,
  &:hover,
  &:active,
  &:focus {
    background-position: 1.5em center;
  }
}

.action-item-search {
  float: right;
  margin-right: 0;
  margin-bottom: 0;

  &,
  &[type="submit"] {
    padding-left: 3em;
  }

  &[type="submit"] {
    @extend %button-primary-icon-search;
  }

  @include respond(only-small) {
    float: none;
    clear: both;
    display: block;
  }

  @include respond(medium) {
    margin-top: 0;
  }
}

.block-facetapi {
  margin-bottom: .7em;
  padding-bottom: .7em;
  border-bottom: 1px solid $border;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .block-title {
    margin-bottom: $gutter-width;
    color: $gray;
    font-size: 1.2em;
  }
}

.facetapi-facetapi-links,
.facetapi-date-range {
  margin: 0;
  padding: 0;

  li.leaf {
    margin-left: 0;
    list-style-type: none;
    list-style-image: none;
  }
}

.page-search {
  .two-33-66 {
    > {
      @include filter-drawer;
    }
  }

  @include filter-overlay;
}

.facetapi-processed {
  &,
  .block-content & {
    margin-bottom: 0;
    padding-left: 0;

    li {
      &.last {
        margin-bottom: 0;
      }
    }
  }
}

.search-results-title,
.search-results-wrapper > h2 {
  margin-top: .2em;
}

.search-results {
  margin-top: 0;

  &,
    /* The ol styling is a bit specific. */
  .block-content & {
    margin-bottom: 0;
    padding: 0;
  }
}

// This is not optimal. Preferably, a class would be placed in the markup, but
// ctools has hardcoded the output without the chance of override. The other
// option is to use regex to force in wrappers.
// @see http://drupal.org/node/1743402
.pane-search-result {
  .block-content {
    .search-results {
      @extend %pod;
      @extend %pod-content;
    }
  }
}

.page-search-user, {
  .user-results {
    @extend %pod;
    @extend %pod-content;
  }
}

.search-result {
  border-bottom: 1px solid $border;
  list-style-type: none;

  &,
  .block-content & {
    padding-bottom: .7em;
    margin-bottom: .7em;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 1.429em;
    line-height: 1.2em;
    margin: .5em 0 0;
  }

  .title {
    margin-top: 0;
    margin-bottom: .2em;
  }

  .search-result-url {
    color: $gray;
    font-size: .85em;
    margin-bottom: 1em;
  }

  .apachesolr_search-results & {
    .title,
    .search-result-url,
    .search-result-date,
    .search-result-snippet-info {
      display: none;
    }

    .pane-node-flag-commons-follow-group-link {
      position: static;
      right: auto;
      top: auto;
      float: right;
    }
  }
}

/* Search profiles */

.profile-search-result {
  box-sizing: border-box;
  display: inline-block;
  padding-bottom: 0;
  vertical-align: top;
  width: 48.5%;
  margin-right: 2%;

  &:nth-child(even) {
    margin-right: 0;
  }

  @include respond(only-small) {
    width: 100%;
    margin-right: 0;
  }

  @include respond(large) {
    width: 31.5%;

    &:nth-child(even) {
      margin-right: 2%;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .commons-pod & {
    width: 100%;
    margin-right: 0;

    .profile-actions {
      box-sizing: border-box;
      margin-right: 0;
      margin-left: 0;
      border: 1px solid $border;
      border-radius: 0;
    }
  }

  h4 {
    margin: 0;
  }
}

.profile-header,
.profile-content {
  margin-left: 72px;
}

.profile-header {
  box-sizing: border-box;

  .user-picture {
    position: relative;
    top: -1.2em;
    float: left;
    margin-bottom: 0;
    margin-left: -72px;
  }
}

@include multiline-ellipsis(profile-content, 2.8em, 1.4em);

.associated-groups-title,
.associated-groups,
.group-item {
  display: inline;
  margin: 0;
  color: $light_gray;
  white-space: normal;
}

.associated-groups-title {
  font-weight: normal;
}

.associated-groups {
  &,
  .block-content & {
    padding-left: 0;
  }
}

.associated-groups-title,
.group-item {
  margin-right: .2em;
  font-size: .88em;
}

.group-item {
  &:after {
    content: ",";
  }

  &:last-child {
    margin-right: 0;

    &:after {
      content: ".";
    }
  }
}

.profile-actions {
  clear: both;
  width: 100%;
  margin-top: $gutter_width;
  margin-left: -$gutter_width;
  margin-right: -$gutter_width;
  padding: ($gutter_width / 2) $gutter_width;
  background: $ultralight;
  border-top: 1px solid $border;
  @include border-bottom-right-radius(.4em);
  @include border-bottom-left-radius(.4em);

  .field {
    /* Make fields in the wrapper inline. */
    display: inline-block;
  }
}

/**
 * Notification settings
 */

.view-commons-follow-node,
.view-commons-follow-user,
.view-commons-follow-taxonomy-term {
  .views-field-ops,
  .views-field-ops-1 {
    width: 20%;
  }

  .views-field-ops {
    text-align: right;
  }

  .views-field-ops-1 {
    text-align: center;
  }
}

/**
 * Trusted contacts
 */

.views-field-field-membership-token {
  a {
    &:first-child {
      &:after {
        content: "|";
        display: inline-block;
        padding: 0 .3em 0 .5em;
        color: $dark_gray;
      }
    }
  }
}

.trusted-status-request {
  &:before {
    content: "\271a";
    position: relative;
    top: .1em;
    display: inline-block;
    margin-right: .2em;
    font-size: 1.4em;
    line-height: .6em;
  }
}

.message-contact {
  &:before {
    content: "";
    @include sprite_url_height_width($icons, message);
  }
}

/**
 * Private message
 */

.privatemsg-conversation {
  .form-actions {
    &,
    [class*="action-item"] {
      margin-bottom: 0;
    }
  }
}

.privatemsg-message-participants {
  margin-bottom: $gutter_width;
  padding-bottom: $gutter_width;
  border-bottom: 1px solid $border;
}

.privatemsg-message {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: $gutter_width;
  padding-bottom: $gutter_width;
  padding-left: 72px;
  border-bottom: 1px solid $border;

  .user-picture {
    position: relative;
    float: left;
    width: 50px;
    margin-left: -72px;
  }
}

.privatemsg-message-links {
  font-size: .88em;
  text-align: right;
}

.message-delete {
  &:before {
    content: "\2715";
    display: inline-block;
    margin-right: .3em;
    color: $icon_gray;
    font-weight: bold;
  }
}

/**
 * Commons Origins Pallete Choices
 */

#edit-commons-origins-palette {
  .form-item-commons-origins-palette {
    clear: both;
    margin-bottom: 10px;

    input[type="radio"] {
      float: left;
    }

    label {
      margin-left: 20px;
    }
  }
}

/**
 * Login and signup form
 */

#user-login, #user-register-form {
  input[type="text"], input[type="password"] {
    width: 20em;
    max-width: 100%;
  }
}

/**
 * Add the color palettes
 */

//@import 'palettes';
