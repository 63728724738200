#front {
  .panel-pane {
    clear: both;
    margin: 0;
    float: left;
    width: 100%;
    background: $white;
    .block-title {
      font-size: 2em;
      color: $primary-color;
    }
  }

  .field-name-field-body {
    font-size: 1.33em;
    //font-family: $heading-font;
  }

  .entity-bean > .content > {
    .group-text {
      overflow: hidden;
      padding: 1em;
      &.no-padding {
        padding: 0;
      }
      background: $white;
      .field-name-field-body {
        max-width: 600px;
      }

    }

  }

  .pane-bean-frontpage-slideshow {

    @include respond(medium) {
      .block-inner {
        //margin-left: 25%;
      }
    }

  }
  .move-bean-content {
    @include respond(medium) {
      .entity-bean > .content {
        @include bg;
        .group-text {
          margin-left: 25%;

        }

      }
    }

  }

  .field-name-field-paragraphs {
    @include wide;
    background: $primary-color;
    color: $white;
    padding: $gutter-width*2;
    .field-name-field-link {
      a {
        @include cta;
        border-color: $white;
        color: $white;
        &:before, &:after {
          border-color: $white;
        }
      }
    }
    > .field-items {
      display: none !important;
      overflow: hidden;
      @include respond(medium) {
        display: table;
        width: 100%;
      }
      > .field-item {
        @include respond(medium) {
          width: 33%;
          //float: left;
          display: table-cell;
          padding: 0 $gutter-width*2;
          box-sizing: border-box;

          border-right: 2px dashed $white;
          &:last-child {
            border-right: none;
          }

        }
      }
    }

    .component-title {
      color: $white;
      text-align: center;
    }

  }

  .pane-bean-what-we-do {

  }

  .pane-bean-resources {
    border-top: 2px dashed $border;
    @include wide();
    /*.group-image {
      @include wide;
      text-align: center;
      border-bottom: 2px dashed $primary-color;
    }*/
    .group-text {
      text-align: center;
      margin: 0 auto;

    }
  }
}