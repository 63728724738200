.panels-flexible-two_25_75 {
  .panel-pane {
    margin-bottom: 0;
  }
  .panels-flexible-region-two_25_75-first {
    @include bg();
    border-top: 2px solid $border;
    padding-top: 5px;
    .panels-flexible-region-inside {
      padding: 0;
    }
    &:before {
      content: '';
      border-top: 2px solid $border;
      display: block;

    }

  }
  .panels-flexible-region-two_25_75-center {
    //padding-left: $gutter_width;
  }

  .panels-flexible-two_25_75-inside {
    display: table;
    table-layout: fixed;
    width: 100%;
    .panels-flexible-region {
      display: table-cell;
      vertical-align: top;
      float: none;
    }
  }
}

.panels-flexible-two_33_66 {
  .panel-pane {
    margin-bottom: 0;
  }
  .panels-flexible-two_33_66-inside {
    display: table;
    table-layout: fixed;
    width: 100%;
    .panels-flexible-region {
      display: table-cell;
      vertical-align: top;
      float: none;
    }

    .block {
      background: none;
      border: none;
    }
  }

}

.pane-title {
  color: $black;
  font-weight: bold;
}

.panel-pane {
  &.page-header {
    .block-content {
      padding: 0 1em;
      .block-title {
        @include page-title();
      }
    }

    &.pane-user-name {
      .block-content {
        @include page-title();
      }
    }
  }

}

.block.pane-node-content {
  margin-bottom: 0;
}

.page-groups, .page-resources, .page-jobs {
  #page-title {
    visibility: hidden;
    margin: 0;
    font-size: 2em;
  }
}