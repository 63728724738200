/**
 * "Search form" block
 */
$button-size: 32px;
.block-search {
  display: block;
  background-color: $primary-color; // Old browsers
  height: $button-size;
  margin: 0 0 $gutter_width 0;
  position: relative;

  .container-inline {
    overflow: hidden;
    label,
    div {
      float: left;
      display: block;
      margin-top: 0;
      margin-bottom: 0;
    }

    .form-item-custom-search-types {
      display: none!important;
      position: relative;
      z-index: 2;
      display: inline-block;
      padding: 0 .3em;
      line-height: $button-size;
      float: left;
      border-right: 1px solid $white;
      width: $button-size*2;
      .select-status {
        color: $white;

        &:after {
          border-top-color: $white;
        }
      }
    }
  }

  .block-inner {
    margin-left: 0;
    margin-right: 0;
  }

  .form-item-search-block-form {
    box-sizing: border-box;
    float: left;
    width: 100%;
    margin: 0 -$button-size 0 -$button-size*2;
    padding: 0 $button-size 0 $button-size*2;
    line-height: $button-size;
  }

  .form-text {
    width: 100%;
    max-width: 100%;
    color: $white;
    background: transparent;
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  .form-actions {
    position: absolute;
    right: 0;
    z-index: 2;
  }

  .form-actions,
  .form-submit {
    box-sizing: border-box;
    width: $button-size;
    height: $button-size;
  }

  .form-submit {
    font-size: 0;
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    &:before {
      @include icon;
      content: $icon-search;
      font-size: 1rem;
      width: $button-size;
      height: $button-size;
      line-height: $button-size;
      color: $white;
    }
    &,
    &:hover {
    }
  }

  @include respond(small-medium) {
    display: none;
  }
}