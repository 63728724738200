/**
 * Events Page
 */

.page-events {
  .region-two-66-33-top {
    position: relative;
  }
  .region-three-25-50-25-third {
    .pane-views-panes {
      @extend %pod;
      margin-right: 0;

      .block-inner {
        @extend %pod-content;
      }
    }
  }

  div.search-result-wrapper h3.title {
    text-decoration: none;

    a {
      color: $primary-color;
    }
  }

  .three-25-50-25 {
    > {
      @include filter-drawer(small-medium);
    }
  }

  @include filter-overlay(small-medium);
}


.pane-commons-events-commons-events-create-event-link {

  text-align: center;



  a {
    @extend %button-common;
    @extend %button-default-layout;
    @extend %button-primary;
    margin: 0;

    @include respond(only-small) {
      float: none;
      display: block;
    }
  }
}