.node-panel {
  padding: 0;
  &.node-full{
    .node-content,.submitted{
      width:100%;
      float:none;
    }
  }
  .submitted {
    display: none;
  }

}