.block {
  clear: both;
  .sidebar &, &.sidebar {
    margin: $gutter_width*2;
    border: 2px solid $border;

    background: $white;

    .pane-title, .block-title {
      padding: $gutter_width/2;
      font-size: 1.2em;

      border-bottom: 1px dashed $border;
      margin-bottom: 2px;
    }




  .block-content {
    padding: 5px;
    .views-row {
      border-bottom: 1px dashed $border;
      padding: $gutter_width $gutter_width/2;
      font-size: .9em;
      a {
        color: $black;
        font-weight: bold;
      }
      &.views-row-first {
        //border-top: 1px dashed $border;
      }
      &.views-row-last {
        //border-bottom: none;
      }
    }
  }
  }
  &.plain {
    border: none;
    background: none;
    .pane-title {
      padding: 0;
    }
  }
}