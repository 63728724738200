.node-group {

  &.node-teaser {
    .panel-flexible {
      border-bottom: 2px dashed $border;
      .panels-flexible-region {
        padding: 1em;
      }
      .panels-flexible-region.first {
        text-align: center;

        border-right: 2px dashed $border;

        .panel-pane {
          margin: 0 0 5px;
        }
      }

    }
  }

  .field-name-field-group-logo {
    overflow: hidden;
    display: inline-block;

    img {
      display: inline-block;
      border-radius: 100%;
      float: left;
      margin-right: 1em;
    }
  }
  .pane-node-field-group-logo {

    .field-name-field-group-logo a {

      overflow: hidden;
      display: inline-block;

      img {
        display: inline-block;
        border-radius: 100%;
        float: left;
      }
    }
  }
  .pane-node-title {
    h2 {
      font-size: 1.75em;
      text-align: center;
      font-family: $heading-font;
      line-height: 1.2;
      margin: 0;
    }

  }

  .field-name-body {

  }

  .pane-node-flag-commons-follow-group-link {
    display: inline-block;
    position: absolute;
    top: .5em;
    right: 0;
    margin: 0;

    @include respond(small-medium) {
      position: static;
      top: auto;
      right: auto;
    }
  }

  .view-commons-groups-recent-content {
    .views-row {
      a {
        color: $black;
      }
    }
  }

  .view-commons-contributors-group {
    overflow: hidden;
    .view-content {
      width: 100%;
      margin-right: -32px;
      padding-right: 32px;
      float: left;
      box-sizing: border-box;
    }
    .more-link {
      width: 32px;
      float: left;
      text-align: center;

    }
    .views-field-nothing {

      a {
        img {
          border-radius: 100%;
        }
      }

    }
  }
}

.view-commons-groups-directory,
.view-commons-homepage-content .node-teaser.node-group {
  > .view-content > .views-row {
    //@extend %pod;
    position: relative;

    > .node-teaser,
    form {
      //@extend %pod-content;
    }
  }

  .pane-node-body {
    margin-bottom: 0;
  }
}

.quicktabs-tabpage {
  .views-row {

  }
}
