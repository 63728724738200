.page-jobs{

  .pane-bean-cta{

    text-align: center;

  }
}

.view-commons-jobs-latest-jobs{
  &.view-display-id-panel_pane_2{
      .view-content{
        @extend %pod;
        @extend %pod-content;
      }
  }
}