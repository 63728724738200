@charset "UTF-8";
@font-face {
  font-family: 'icons';
  src: url("../fonts/icons/icons.eot?51503659");
  src: url("../fonts/icons/icons.eot?51503659#iefix") format("embedded-opentype"), url("../fonts/icons/icons.woff2?51503659") format("woff2"), url("../fonts/icons/icons.woff?51503659") format("woff"), url("../fonts/icons/icons.ttf?51503659") format("truetype"), url("../fonts/icons/icons.svg?51503659#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icons';
    src: url('../fonts/icons/icons.svg?51503659#icons') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-info:before {
  content: '\e800'; }

/* '' */
.icon-left-open-big:before {
  content: '\e801'; }

/* '' */
.icon-right-open-big:before {
  content: '\e802'; }

/* '' */
.icon-search:before {
  content: '\e803'; }

/* '' */
.icon-cancel-1:before {
  content: '\e804'; }

/* '' */
.icon-tag:before {
  content: '\e805'; }

/* '' */
.icon-download-alt:before {
  content: '\e806'; }

/* '' */
.icon-videocam:before {
  content: '\e807'; }

/* '' */
.icon-picture:before {
  content: '\e808'; }

/* '' */
.icon-calendar:before {
  content: '\e809'; }

/* '' */
.icon-info-circled:before {
  content: '\e80a'; }

/* '' */
.icon-home:before {
  content: '\e80b'; }

/* '' */
.icon-basket:before {
  content: '\e80c'; }

/* '' */
.icon-pause:before {
  content: '\e80f'; }

/* '' */
.icon-play:before {
  content: '\e810'; }

/* '' */
.icon-stop:before {
  content: '\e811'; }

/* '' */
.icon-to-end:before {
  content: '\e812'; }

/* '' */
.icon-to-start:before {
  content: '\e813'; }

/* '' */
.icon-heart:before {
  content: '\e814'; }

/* '' */
.icon-heart-broken:before {
  content: '\e815'; }

/* '' */
.icon-star:before {
  content: '\e816'; }

/* '' */
.icon-star-empty:before {
  content: '\e817'; }

/* '' */
.icon-thumbs-up:before {
  content: '\e818'; }

/* '' */
.icon-thumbs-down:before {
  content: '\e819'; }

/* '' */
.icon-thumbs-up-alt:before {
  content: '\e81a'; }

/* '' */
.icon-thumbs-down-alt:before {
  content: '\e81b'; }

/* '' */
.icon-twitter:before {
  content: '\f099'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-menu:before {
  content: '\f0c9'; }

/* '' */
.icon-gplus:before {
  content: '\f0d5'; }

/* '' */
.icon-linkedin:before {
  content: '\f0e1'; }

/* '' */
.icon-doc-text:before {
  content: '\f0f6'; }

/* '' */
.icon-angle-left:before {
  content: '\f104'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-angle-up:before {
  content: '\f106'; }

/* '' */
.icon-angle-down:before {
  content: '\f107'; }

/* '' */
.icon-circle-empty:before {
  content: '\f10c'; }

/* '' */
.icon-youtube:before {
  content: '\f167'; }

/* '' */
.icon-youtube-play:before {
  content: '\f16a'; }

/* '' */
.icon-instagram:before {
  content: '\f16d'; }

/* '' */
.icon-tumblr:before {
  content: '\f173'; }

/* '' */
.icon-dot-circled:before {
  content: '\f192'; }

/* '' */
.icon-angle-double-right:before {
  content: '\f193'; }

/* '' */
.icon-angle-double-up:before {
  content: '\f194'; }

/* '' */
.icon-angle-double-down:before {
  content: '\f195'; }

/* '' */
.icon-angle-double-left:before {
  content: '\f196'; }

/* '' */
.icon-stumbleupon:before {
  content: '\f1a4'; }

/* '' */
.icon-delicious:before {
  content: '\f1a5'; }

/* '' */
.icon-digg:before {
  content: '\f1a6'; }

/* '' */
.icon-soundcloud:before {
  content: '\f1be'; }

/* '' */
.icon-file-pdf:before {
  content: '\f1c1'; }

/* '' */
.icon-file-word:before {
  content: '\f1c2'; }

/* '' */
.icon-file-audio:before {
  content: '\f1c7'; }

/* '' */
.icon-circle-thin:before {
  content: '\f1db'; }

/* '' */
.icon-pinterest:before {
  content: '\f231'; }

/* '' */
.icon-reddit-alien:before {
  content: '\f281'; }

/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
@font-face {
  font-family: 'Nassim';
  src: url("../fonts/nassim/Nassim.woff") format("woff"), url("../fonts/nassim/Nassim.ttf") format("truetype"), url("../fonts/nassim/Nassim.eot"), url("../fonts/nassim/Nassim.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Nassim';
  src: url("../fonts/nassim/NassimBold.woff") format("woff"), url("../fonts/nassim/NassimBold.ttf") format("truetype"), url("../fonts/nassim/NassimBold.eot"), url("../fonts/nassim/NassimBold.eot?#iefix") format("embedded-opentype");
  font-weight: bold;
  font-style: normal; }

.commons-pod, .front.not-logged-in .pane-views-panes, .front.not-logged-in .pane-views, .front.not-logged-in
body.page-node .pane-views-panes, .page-events .view-commons-events-upcoming .view-content, body.front.logged-in .view-commons-homepage-content > .view-content, body.front.logged-in #views-exposed-form-commons-homepage-content-panel-pane-1 .views-exposed-widgets.widgets-active, .pane-search-result .block-content .search-results, .page-search-user .user-results, .view-display-id-commons_events_upcoming_page .views-row, .view-commons-resources-latest-resources.view-display-id-panel_pane_2 .view-content, .pane-commons-bw-group, .page-events .region-three-25-50-25-third .pane-views-panes, .page-user .pane-views-panes, .view-commons-jobs-latest-jobs.view-display-id-panel_pane_2 .view-content {
  margin: 0 0 10px; }

.commons-pod, .front.not-logged-in .pane-views-panes > div, .front.not-logged-in
.pane-views > div, .front.not-logged-in
body.page-node .pane-views-panes > div, .page-events .view-commons-events-upcoming .view-content, body.front.logged-in #views-exposed-form-commons-homepage-content-panel-pane-1 .views-exposed-widgets.widgets-active, .pane-search-result .block-content .search-results, .page-search-user .user-results, .view-display-id-commons_events_upcoming_page .views-row article, .view-commons-resources-latest-resources.view-display-id-panel_pane_2 .view-content, .pane-commons-bw-group > .block-inner, .page-events .region-three-25-50-25-third .pane-views-panes .block-inner, .page-user .pane-views-panes .block-inner, .view-commons-jobs-latest-jobs.view-display-id-panel_pane_2 .view-content {
  padding: 0; }

.node.node-event > .node-content, .node.node-poll > .node-content, .node.node-question > .node-content, .node-teaser.node-promoted.node-event > .node-content, .node-teaser.node-promoted.node-poll > .node-content, .node-teaser.node-promoted.node-question > .node-content {
  margin-right: 10em; }
  @media only screen and (max-width: 768px) {
    .node.node-event > .node-content, .node.node-poll > .node-content, .node.node-question > .node-content, .node-teaser.node-promoted.node-event > .node-content, .node-teaser.node-promoted.node-poll > .node-content, .node-teaser.node-promoted.node-question > .node-content {
      margin-right: 0; } }

.container {
  width: 100% !important;
  max-width: 1280px;
  margin: 0 auto; }

/**
 * Wraps all header elements - branding and .region-header
 */
.header-wrapper {
  overflow: hidden; }

#header {
  position: relative;
  box-sizing: border-box;
  text-align: left;
  padding-right: 10px;
  padding-left: 10px;
  white-space: nowrap;
  display: table;
  table-layout: fixed; }
  @media only screen and (max-width: 768px) {
    #header {
      padding: 0; } }

/* =============================================================================
 *   Branding
 * ========================================================================== */
/**
 * Wraps all the branding elements, logo, name and slogan
 */
#branding {
  box-sizing: border-box;
  text-align: left;
  position: relative;
  width: 25%;
  display: table-cell;
  padding-top: 10px;
  vertical-align: bottom; }

/**
 * Logo
 */
#logo {
  position: relative;
  display: block;
  z-index: 3;
  width: 75%;
  margin: 0 auto;
  background: #ffffff; }
  #logo .slogan {
    display: block;
    background: #fff; }

/**
 * Wrapper for the site name and slogan (hgroup)
 */
#name-and-slogan {
  display: block;
  box-sizing: border-box; }
  @media only screen and (max-width: 480px) {
    #name-and-slogan {
      display: none; } }

/**
 * Site name (h1)
 */
#site-name {
  margin: .3em 0 0;
  font-family: 'Trebuchet MS';
  font-weight: 700; }
  #site-name a:link, #site-name a:visited {
    text-decoration: none; }
  #site-name a:hover, #site-name a:focus {
    text-decoration: none; }
  @media only screen and (min-width: 481px) {
    #site-name {
      font-size: 1.4em; } }

/**
 * Site slogan (h2)
 */
#site-slogan {
  margin: 0 0 .3em;
  font-size: 1em;
  color: #fff; }

#site-name,
#site-slogan {
  display: block;
  overflow: hidden;
  padding-right: 10px;
  line-height: 1.2em;
  white-space: normal; }

/* =============================================================================
 *   Page content header
 * ========================================================================== */
/**
 * The main page title (h1)
 */
#page-title,
.search-results-title {
  margin: 0 0 .5em;
  font-weight: bold;
  font-size: 2.75em;
  line-height: 1.2em;
  color: #000;
  text-align: left; }

.page-messages-view #page-title,
.page-flag-confirm #page-title {
  /* Some pages require a smaller title to prevent the equivalent of
       "yelling". */
  font-size: 2em; }

#page-top {
  margin: 20px 0 0; }

#header #menu-bar.nav {
  border-top: 2px solid #941b80;
  clear: both;
  width: 100%; }

#header .region-header {
  max-width: 320px;
  min-width: 240px;
  width: 50%;
  float: right; }

#header .nav-header {
  box-sizing: border-box;
  width: 75%;
  display: table-cell;
  vertical-align: bottom; }

/**
 * Header region, embedded in the #header
 */
/**
 * Footer wrapper
 */
#footer {
  border-top: 2px dashed #941b80;
  padding: 1em 0 0;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  #footer .region-footer {
    padding: 1em 0;
    border-top: 2px dashed #941b80; }
    #footer .region-footer .block {
      max-width: 1280px;
      margin: 0 auto; }

#l10n-client {
  z-index: 100000; }

/* =============================================================================
 *   Primary, Secondary and Menu Bar region menus
 * ========================================================================== */
#primary-menu-bar {
  display: inline-block; }

/**
 * Use one of the following id's for granular control:
 *  - #menu-bar (menu bar region)
 *  - #primary-menu (main menu)
 *  - #secondary-menu (secondary/user menu)
 * You can target all of these with .nav or div[id$="menu-bar"]
 */
#menu-bar.nav h3.block-title {
  display: none; }

#menu-bar.nav ul.menu {
  margin: 0;
  padding: 0; }

#menu-bar.nav ul.menu li {
  display: inline-block;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0; }
  #menu-bar.nav ul.menu li:hover .arrow:before, #menu-bar.nav ul.menu li.active-trail .arrow:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 7px 8px 7px;
    border-color: transparent transparent #00cbe5 transparent; }
  #menu-bar.nav ul.menu li:hover .arrow:after, #menu-bar.nav ul.menu li.active-trail .arrow:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 1px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #009eb2 transparent; }
  #menu-bar.nav ul.menu li a {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    display: block;
    white-space: nowrap;
    padding: 0 1em;
    margin-top: 10px;
    line-height: 1;
    color: #000;
    font-size: 1.33em;
    font-weight: bold; }
    #menu-bar.nav ul.menu li a:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -5px;
      height: 10px;
      width: 3px;
      background: #941b80; }
    #menu-bar.nav ul.menu li a:hover, #menu-bar.nav ul.menu li a:focus, #menu-bar.nav ul.menu li a:active {
      text-decoration: none;
      color: #941b80; }
    #menu-bar.nav ul.menu li a.active {
      color: #941b80; }
    #menu-bar.nav ul.menu li a .arrow {
      width: 17px;
      height: 9px;
      position: absolute;
      left: 50%;
      bottom: -1px;
      margin: 0 0 0 -8px; }
  #menu-bar.nav ul.menu li:first-child a {
    padding-left: 0; }
    #menu-bar.nav ul.menu li:first-child a:after {
      display: none; }

#menu-bar.nav .block {
  margin: 0; }

@media only screen and (max-width: 480px) {
  #menu-bar.nav {
    border-right: 0; }
    #menu-bar.nav ul.menu li a {
      font: 0/0 a;
      width: 40px;
      height: 50px; } }

@media only screen and (min-width: 481px) and (max-width: 768px) {
  #menu-bar.nav {
    border-right: none; }
    #menu-bar.nav ul.menu li a {
      padding-right: 0.4375em;
      padding-left: 0.4375em; } }

#menu-bar.nav ul.menu li.main-menu-search {
  display: none; }
  @media only screen and (max-width: 768px) {
    #menu-bar.nav ul.menu li.main-menu-search {
      display: inline-block; } }

#block-commons-utility-links-commons-utility-links {
  margin: 0; }
  #block-commons-utility-links-commons-utility-links .block-inner {
    margin: 0; }

ul.commons-utility-links {
  list-style: none;
  text-align: right;
  margin: .2em 0;
  padding: 0;
  line-height: 1; }
  .block-content ul.commons-utility-links {
    padding: 0; }
  ul.commons-utility-links li {
    display: inline-block; }
    .logged-in ul.commons-utility-links li.first {
      margin-right: 17px;
      font-weight: bold;
      float: left; }
    ul.commons-utility-links li.last {
      border-left: 1px solid #009eb2;
      margin-left: 9px; }
      ul.commons-utility-links li.last a {
        margin-left: 9px; }

.unread-invitations a {
  background: sprite(sprite-map("icons/*.png", 20px), icon-person) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), icon-person));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), icon-person));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

.unread-invitations a,
.unread-messages a,
.no-unread-messages a {
  position: relative;
  top: .3em;
  display: inline-block;
  margin-top: -.2em;
  margin-right: .8em; }

.no-unread-messages a {
  /*top: -.1em;
    margin-top: 0;
    text-indent: -100%;
    white-space: nowrap;
    overflow: hidden;
    @include opacity(.4);*/
  display: none; }

.notification-count {
  position: absolute;
  top: -.2em;
  right: -.6em;
  display: inline-block;
  padding: 0 .2em;
  font-size: .88em;
  border-radius: .4em;
  box-shadow: 0 0 2px #009eb2;
  background: #46ba31; }

/* =============================================================================
 *   Superfish
 * ========================================================================== */
ul.sf-menu {
  margin-bottom: 0; }
  ul.sf-menu a {
    border-left: 0;
    border-top: 0;
    padding: 0 10px;
    text-decoration: none;
    height: 2.5em;
    line-height: 2.5em; }
  ul.sf-menu li:hover, ul.sf-menu li.sfHover {
    outline: 0; }
  ul.sf-menu a:focus, ul.sf-menu a:hover, ul.sf-menu a:active {
    outline: 0; }

/**
 * Superfish blocks
 */
.block-superfish ul {
  margin: 0 !important;
  padding: 0 !important; }

.block-superfish li {
  margin: 0 !important;
  padding: 0 !important; }

/**
 * Vertical style
 */
.sf-vertical {
  width: 100%; }
  .sf-vertical li {
    width: 100%; }
    .sf-vertical li:hover ul,
    .sf-vertical li.sfHover ul {
      left: 100%;
      top: 0;
      margin: 0;
      padding: 0; }
    .sf-vertical li a {
      padding: 0 10px; }

/**
 * Navbar style
 */
.sf-navbar {
  padding-bottom: 0 !important; }

/**
 * Sensible padding for the default style
 */
.sf-menu.sf-style-default a {
  padding: 0 10px; }

/* =============================================================================
 *   Menus, usually blocks
 * ========================================================================== */
ul.menu {
  padding-left: 15px; }
  ul.menu ul {
    padding-left: 15px; }
    ul.menu ul li {
      margin: 0; }

/* If li.content exists it's a problem, so reset the padding */
.block .menu li.content {
  padding: 0; }

/* =============================================================================
 *   Book navigation menu
 * ========================================================================== */
.book-navigation .page-up {
  /* Prevent text wrapping to a new line, assumes English "up" is used (two characters) */
  min-width: 2em;
  white-space: nowrap; }

.book-navigation .menu {
  margin-left: 0; }

.panels-flexible-two_25_75 .panel-pane {
  margin-bottom: 0; }

.panels-flexible-two_25_75 .panels-flexible-region-two_25_75-first {
  background-size: 12px 12px !important;
  background-image: linear-gradient(45deg, transparent 49%, #b3b3b3 50%, #b3b3b3 51%, transparent 52%);
  border-top: 2px solid #941b80;
  padding-top: 5px; }
  .panels-flexible-two_25_75 .panels-flexible-region-two_25_75-first .panels-flexible-region-inside {
    padding: 0; }
  .panels-flexible-two_25_75 .panels-flexible-region-two_25_75-first:before {
    content: '';
    border-top: 2px solid #941b80;
    display: block; }

.panels-flexible-two_25_75 .panels-flexible-two_25_75-inside {
  display: table;
  table-layout: fixed;
  width: 100%; }
  .panels-flexible-two_25_75 .panels-flexible-two_25_75-inside .panels-flexible-region {
    display: table-cell;
    vertical-align: top;
    float: none; }

.panels-flexible-two_33_66 .panel-pane {
  margin-bottom: 0; }

.panels-flexible-two_33_66 .panels-flexible-two_33_66-inside {
  display: table;
  table-layout: fixed;
  width: 100%; }
  .panels-flexible-two_33_66 .panels-flexible-two_33_66-inside .panels-flexible-region {
    display: table-cell;
    vertical-align: top;
    float: none; }
  .panels-flexible-two_33_66 .panels-flexible-two_33_66-inside .block {
    background: none;
    border: none; }

.pane-title {
  color: #000;
  font-weight: bold; }

.panel-pane.page-header .block-content {
  padding: 0 1em; }
  .panel-pane.page-header .block-content .block-title {
    font-size: 2em;
    font-weight: bold;
    color: #000;
    font-family: "Nassim", sans-serif;
    margin-top: 0; }
    .panel-pane.page-header .block-content .block-title a {
      color: inherit;
      text-decoration: none !important; }

.panel-pane.page-header.pane-user-name .block-content {
  font-size: 2em;
  font-weight: bold;
  color: #000;
  font-family: "Nassim", sans-serif;
  margin-top: 0; }
  .panel-pane.page-header.pane-user-name .block-content a {
    color: inherit;
    text-decoration: none !important; }

.block.pane-node-content {
  margin-bottom: 0; }

.page-groups #page-title, .page-resources #page-title, .page-jobs #page-title {
  visibility: hidden;
  margin: 0;
  font-size: 2em; }

/*
 * @file
 * global.base
 *
 * - normalize with small modifications for Drupal and AT: http://necolas.github.com/normalize.css/
 * - Gutter widths, see $gutter-width in _custom.scss
 * - Default container alignment.
 * - Flexible images and media declarations.
 * - Copies of the SCSS mixins for reference, which includes an improved
 *   element-invisible and focusable styles.
 */
/* =============================================================================
    HTML5 display definitions
   ========================================================================== */
/*
 * Corrects block display not defined in IE6/7/8/9 & FF3
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block; }

/*
 * Corrects inline-block display not defined in IE6/7/8/9 & FF3
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

/*
 * Prevents modern browsers from displaying 'audio' without controls
 * Remove excess height in iOS5 devices
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/*
 * Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support
 */
[hidden] {
  display: none; }

/* =============================================================================
    Base
   ========================================================================== */
/*
 * If you are using the themes Responsive JS feature (media_queries.js) do not
 * set a font-family on the html element, use body.
 *
 * 1. Corrects text resizing oddly in IE6/7 when body font-size is set using em units
 *    http://clagnut.com/blog/348/#c790
 * 2. Prevents iOS text size adjust after orientation change, without disabling user zoom
 *    www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
 */
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.7;
  overflow-y: scroll; }

/*
 * 1 . Addresses margins handled incorrectly in IE6/7
 */
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility\9;
  /* Only IE, can mess with Android */ }

/*
 * Addresses font-family inconsistency between 'textarea' and other form elements.
 */
button,
input,
select,
textarea {
  font-family: sans-serif; }

/* =============================================================================
    Links
   ========================================================================== */
/*
 * Addresses outline displayed oddly in Chrome
 */
a:focus {
  outline: thin dotted; }

/*
 * Improves readability when focused and also mouse hovered in all browsers
 * people.opera.com/patrickl/experiments/keyboard/test
 */
a:hover,
a:active {
  outline: 0; }

/* =============================================================================
    Typography
   ========================================================================== */
/*
 * Headings
 *
 * Addresses font sizes and margins set differently in IE6/7
 * Addresses font sizes within 'section' and 'article' in FF4+, Chrome, S5
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

h2 {
  font-size: 1.5em;
  margin: 0.83em 0; }

h3 {
  font-size: 1.17em;
  margin: 1em 0; }

h4 {
  font-size: 1em;
  margin: 1.33em 0; }

h5 {
  font-size: 0.83em;
  margin: 1.67em 0; }

h6 {
  font-size: 0.75em;
  margin: 2.33em 0; }

/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */
abbr[title] {
  border-bottom: 1px dotted; }

/*
 * Addresses style set to 'bolder' in FF3+, S4/5, Chrome
 */
b,
strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

/*
 * Addresses styling not present in S5, Chrome
 */
dfn {
  font-style: italic; }

/*
 * Addresses styling not present in IE6/7/8/9
 */
mark {
  background: #ff0;
  color: #000; }

/*
 * Addresses margins set differently in IE6/7
 */
p,
pre {
  margin: 0 0 1.5em; }

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

/*
 * Improves readability of pre-formatted text in all browsers
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */
q {
  quotes: none; }

q:before,
q:after {
  content: '';
  content: none; }

small {
  font-size: 75%; }

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* =============================================================================
    Lists
   ========================================================================== */
/*
 * Addresses margins set differently in IE6/7
 */
dl,
menu,
ol,
ul {
  margin: 1em 0; }

dd {
  margin: 0 0 0 40px; }

/*
 * Addresses paddings set differently in IE6/7
 */
menu,
ol,
ul {
  padding: 0 0 0 40px; }

/*
 * Corrects list images handled incorrectly in IE7
 */
nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

/* =============================================================================
    Embedded content
   ========================================================================== */
/*
 * 1. Remove border when inside 'a' element in IE6/7/8/9, FF3
 * 2. Improves image quality when scaled in IE7
 *    code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
 */
img {
  /* border: 0; */
  /* Drupal core already does this, uncomment if you need it */
  -ms-interpolation-mode: bicubic; }

/*
 * Corrects overflow displayed oddly in IE9
 */
svg:not(:root) {
  overflow: hidden; }

/* =============================================================================
    Figures
   ========================================================================== */
/*
 * Addresses margin not present in IE6/7/8/9, S5, O11
 */
figure {
  margin: 0; }

/* =============================================================================
    Forms
   ========================================================================== */
/*
 * 1. Corrects font size not being inherited in all browsers
 * 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
 * 3. Improves appearance and consistency in all browsers
 */
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

/*
 * 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 * 3. Removes excess padding in IE7
 *    Known issue: excess padding remains in IE6
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px; }

/*
 * 1. Addresses appearance set to searchfield in S5, Chrome
 * 2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
 */
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

/*
 * Removes inner padding and search cancel button in S5, Chrome on OS X
 */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/*
 * 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers
 */
textarea {
  overflow: auto;
  vertical-align: top; }

/* =============================================================================
    Tables
   ========================================================================== */
/*
 * 1. Remove most spacing between table cells
 */
table {
  border: 1px solid;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: inherit;
  font: 100%; }

/* =============================================================================
    Flexible Media and Cross browser improvements
   ========================================================================== */
/*
 * - This can cause issues in table cells where no width is set on the column,
 *   only in some versions of webkit and IE 7/8.
 * - declaring the width attribute on image elements and using max-width causes
 *   the "dissappering images" bug in IE8.
 * - Mapping services such as Google Maps may break also, see below for fixes.
 */
img {
  height: auto;
  -ms-interpolation-mode: bicubic; }

/* Ethan Marcotte - http://front.ie/l8rJaA */
img,
embed,
object,
video {
  max-width: 100%; }

/* Disable flexiblity for IE8 and below */
.lt-ie9 img,
.lt-ie9 object,
.lt-ie9 embed,
.lt-ie9 video {
  max-width: none; }

/* Override max-width 100% for map displays */
#map img,
.gmap img,
.view-gmap img,
.openlayers-map img,
#getlocations_map_canvas img,
#locationmap_map img,
.geofieldMap img,
.views_horizontal_slider img {
  max-width: none !important; }

/* =============================================================================
    Misc helpers, Accessibility classes etc
   ========================================================================== */
/* Prevent overflowing content */
header[role=banner],
.content-inner,
.nav,
.region-sidebar-first,
.region-sidebar-second,
.region-secondary-content,
.region-tertiary-content,
.region-footer {
  overflow: visible;
  word-wrap: break-word; }

/**
 * General classes for hiding content. These are all included as SASS mixins.
 * @SEE: sass/_custom.scss
 */
/**
 * Image replacement
 * Kellum Method: http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
 * Additional helpers from http://html5boilerplate.com/docs/css/
 */
.ir {
  /* Kellum Method */
  display: block !important;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  /* Remove the default border from elements like button */
  border: 0;
  /* Crush the text down to take up no space */
  font: 0/0 a;
  /* Remove any text shadows */
  text-shadow: none;
  /* Hide any residual text in Safari 4 and any mobile devices that may need it */
  color: transparent;
  /* Hide the default background color on elements like button */
  background-color: transparent; }

/* More robust element-invisible and element-focuable classes */
.element-invisible {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  width: auto; }

/* Shift content offscreen, can be usefull when you reall need to do this */
.offscreen {
  position: absolute;
  top: -99999em;
  width: 1px;
  height: 1px;
  overflow: hidden;
  outline: 0; }

/* Hide content from all users */
.element-hidden {
  display: none; }

/**
 * @file
 * global.styles
 *
 * Styles you add here will load for all device sizes, they are "global", as
 * opposed to "responsive" styles, which only load for a given breakpoint (media
 * query) that you set in theme settings. See the README for more details.
 *
 *
 * How to use this file:
 * --------------------
 *
 * There are many empty selectors and some basic styles to act as a guide.
 * Nothing is set in stone and you can change anything - even delete all of it
 * and start with your own clean slate.
 *
 * To FORCE PRINT all selectors you can search and replace a single { (opening
 * curly brace) and insert an empty comment. This is very useful in conjuction
 * with FireSass: https://addons.mozilla.org/en-US/firefox/addon/firesass-for-firebug/
 *
 * See global.base also which includes normalize.css and some additional
 * helper classes and base styles.
 */
/* =============================================================================
 *   Base
 * ========================================================================== */
/**
 * To preserve theme settings never apply font properties to the HTML element.
 * This is critically important if you are using the Responsive JavaScript
 * feature as this relies on being able to set a pseudo font family on the HTML
 * element. If you need to set default font properties of any kind use the BODY
 * element as these can be overridden using font theme settings.
 */
html {
  background: #ffffff;
  font-size: 100%;
  min-height: 100%; }
  html .cke_editable {
    box-sizing: border-box;
    padding: 1em;
    background: #fff;
    min-height: 200px; }

body {
  font-family: "Nassim", sans-serif;
  color: #000;
  min-width: 18em;
  overflow-x: hidden; }

/* =============================================================================
 *   HTML Elements
 * ========================================================================== */
h1, h2, h3, h4, h5, h6 {
  font-family: "Nassim", sans-serif;
  color: #000; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit; }
    h1 a :hover, h2 a :hover, h3 a :hover, h4 a :hover, h5 a :hover, h6 a :hover {
      text-decoration: none; }

h1 {
  font-size: 2.5em;
  line-height: 1.2;
  font-weight: normal; }
  .site-name-long h1 {
    font-size: 20px;
    line-height: 25px; }
  .site-name-long-2-lines h1 {
    font-size: 20px;
    line-height: 25px; }

h2 {
  font-size: 2em; }

h3 {
  font-size: 1.4em; }
  .sidebar h3 {
    color: #666666; }

pre,
code,
tt,
samp,
kbd,
var {
  font-family: Consolas, Monaco, "Courier New", Courier, monospace, sans-serif; }

/* =============================================================================
 *   Wrappers
 * ========================================================================== */
/**
 * Page wrapper, includes the .container class which sets the overall page or section width
 * Main wrapper in page.tpl.php, the .container class sets the width of the page, do not add width to it!
 */
/**
 * Wraps the sidebars the content column
 */
#columns {
  margin-right: 10px;
  margin-left: 10px; }

/**
 * Default credit block
 */
#block-block-1 .block-title,
#block-block-1 .block-content {
  color: #858585;
  text-align: center; }

#block-block-1 .block-title {
  margin: 10px 0 5px; }

#block-block-1 .block-content {
  font-size: .88em; }

#footer #block-block-1 {
  margin-bottom: 0; }

/**
 * Generic pod wrappers
 */
.commons-pod {
  /* Prevent pod nesting. */ }
  .commons-pod .commons-pod {
    margin: 0;
    padding: 0;
    background: transparent;
    box-shadow: none; }

/* =============================================================================
 *   Misc Global Styles
 * ========================================================================== */
/**
 * Generic styles for nodes.
 */
.node-actions {
  box-sizing: border-box;
  float: right;
  width: 10em;
  list-style: none;
  margin: .5em -10em 0 0;
  padding-left: 1em;
  text-align: center; }
  .node-actions [class*="action-item"], .node-actions [class*="action-item"][type="submit"] {
    margin: 0; }
  @media only screen and (max-width: 768px) {
    .node-actions {
      float: none;
      width: auto;
      margin: 1.7em 0;
      text-align: left; } }

.node-teaser.node-promoted {
  position: relative;
  background-color: #f7f7f7; }
  .node-teaser.node-promoted:after {
    position: absolute;
    top: 0;
    right: 0;
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    font-size: 1rem;
    line-height: 1;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    font-size: 1rem;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "";
    color: #941b80;
    margin: .5rem;
    font-size: 2rem; }
  .node-teaser.node-promoted .flag-wrapper {
    top: 2em; }

.user-picture,
.views-field-picture,
.profile-badge-picture {
  background: #000;
  display: inline-block;
  overflow: hidden;
  border-radius: 100%;
  line-height: 0; }
  .node .user-picture:after,
  .comment .user-picture:after,
  .privatemsg-message .user-picture:after, .node
  .views-field-picture:after,
  .comment
  .views-field-picture:after,
  .privatemsg-message
  .views-field-picture:after, .node
  .profile-badge-picture:after,
  .comment
  .profile-badge-picture:after,
  .privatemsg-message
  .profile-badge-picture:after {
    content: "";
    width: 5px;
    height: 8px;
    position: absolute;
    right: -5px;
    top: 10px;
    background: url("../img/user_img_arrow.png"); }
  .node .user-picture img,
  .comment .user-picture img,
  .privatemsg-message .user-picture img, .node
  .views-field-picture img,
  .comment
  .views-field-picture img,
  .privatemsg-message
  .views-field-picture img, .node
  .profile-badge-picture img,
  .comment
  .profile-badge-picture img,
  .privatemsg-message
  .profile-badge-picture img {
    width: 50px; }

/**
 * Form styles
 *
 * These styles are extracted from the Formalize library by Nathan Smith. The
 * specific support for IE below 8 is trimmed along with references to font
 * families and the extra buttons styles.
 */
input:invalid,
button:invalid,
select:invalid,
textarea:invalid,
[class*=" action-item"]:invalid {
  box-shadow: none; }

input:focus,
button:focus,
select:focus,
textarea:focus,
[class*=" action-item"]:focus {
  box-shadow: #0066ff 0 0 5px 0;
  z-index: 1; }

label {
  margin-top: .5em;
  margin-bottom: .5em; }

input[type="file"]:focus, input[type="file"]:active,
input[type="radio"]:focus,
input[type="radio"]:active,
input[type="checkbox"]:focus,
input[type="checkbox"]:active {
  box-shadow: none; }

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  box-sizing: border-box;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-radius: 2px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #941b80;
  color: black;
  outline: 0;
  margin: 0;
  padding: .5em;
  text-align: left;
  vertical-align: top;
  line-height: 1.2em; }
  textarea[disabled],
  select[disabled],
  input[type="date"][disabled],
  input[type="datetime"][disabled],
  input[type="datetime-local"][disabled],
  input[type="email"][disabled],
  input[type="month"][disabled],
  input[type="number"][disabled],
  input[type="password"][disabled],
  input[type="search"][disabled],
  input[type="tel"][disabled],
  input[type="text"][disabled],
  input[type="time"][disabled],
  input[type="url"][disabled],
  input[type="week"][disabled] {
    background-color: #eeeeee; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #888888; }

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #888888; }

textarea,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  width: 100%;
  max-width: 100%; }

textarea,
select[size],
select[multiple] {
  height: auto; }

@media (-webkit-min-device-pixel-ratio: 0) {
  select[size],
  select[multiple],
  select[multiple][size] {
    background-image: none;
    padding-right: 3px; }
  select,
  select[size="0"],
  select[size="1"] {
    border-radius: 3px;
    background-image: url(data:image/png;base64,R0lGODlhDQAEAIAAAAAAAP8A/yH5BAEHAAEALAAAAAANAAQAAAILhA+hG5jMDpxvhgIAOw==);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px; }
  ::-webkit-validation-bubble-message {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #666666), color-stop(1, black));
    border: 1px solid;
    border-color: #747474 #5e5e5e #4f4f4f;
    color: white;
    overflow: hidden;
    padding: 15px 15px 17px;
    text-shadow: black 0 0 1px;
    height: 16px; }
  ::-webkit-validation-bubble-arrow,
  ::-webkit-validation-bubble-top-outer-arrow,
  ::-webkit-validation-bubble-top-inner-arrow {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #666666;
    border: 0; } }

textarea {
  overflow: auto;
  resize: vertical;
  width: 100%; }

optgroup {
  color: black;
  font-style: normal;
  font-weight: normal; }
  optgroup::-moz-focus-inner {
    border: 0;
    padding: 0; }

/* Simulate a chosen select form value as inline text. */
.hidden-select-wrapper {
  position: relative;
  display: inline-block;
  -webkit-transition: width 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition: width 0.2s;
  -o-transition: width 0.2s;
  transition: width 0.2s false false; }

.select-status {
  display: inline-block;
  white-space: nowrap;
  -webkit-transition: opacity 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s false false; }
  .select-status:after {
    content: "";
    position: relative;
    top: .2em;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .2em;
    border: .4em solid transparent;
    border-top-color: #009eb2; }

.hidden-select {
  position: absolute;
  top: -.35em;
  left: -.35em;
  margin: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0; }
  .select-inactive .hidden-select {
    width: 100%; }

/* Replace the default Drupal throbber with a custom one. */
html.js input.form-autocomplete {
  background-image: none;
  background-position: right center;
  padding-right: 20px; }
  html.js input.form-autocomplete.throbbing {
    background-image: image-url("commons-throbber.gif");
    background-position: right center; }

.general-settings {
  padding-top: 0;
  padding-bottom: 0; }

.fieldset-outer-wrapper {
  margin: 1.7em 0;
  padding: 1.7em 0;
  border-top: 1px solid #941b80;
  border-color: #941b80;
  border-style: solid;
  border-width: 1px 0;
  /* Some form items have nested fieldsets that mess with the sibling
     selectors and need some extra love. */ }
  .fieldset-outer-wrapper + .fieldset-outer-wrapper {
    border-top-width: 0; }
  .general-settings .fieldset-outer-wrapper {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px; }
  .general-settings > .fieldset-outer-wrapper {
    margin-left: -10px;
    margin-right: -10px; }
    .general-settings > .fieldset-outer-wrapper:first-child {
      border-top: none; }
    .general-settings > .fieldset-outer-wrapper:last-child {
      border-bottom-width: 0; }
  .field-name-og-group-ref .fieldset-outer-wrapper {
    border-top: none;
    margin-top: 0;
    padding-top: 0; }
  .field-name-og-group-ref .fieldset-outer-wrapper,
  .field-type-datetime-form .fieldset-outer-wrapper {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0; }

fieldset {
  border: none;
  margin: 0;
  padding: .01em 0;
  box-sizing: border-box; }

legend {
  -webkit-padding-start: 0;
  -webkit-padding-end: 0;
  line-height: 1.2em; }
  legend + .fieldset-wrapper {
    -webkit-margin-top-collapse: separate;
    margin-top: 1.2em; }

.fieldset-legend {
  display: block;
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: light; }
  html.js fieldset.collapsed .fieldset-legend,
  html.js fieldset.collapsible .fieldset-legend {
    padding: 0;
    background: none; }
  .fieldset-legend span.summary {
    display: block;
    margin: 0;
    font-size: .76em;
    font-weight: normal; }
  .collapsible > legend .fieldset-legend {
    display: block;
    text-indent: -.5em;
    margin-left: .6em; }
    .collapsible > legend .fieldset-legend span.summary {
      margin-left: .7em; }
    .collapsible > legend .fieldset-legend a:before {
      display: inline-block;
      position: relative;
      margin-right: .2em;
      width: 0;
      height: 0;
      overflow: hidden;
      border: .2em solid transparent;
      border-top-color: #333333;
      content: "";
      top: -.1em;
      left: -.1em; }
  .collapsed > legend .fieldset-legend a:before {
    border-top-color: transparent;
    border-left-color: #333333;
    top: -.2em;
    left: auto; }

.form-item:first-child {
  margin-top: 0; }

.form-item:last-child {
  margin-bottom: 0; }

.filter-wrapper {
  margin: 0;
  padding: 0; }

/**
 * Button styles
 */
/* Common styles for all buttons. */
.button,
button,
[type="reset"],
[type="submit"],
[type="button"],
[class*="action-item"], .pane-commons-groups-commons-groups-create-group a, .pane-commons-events-commons-events-create-event-link a, .bean-cta .field-name-field-link a, [class*="action-item-small"], .commons-sign-up,
.commons-login, [class*="action-item-large"] {
  -webkit-appearance: none;
  display: inline-block;
  box-sizing: border-box;
  margin: .5em .3em .5em 0;
  max-width: 100%;
  overflow: visible;
  line-height: 1.2em;
  vertical-align: baseline;
  border-style: solid;
  border-width: 1px;
  /* Reset all button fonts to a similar baseline in webkit. */
  font: -webkit-small-control;
  text-align: center;
  text-decoration: none;
  outline: 0;
  cursor: pointer; }
  .button:focus,
  button:focus,
  [type="reset"]:focus,
  [type="submit"]:focus,
  [type="button"]:focus,
  [class*="action-item"]:focus, .pane-commons-groups-commons-groups-create-group a:focus, .pane-commons-events-commons-events-create-event-link a:focus, .bean-cta .field-name-field-link a:focus, [class*="action-item-small"]:focus, .commons-sign-up:focus,
  .commons-login:focus, [class*="action-item-large"]:focus, .button:hover,
  button:hover,
  [type="reset"]:hover,
  [type="submit"]:hover,
  [type="button"]:hover,
  [class*="action-item"]:hover, .pane-commons-groups-commons-groups-create-group a:hover, .pane-commons-events-commons-events-create-event-link a:hover, .bean-cta .field-name-field-link a:hover, [class*="action-item-small"]:hover, .commons-sign-up:hover,
  .commons-login:hover, [class*="action-item-large"]:hover {
    text-decoration: none; }
  .button:active,
  button:active,
  [type="reset"]:active,
  [type="submit"]:active,
  [type="button"]:active,
  [class*="action-item"]:active, .pane-commons-groups-commons-groups-create-group a:active, .pane-commons-events-commons-events-create-event-link a:active, .bean-cta .field-name-field-link a:active, [class*="action-item-small"]:active, .commons-sign-up:active,
  .commons-login:active, [class*="action-item-large"]:active {
    box-shadow: inset rgba(0, 0, 0, 0.25) 0 1px 2px 0;
    text-decoration: none; }
  .button::-moz-focus-inner,
  button::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [class*="action-item"]::-moz-focus-inner, .pane-commons-groups-commons-groups-create-group a::-moz-focus-inner, .pane-commons-events-commons-events-create-event-link a::-moz-focus-inner, .bean-cta .field-name-field-link a::-moz-focus-inner, [class*="action-item-small"]::-moz-focus-inner, .commons-sign-up::-moz-focus-inner,
  .commons-login::-moz-focus-inner, [class*="action-item-large"]::-moz-focus-inner {
    border: 0;
    padding: 0; }

/* Dimensions for the default button type. */
.button,
button,
[type="reset"],
[type="submit"],
[type="button"],
[class*="action-item"], .pane-commons-groups-commons-groups-create-group a, .pane-commons-events-commons-events-create-event-link a, .bean-cta .field-name-field-link a {
  padding: 0.5em 1.5em;
  font-size: 1em; }

/* Dimensions for the small button type. */
[class*="action-item-small"], .commons-sign-up,
.commons-login {
  padding: 0.3em 1em;
  font-size: 0.88em; }

/* Dimensions for the large button type. */
[class*="action-item-large"] {
  padding: 0.5em 1.5em;
  font-size: 1.2em; }

.button,
button,
[type="reset"],
[type="submit"],
[type="button"],
[class*="action-item"], .commons-login, .bean-cta .field-name-field-link a {
  background-color: #941b80;
  border-color: #3e0b35;
  /*@include text-shadow(
                    -1px 1px 2px $text-shadow,
                    -1px -1px 2px $text-shadow,
                    1px -1px 2px $text-shadow,
                    1px 1px 2px $text-shadow
    );*/ }
  .button,
  button,
  [type="reset"],
  [type="submit"],
  [type="button"],
  [class*="action-item"], .commons-login, .bean-cta .field-name-field-link a, .button:link,
  button:link,
  [type="reset"]:link,
  [type="submit"]:link,
  [type="button"]:link,
  [class*="action-item"]:link, .commons-login:link, .bean-cta .field-name-field-link a:link, .button:visited,
  button:visited,
  [type="reset"]:visited,
  [type="submit"]:visited,
  [type="button"]:visited,
  [class*="action-item"]:visited, .commons-login:visited, .bean-cta .field-name-field-link a:visited {
    color: white; }
  .button:hover,
  button:hover,
  [type="reset"]:hover,
  [type="submit"]:hover,
  [type="button"]:hover,
  [class*="action-item"]:hover, .commons-login:hover, .bean-cta .field-name-field-link a:hover {
    color: #fefcfe;
    background-color: #7e176d;
    border-color: #130310;
    /*@include text-shadow(
                      -1px 1px 2px $hover-text-shadow,
                      -1px -1px 2px $hover-text-shadow,
                      1px -1px 2px $hover-text-shadow,
                      1px 1px 2px $hover-text-shadow
      );*/ }
  .button:focus,
  button:focus,
  [type="reset"]:focus,
  [type="submit"]:focus,
  [type="button"]:focus,
  [class*="action-item"]:focus, .commons-login:focus, .bean-cta .field-name-field-link a:focus, .button:active,
  button:active,
  [type="reset"]:active,
  [type="submit"]:active,
  [type="button"]:active,
  [class*="action-item"]:active, .commons-login:active, .bean-cta .field-name-field-link a:active {
    background: #941b80;
    border-color: #3e0b35;
    /*@include text-shadow(
                      -1px 1px 2px $text-shadow,
                      -1px -1px 2px $text-shadow,
                      1px -1px 2px $text-shadow,
                      1px 1px 2px $text-shadow
      );*/ }
  [disabled].button,
  button[disabled],
  [disabled][type="reset"],
  [disabled][type="submit"],
  [disabled][type="button"],
  [disabled][class*="action-item"], [disabled].commons-login, .bean-cta .field-name-field-link a[disabled] {
    color: #db3ac0;
    background: #941b80;
    border-color: #3e0b35;
    box-shadow: none;
    text-shadow: none; }

.action-item-active, .action-item-active[type="reset"], .action-item-active[type="submit"], .action-item-active[type="button"],
.action-item-small-active,
.action-item-small-active[type="reset"],
.action-item-small-active[type="submit"],
.action-item-small-active[type="button"],
.action-item-large-active,
.action-item-large-active[type="reset"],
.action-item-large-active[type="submit"],
.action-item-large-active[type="button"], [class*="flag-commons-follow-"] a.unflag-action {
  background-color: #941b80;
  border-color: #3e0b35;
  /*@include text-shadow(
                    -1px 1px 2px $text-shadow,
                    -1px -1px 2px $text-shadow,
                    1px -1px 2px $text-shadow,
                    1px 1px 2px $text-shadow
    );*/ }
  .action-item-active, .action-item-active[type="reset"], .action-item-active[type="submit"], .action-item-active[type="button"],
  .action-item-small-active,
  .action-item-small-active[type="reset"],
  .action-item-small-active[type="submit"],
  .action-item-small-active[type="button"],
  .action-item-large-active,
  .action-item-large-active[type="reset"],
  .action-item-large-active[type="submit"],
  .action-item-large-active[type="button"], [class*="flag-commons-follow-"] a.unflag-action, .action-item-active:link,
  .action-item-small-active:link,
  .action-item-large-active:link, [class*="flag-commons-follow-"] a.unflag-action:link, .action-item-active:visited,
  .action-item-small-active:visited,
  .action-item-large-active:visited, [class*="flag-commons-follow-"] a.unflag-action:visited {
    color: white; }
  .action-item-active:hover,
  .action-item-small-active:hover,
  .action-item-large-active:hover, [class*="flag-commons-follow-"] a.unflag-action:hover {
    color: #fefcfe;
    background-color: #7e176d;
    border-color: #130310;
    /*@include text-shadow(
                      -1px 1px 2px $hover-text-shadow,
                      -1px -1px 2px $hover-text-shadow,
                      1px -1px 2px $hover-text-shadow,
                      1px 1px 2px $hover-text-shadow
      );*/ }
  .action-item-active:focus,
  .action-item-small-active:focus,
  .action-item-large-active:focus, [class*="flag-commons-follow-"] a.unflag-action:focus, .action-item-active:active,
  .action-item-small-active:active,
  .action-item-large-active:active, [class*="flag-commons-follow-"] a.unflag-action:active {
    background: #941b80;
    border-color: #3e0b35;
    /*@include text-shadow(
                      -1px 1px 2px $text-shadow,
                      -1px -1px 2px $text-shadow,
                      1px -1px 2px $text-shadow,
                      1px 1px 2px $text-shadow
      );*/ }
  [disabled].action-item-active,
  [disabled].action-item-small-active,
  [disabled].action-item-large-active, [class*="flag-commons-follow-"] a[disabled].unflag-action {
    color: #db3ac0;
    background: #941b80;
    border-color: #3e0b35;
    box-shadow: none;
    text-shadow: none; }

.action-item-primary, .action-item-primary[type="reset"], .action-item-primary[type="submit"], .action-item-primary[type="button"],
.action-item-small-primary,
.action-item-small-primary[type="reset"],
.action-item-small-primary[type="submit"],
.action-item-small-primary[type="button"],
.action-item-large-primary,
.action-item-large-primary[type="reset"],
.action-item-large-primary[type="submit"],
.action-item-large-primary[type="button"], .commons-sign-up, .pane-commons-groups-commons-groups-create-group a, .pane-commons-events-commons-events-create-event-link a {
  background-color: #941b80;
  border-color: #3e0b35;
  /*@include text-shadow(
                    -1px 1px 2px $text-shadow,
                    -1px -1px 2px $text-shadow,
                    1px -1px 2px $text-shadow,
                    1px 1px 2px $text-shadow
    );*/ }
  .action-item-primary, .action-item-primary[type="reset"], .action-item-primary[type="submit"], .action-item-primary[type="button"],
  .action-item-small-primary,
  .action-item-small-primary[type="reset"],
  .action-item-small-primary[type="submit"],
  .action-item-small-primary[type="button"],
  .action-item-large-primary,
  .action-item-large-primary[type="reset"],
  .action-item-large-primary[type="submit"],
  .action-item-large-primary[type="button"], .commons-sign-up, .pane-commons-groups-commons-groups-create-group a, .pane-commons-events-commons-events-create-event-link a, .action-item-primary:link,
  .action-item-small-primary:link,
  .action-item-large-primary:link, .commons-sign-up:link, .pane-commons-groups-commons-groups-create-group a:link, .pane-commons-events-commons-events-create-event-link a:link, .action-item-primary:visited,
  .action-item-small-primary:visited,
  .action-item-large-primary:visited, .commons-sign-up:visited, .pane-commons-groups-commons-groups-create-group a:visited, .pane-commons-events-commons-events-create-event-link a:visited {
    color: white; }
  .action-item-primary:hover,
  .action-item-small-primary:hover,
  .action-item-large-primary:hover, .commons-sign-up:hover, .pane-commons-groups-commons-groups-create-group a:hover, .pane-commons-events-commons-events-create-event-link a:hover {
    color: #fefcfe;
    background-color: #7e176d;
    border-color: #130310;
    /*@include text-shadow(
                      -1px 1px 2px $hover-text-shadow,
                      -1px -1px 2px $hover-text-shadow,
                      1px -1px 2px $hover-text-shadow,
                      1px 1px 2px $hover-text-shadow
      );*/ }
  .action-item-primary:focus,
  .action-item-small-primary:focus,
  .action-item-large-primary:focus, .commons-sign-up:focus, .pane-commons-groups-commons-groups-create-group a:focus, .pane-commons-events-commons-events-create-event-link a:focus, .action-item-primary:active,
  .action-item-small-primary:active,
  .action-item-large-primary:active, .commons-sign-up:active, .pane-commons-groups-commons-groups-create-group a:active, .pane-commons-events-commons-events-create-event-link a:active {
    background: #941b80;
    border-color: #3e0b35;
    /*@include text-shadow(
                      -1px 1px 2px $text-shadow,
                      -1px -1px 2px $text-shadow,
                      1px -1px 2px $text-shadow,
                      1px 1px 2px $text-shadow
      );*/ }
  [disabled].action-item-primary,
  [disabled].action-item-small-primary,
  [disabled].action-item-large-primary, [disabled].commons-sign-up, .pane-commons-groups-commons-groups-create-group a[disabled], .pane-commons-events-commons-events-create-event-link a[disabled] {
    color: #db3ac0;
    background: #941b80;
    border-color: #3e0b35;
    box-shadow: none;
    text-shadow: none; }

.action-item-primary-active, .action-item-primary-active[type="reset"], .action-item-primary-active[type="submit"], .action-item-primary-active[type="button"],
.action-item-small-primary-active,
.action-item-small-primary-active[type="reset"],
.action-item-small-primary-active[type="submit"],
.action-item-small-primary-active[type="button"],
.action-item-large-primary-active,
.action-item-large-primary-active[type="reset"],
.action-item-large-primary-active[type="submit"],
.action-item-large-primary-active[type="button"] {
  background-color: #941b80;
  border-color: #3e0b35;
  /*@include text-shadow(
                    -1px 1px 2px $text-shadow,
                    -1px -1px 2px $text-shadow,
                    1px -1px 2px $text-shadow,
                    1px 1px 2px $text-shadow
    );*/ }
  .action-item-primary-active, .action-item-primary-active[type="reset"], .action-item-primary-active[type="submit"], .action-item-primary-active[type="button"],
  .action-item-small-primary-active,
  .action-item-small-primary-active[type="reset"],
  .action-item-small-primary-active[type="submit"],
  .action-item-small-primary-active[type="button"],
  .action-item-large-primary-active,
  .action-item-large-primary-active[type="reset"],
  .action-item-large-primary-active[type="submit"],
  .action-item-large-primary-active[type="button"], .action-item-primary-active:link,
  .action-item-small-primary-active:link,
  .action-item-large-primary-active:link, .action-item-primary-active:visited,
  .action-item-small-primary-active:visited,
  .action-item-large-primary-active:visited {
    color: white; }
  .action-item-primary-active:hover,
  .action-item-small-primary-active:hover,
  .action-item-large-primary-active:hover {
    color: #fefcfe;
    background-color: #7e176d;
    border-color: #130310;
    /*@include text-shadow(
                      -1px 1px 2px $hover-text-shadow,
                      -1px -1px 2px $hover-text-shadow,
                      1px -1px 2px $hover-text-shadow,
                      1px 1px 2px $hover-text-shadow
      );*/ }
  .action-item-primary-active:focus,
  .action-item-small-primary-active:focus,
  .action-item-large-primary-active:focus, .action-item-primary-active:active,
  .action-item-small-primary-active:active,
  .action-item-large-primary-active:active {
    background: #941b80;
    border-color: #3e0b35;
    /*@include text-shadow(
                      -1px 1px 2px $text-shadow,
                      -1px -1px 2px $text-shadow,
                      1px -1px 2px $text-shadow,
                      1px 1px 2px $text-shadow
      );*/ }
  [disabled].action-item-primary-active,
  [disabled].action-item-small-primary-active,
  [disabled].action-item-large-primary-active {
    color: #db3ac0;
    background: #941b80;
    border-color: #3e0b35;
    box-shadow: none;
    text-shadow: none; }

.button,
button,
[type="reset"],
[type="submit"],
[type="button"],
[class*="action-item"] {
  max-width: 100%; }
  @media only screen and (max-width: 480px) {
    .button:not(.action-item-inline):not(.action-item-merge),
    button:not(.action-item-inline):not(.action-item-merge),
    [type="reset"]:not(.action-item-inline):not(.action-item-merge),
    [type="submit"]:not(.action-item-inline):not(.action-item-merge),
    [type="button"]:not(.action-item-inline):not(.action-item-merge),
    [class*="action-item"]:not(.action-item-inline):not(.action-item-merge) {
      display: block;
      width: 100%;
      margin: .5em 0; } }
  #panels-ipe-control-container .button, #panels-ipe-control-container
  button, #panels-ipe-control-container
  [type="reset"], #panels-ipe-control-container
  [type="submit"], #panels-ipe-control-container
  [type="button"], #panels-ipe-control-container
  [class*="action-item"] {
    text-shadow: none; }

.action-item-merge + .action-item-merge {
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -.5em; }

.action-item-append,
.action-item-small-append,
.action-item-large-append {
  position: relative;
  padding-right: .5em;
  padding-left: .5em; }
  .action-item-append, .action-item-append:active, .action-item-append:focus, .action-item-append:hover,
  .action-item-small-append,
  .action-item-small-append:active,
  .action-item-small-append:focus,
  .action-item-small-append:hover,
  .action-item-large-append,
  .action-item-large-append:active,
  .action-item-large-append:focus,
  .action-item-large-append:hover {
    background: #941b80;
    border-color: #858585;
    color: #fff;
    cursor: default;
    text-shadow: none; }
  .action-item-append:before, .action-item-append:after,
  .action-item-small-append:before,
  .action-item-small-append:after,
  .action-item-large-append:before,
  .action-item-large-append:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    border-color: transparent;
    border-style: solid; }
  .action-item-append:before,
  .action-item-small-append:before,
  .action-item-large-append:before {
    left: -10px;
    margin-top: -5px;
    border-width: 5px;
    border-right-color: #858585; }
  .action-item-append:after,
  .action-item-small-append:after,
  .action-item-large-append:after {
    left: -8px;
    margin-top: -4px;
    border-width: 4px;
    border-right-color: #941b80; }

/* Reset some styles for the admin toolbar. */
.drupal-navbar .icon.handle {
  margin: 0;
  padding: 0; }
  .drupal-navbar .icon.handle:hover {
    background: none; }

/* Make the disabled buttons subdued. */
[disabled],
[disabled] option,
[disabled] optgroup {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  cursor: normal; }

/**
 * Specialty buttons
 */
/* Like widget */
.rate-widget-commons_like {
  white-space: nowrap; }

.rate-commons-like-btn {
  background: #fff; }
  .rate-commons-like-btn:before {
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    font-size: 1rem;
    line-height: 1;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    font-size: 1rem;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "";
    margin-top: -2px;
    margin-bottom: -2px;
    color: #941b80;
    text-shadow: none; }
  .rate-commons-like-btn:hover:before {
    color: #fff; }

/* Follow buttons */
[class*="flag-commons-follow-"] .flag-action {
  white-space: nowrap; }
  [class*="flag-commons-follow-"] .flag-action:before {
    content: "";
    background: sprite(sprite-map("icons/*.png", 20px), follow-large) no-repeat;
    width: image-width(sprite-file(sprite-map("icons/*.png", 20px), follow-large));
    height: image-height(sprite-file(sprite-map("icons/*.png", 20px), follow-large));
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: 1px; }

[class*="flag-commons-follow-"] .flag-throbber {
  display: none; }

[class*="flag-commons-follow-"] .flag-message {
  display: none !important; }

/* Report as inappropriate */
.flag-inappropriate-node,
.flag-inappropriate-comment {
  display: block;
  clear: both;
  margin: 1em 0 0; }
  .flag-inappropriate-node .flag-action:before,
  .flag-inappropriate-comment .flag-action:before {
    content: "";
    background: sprite(sprite-map("icons/*.png", 20px), spam) no-repeat;
    width: image-width(sprite-file(sprite-map("icons/*.png", 20px), spam));
    height: image-height(sprite-file(sprite-map("icons/*.png", 20px), spam));
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: 1px; }

/* ShareThis */
.sharethis-buttons {
  position: relative; }
  .sharethis-buttons:before {
    content: "";
    background: sprite(sprite-map("icons/*.png", 20px), share) no-repeat;
    width: image-width(sprite-file(sprite-map("icons/*.png", 20px), share));
    height: image-height(sprite-file(sprite-map("icons/*.png", 20px), share));
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: 1px; }

.sharethis-wrapper {
  position: absolute;
  left: 0;
  bottom: 80%;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #46ba31;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 10px;
  -webkit-transition: opacity 0.3s ease, bottom 0.3s ease, visibility 0.3s ease;
  -webkit-transition-delay: 0.4s, 0.4s, 0.4s;
  -moz-transition: opacity 0.3s ease 0.4s, bottom 0.3s ease 0.4s, visibility 0.3s ease 0.4s;
  -o-transition: opacity 0.3s ease 0.4s, bottom 0.3s ease 0.4s, visibility 0.3s ease 0.4s;
  transition: opacity 0.3s ease 0.4s, bottom 0.3s ease 0.4s, visibility 0.3s ease 0.4s;
  z-index: 9; }
  .sharethis-wrapper:before, .sharethis-wrapper:after {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    position: absolute;
    top: 100%;
    z-index: 10; }
  .sharethis-wrapper:before {
    border-width: 10px;
    border-top-color: #46ba31;
    left: 10px; }
  .sharethis-wrapper:after {
    border-width: 9px;
    border-top-color: #fff;
    left: 11px; }
  .sharethis-wrapper > span {
    display: inline-block;
    margin-top: -5px; }
  .sharethis:hover .sharethis-wrapper,
  .sharethis-buttons:active .sharethis-wrapper {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    visibility: visible;
    bottom: 100%; }
  .sharethis-buttons:active .sharethis-wrapper {
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s; }

/* Comments */
.comment-comments {
  white-space: nowrap; }
  .comment-comments .action-item-small {
    /*padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;*/ }
  .comment-comments .action-item-small-append {
    /*margin: -1px -1px -1px 1em;
    font-size: 1em;
    // min-height: 1.8em;
    @include border-top-left-radius(0);
    @include border-bottom-left-radius(0);*/ }

/* Drupal specific field styling. */
.field-label-inline {
  margin: 1.7em 0; }
  .field-label-inline .field-label,
  .field-label-inline .field-items,
  .field-label-inline .field-item {
    display: inline;
    float: none; }
  .field-label-inline .field-item {
    margin: 0 .1em; }
    .field-label-inline .field-item:first-child {
      margin-left: 0; }
    .field-label-inline .field-item:last-child {
      margin-right: 0; }
    .field-label-inline .field-item:after {
      content: ", "; }
    .field-label-inline .field-item:last-child:after {
      content: ""; }
  .field-label-inline .field-label {
    font-weight: bold;
    color: #333333; }

/**
 * User avatar
 */
/**
 * Filter drawer accessories
 */
.filter-trigger {
  display: none;
  background: white;
  box-sizing: border-box;
  padding: 5px;
  position: absolute;
  top: 0;
  left: -39px;
  width: 40px;
  border: 1px solid #941b80;
  border-right: none;
  border-radius: 3px 0 0 3px;
  text-align: center;
  font: 0/0 a;
  line-height: 0;
  color: transparent;
  box-shadow: rgba(51, 51, 51, 0.4) -1px 1px 2px;
  cursor: pointer;
  z-index: 1; }
  .filter-trigger:after {
    content: "\00ab";
    display: block;
    font-size: 38.4px;
    line-height: .8em;
    padding-bottom: .15em;
    color: #009eb2; }
    .expanded .filter-trigger:after {
      content: "\00bb"; }
  .filter-trigger.following {
    position: fixed;
    right: 0;
    left: auto; }

.filters-processed.expanded .region-inner {
  position: relative;
  border: 1px solid #941b80;
  border-right: none;
  z-index: 0;
  box-shadow: rgba(51, 51, 51, 0.4) -1px 1px 2px; }

.filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.8); }

/**
 * Aggregator feed source
 */
#aggregator .feed-source .feed-icon {
  display: inline;
  float: none;
  margin-right: 10px; }

.feed-details dt,
.feed-details dd {
  display: inline;
  margin: 0; }

/**
 * Generic styles for links. See the ul.links declaration in node and comment stylesheets
 */
ul.links {
  margin: 0;
  padding: 0; }
  ul.links.inline {
    display: block; }
  ul.links li {
    display: inline-block;
    list-style: none; }

.quicktabs-style-commons-pills ul.quicktabs-tabs,
ul.primary.tabs {
  border-bottom: none;
  padding: 0; }
  .quicktabs-style-commons-pills ul.quicktabs-tabs li,
  ul.primary.tabs li {
    margin-bottom: 5px;
    margin-top: 5px; }
    .quicktabs-style-commons-pills ul.quicktabs-tabs li a,
    ul.primary.tabs li a {
      background-color: rgba(153, 153, 153, 0.3);
      border-radius: 3px;
      padding: .3em .8em;
      display: inline-block;
      border: none; }
      .quicktabs-style-commons-pills ul.quicktabs-tabs li a,
      .quicktabs-style-commons-pills ul.quicktabs-tabs li a span,
      ul.primary.tabs li a,
      ul.primary.tabs li a span {
        color: #009eb2; }
      .quicktabs-style-commons-pills ul.quicktabs-tabs li a:hover,
      ul.primary.tabs li a:hover {
        text-decoration: none;
        background-color: rgba(153, 153, 153, 0.3);
        color: #941b80; }
    .quicktabs-style-commons-pills ul.quicktabs-tabs li.active a,
    ul.primary.tabs li.active a {
      position: relative;
      background-color: #999999;
      border: none; }
      .quicktabs-style-commons-pills ul.quicktabs-tabs li.active a, .quicktabs-style-commons-pills ul.quicktabs-tabs li.active a:hover,
      ul.primary.tabs li.active a,
      ul.primary.tabs li.active a:hover {
        background-color: #999999; }
      .quicktabs-style-commons-pills ul.quicktabs-tabs li.active a,
      .quicktabs-style-commons-pills ul.quicktabs-tabs li.active a span,
      ul.primary.tabs li.active a,
      ul.primary.tabs li.active a span {
        color: #333333; }
      .quicktabs-style-commons-pills ul.quicktabs-tabs li.active a:after,
      ul.primary.tabs li.active a:after {
        position: absolute;
        bottom: -.7em;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
        margin-left: -.6em;
        content: "";
        border-width: .4em .6em;
        border-style: solid;
        border-color: #999999 transparent transparent; }

.quicktabs-style-commons-pills ul.quicktabs-tabs li {
  text-transform: uppercase;
  font-size: .8em; }

/**
 * Main wrapper for most blocks, block_system_main does not have it
 */
.block {
  margin-bottom: 20px; }

.block-title {
  font-size: 1.5em;
  line-height: 1.2em;
  margin-top: 0;
  margin-bottom: .4em;
  color: #000;
  font-family: "Nassim", sans-serif; }

/**
 * Match item list and block menu margin and padding
 */
.block-content ul,
.block-content ol {
  padding: 0 0 0 15px; }

.block-content li {
  margin: 0;
  padding: 0; }

/* Views exposed form */
.views-exposed-form .views-exposed-widget {
  float: none;
  padding: 0;
  margin: 1.7em 0; }
  .views-exposed-form .views-exposed-widget:first-child {
    margin-top: 0; }
  .views-exposed-form .views-exposed-widget:last-child {
    margin-bottom: 0; }
  .views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 0; }

.keyword-filter .views-widget-filter-keys,
.keyword-filter .views-submit-button {
  float: left;
  box-sizing: border-box;
  margin-top: 0; }

.keyword-filter .views-widget-filter-keys {
  margin-right: -6.5em;
  margin-bottom: 0;
  padding-right: 6.75em;
  width: 100%; }
  .keyword-filter .views-widget-filter-keys label {
    margin-top: 0; }
  @media only screen and (max-width: 768px) {
    .keyword-filter .views-widget-filter-keys {
      margin-right: 0;
      padding-right: 0; } }

.keyword-filter .views-submit-button {
  padding-top: 2.15em; }
  @media only screen and (max-width: 768px) {
    .keyword-filter .views-submit-button {
      margin-top: .5em;
      padding-top: 0; } }

/* Generic row styles */
.views-row {
  position: relative; }
  .views-row .views-row {
    padding-bottom: 0; }

/* Apply icons to row row types. */
[class*="row-type-"] {
  box-sizing: border-box;
  padding-left: 20px; }
  [class*="row-type-"]:before {
    content: "";
    float: left;
    margin-top: .1em;
    margin-left: -20px; }

.node-content-type:before {
  content: ""; }

.row-type-event:before,
.node-content-type-event:before {
  background: sprite(sprite-map("icons/*.png", 20px), events) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), events));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), events));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

.row-type-page:before,
.node-content-type-page:before,
.row-type-document:before,
.node-content-type-document:before {
  background: sprite(sprite-map("icons/*.png", 20px), icon-gray-docs) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), icon-gray-docs));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), icon-gray-docs));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

.row-type-poll:before,
.node-content-type-poll:before {
  background: sprite(sprite-map("icons/*.png", 20px), polls) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), polls));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), polls));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

.row-type-post:before,
.node-content-type-post:before {
  background: sprite(sprite-map("icons/*.png", 20px), posts) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), posts));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), posts));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

.row-type-question:before,
.row-type-answer:before,
.node-content-type-question:before,
.node-content-type-answer:before {
  background: sprite(sprite-map("icons/*.png", 20px), qa) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), qa));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), qa));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

.row-type-wiki:before,
.node-content-type-wiki:before {
  background: sprite(sprite-map("icons/*.png", 20px), wikis) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), wikis));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), wikis));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

/* Titles fields */
.views-field-title {
  font-weight: 500;
  line-height: 1.4em; }

/* Meta data */
.views-field-field-date,
.views-field-timestamp,
.views-field-field-location,
.views-field-capacity-used,
.views-field-comment-count {
  font-size: 0.88em; }

.views-field-timestamp,
.views-field-field-location,
.views-field-capacity-used,
.views-field-comment-count {
  color: #858585; }
  .views-field-timestamp strong,
  .views-field-field-location strong,
  .views-field-capacity-used strong,
  .views-field-comment-count strong {
    color: #333333; }

.views-field-timestamp,
.views-field-field-location,
.views-field-capacity-used,
.views-field-comment-count {
  display: inline-block;
  margin-right: .3em; }

/* User picture wrapping and shadow */
.view-commons-homepage-content .content,
.view-commons-homepage-content .message,
.view-commons-homepage-content .views-field-timestamp,
.view-commons-homepage-content .views-field-nothing,
.view-commons-activity-streams-activity .content,
.view-commons-activity-streams-activity .message,
.view-commons-activity-streams-activity .views-field-timestamp,
.view-commons-activity-streams-activity .views-field-nothing,
.view-activity-group .content,
.view-activity-group .message,
.view-activity-group .views-field-timestamp,
.view-activity-group .views-field-nothing,
.view-commons-activity-streams-user-activity .content,
.view-commons-activity-streams-user-activity .message,
.view-commons-activity-streams-user-activity .views-field-timestamp,
.view-commons-activity-streams-user-activity .views-field-nothing {
  padding-left: 58px; }
  .view-commons-homepage-content .content .user-picture,
  .view-commons-homepage-content .message .user-picture,
  .view-commons-homepage-content .views-field-timestamp .user-picture,
  .view-commons-homepage-content .views-field-nothing .user-picture,
  .view-commons-activity-streams-activity .content .user-picture,
  .view-commons-activity-streams-activity .message .user-picture,
  .view-commons-activity-streams-activity .views-field-timestamp .user-picture,
  .view-commons-activity-streams-activity .views-field-nothing .user-picture,
  .view-activity-group .content .user-picture,
  .view-activity-group .message .user-picture,
  .view-activity-group .views-field-timestamp .user-picture,
  .view-activity-group .views-field-nothing .user-picture,
  .view-commons-activity-streams-user-activity .content .user-picture,
  .view-commons-activity-streams-user-activity .message .user-picture,
  .view-commons-activity-streams-user-activity .views-field-timestamp .user-picture,
  .view-commons-activity-streams-user-activity .views-field-nothing .user-picture {
    position: absolute;
    float: left;
    width: 40px;
    margin-left: -58px;
    top: 0; }
    .view-commons-homepage-content .content .user-picture img,
    .view-commons-homepage-content .message .user-picture img,
    .view-commons-homepage-content .views-field-timestamp .user-picture img,
    .view-commons-homepage-content .views-field-nothing .user-picture img,
    .view-commons-activity-streams-activity .content .user-picture img,
    .view-commons-activity-streams-activity .message .user-picture img,
    .view-commons-activity-streams-activity .views-field-timestamp .user-picture img,
    .view-commons-activity-streams-activity .views-field-nothing .user-picture img,
    .view-activity-group .content .user-picture img,
    .view-activity-group .message .user-picture img,
    .view-activity-group .views-field-timestamp .user-picture img,
    .view-activity-group .views-field-nothing .user-picture img,
    .view-commons-activity-streams-user-activity .content .user-picture img,
    .view-commons-activity-streams-user-activity .message .user-picture img,
    .view-commons-activity-streams-user-activity .views-field-timestamp .user-picture img,
    .view-commons-activity-streams-user-activity .views-field-nothing .user-picture img {
      width: 40px; }
  @media only screen and (max-width: 480px) {
    .view-commons-homepage-content .content,
    .view-commons-homepage-content .message,
    .view-commons-homepage-content .views-field-timestamp,
    .view-commons-homepage-content .views-field-nothing,
    .view-commons-activity-streams-activity .content,
    .view-commons-activity-streams-activity .message,
    .view-commons-activity-streams-activity .views-field-timestamp,
    .view-commons-activity-streams-activity .views-field-nothing,
    .view-activity-group .content,
    .view-activity-group .message,
    .view-activity-group .views-field-timestamp,
    .view-activity-group .views-field-nothing,
    .view-commons-activity-streams-user-activity .content,
    .view-commons-activity-streams-user-activity .message,
    .view-commons-activity-streams-user-activity .views-field-timestamp,
    .view-commons-activity-streams-user-activity .views-field-nothing {
      padding-left: 0; }
      .view-commons-homepage-content .content .user-picture,
      .view-commons-homepage-content .message .user-picture,
      .view-commons-homepage-content .views-field-timestamp .user-picture,
      .view-commons-homepage-content .views-field-nothing .user-picture,
      .view-commons-activity-streams-activity .content .user-picture,
      .view-commons-activity-streams-activity .message .user-picture,
      .view-commons-activity-streams-activity .views-field-timestamp .user-picture,
      .view-commons-activity-streams-activity .views-field-nothing .user-picture,
      .view-activity-group .content .user-picture,
      .view-activity-group .message .user-picture,
      .view-activity-group .views-field-timestamp .user-picture,
      .view-activity-group .views-field-nothing .user-picture,
      .view-commons-activity-streams-user-activity .content .user-picture,
      .view-commons-activity-streams-user-activity .message .user-picture,
      .view-commons-activity-streams-user-activity .views-field-timestamp .user-picture,
      .view-commons-activity-streams-user-activity .views-field-nothing .user-picture {
        display: none; } }

.view-activity-group .views-row h2,
.view-commons-activity-streams-activity .views-row h2,
.view-commons-activity-streams-user-activity .views-row h2 {
  display: none; }

.view-activity-group .views-row .user-picture,
.view-commons-activity-streams-activity .views-row .user-picture,
.view-commons-activity-streams-user-activity .views-row .user-picture {
  top: 5px; }
  .view-activity-group .views-row .user-picture img,
  .view-commons-activity-streams-activity .views-row .user-picture img,
  .view-commons-activity-streams-user-activity .views-row .user-picture img {
    width: 40px; }

.page-node .field-label {
  color: #369; }

.page-node .field-name-field-topics,
.page-node .field-name-og-group-ref {
  margin: 0; }
  .page-node .field-name-field-topics .field-label,
  .page-node .field-name-og-group-ref .field-label {
    display: block; }
  .page-node .field-name-field-topics.view-mode-full,
  .page-node .field-name-og-group-ref.view-mode-full {
    float: left;
    width: 50%; }
    .page-node .field-name-field-topics.view-mode-full .field-items,
    .page-node .field-name-og-group-ref.view-mode-full .field-items {
      margin: 0;
      list-style: none;
      padding: 0; }
      .page-node .field-name-field-topics.view-mode-full .field-items .field-item,
      .page-node .field-name-og-group-ref.view-mode-full .field-items .field-item {
        display: inline-block;
        margin: 0 .3em;
        padding: 0; }
        .page-node .field-name-field-topics.view-mode-full .field-items .field-item:first-child,
        .page-node .field-name-og-group-ref.view-mode-full .field-items .field-item:first-child {
          margin-left: 0; }
        .page-node .field-name-field-topics.view-mode-full .field-items .field-item:last-child,
        .page-node .field-name-og-group-ref.view-mode-full .field-items .field-item:last-child {
          margin-right: 0; }
        .page-node .field-name-field-topics.view-mode-full .field-items .field-item:after,
        .page-node .field-name-og-group-ref.view-mode-full .field-items .field-item:after {
          content: ""; }
    @media only screen and (max-width: 480px) {
      .page-node .field-name-field-topics.view-mode-full,
      .page-node .field-name-og-group-ref.view-mode-full {
        float: none;
        width: auto;
        margin: 1.7em 0; } }

.page-node .field-name-field-topics .field-item:before {
  content: "";
  background: sprite(sprite-map("icons/*.png", 20px), tags) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), tags));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), tags));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px;
  top: -1px; }

.page-node .field-name-og-group-ref .field-item:before {
  content: "";
  background: sprite(sprite-map("icons/*.png", 20px), groups) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), groups));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), groups));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px;
  top: -1px; }

.page-node .node nav {
  clear: both;
  margin-top: 1em;
  margin-bottom: 2em;
  position: relative; }
  .page-node .node nav .links {
    margin-top: 0;
    padding-left: 0; }
    .page-node .node nav .links .en {
      display: none; }

#comments {
  margin: 0; }
  #comments .form-item-subject input {
    width: 100%; }
  #comments .rate-widget-commons_like {
    position: absolute;
    right: 5px;
    top: 15px; }

.comments-title,
.comment-form-title {
  margin-top: 0;
  font-size: 2.2em;
  line-height: 1.2em;
  color: #333333; }

.comments-title:before {
  content: "";
  background: sprite(sprite-map("icons/*.png", 20px), comments-large) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), comments-large));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), comments-large));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

.comment-form {
  margin-bottom: 0; }
  .comment-form:before {
    display: none; }

.comment {
  padding-left: 68px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  position: relative; }
  .comment .user-picture {
    position: absolute;
    float: left;
    width: 50px;
    margin-left: -68px;
    top: 0; }
    .comment .user-picture img {
      width: 50px; }
  @media only screen and (max-width: 480px) {
    .comment {
      padding-left: 0; }
      .comment .user-picture {
        display: none; } }
  .comment .author-datetime {
    margin-bottom: 1.4em; }

.comment-title,
.comment .author-datetime {
  margin-right: 90px; }

.comment-title {
  margin-bottom: .2em; }

ul.comment-links {
  margin: .7em 0; }
  ul.comment-links li {
    padding-left: 0; }

.comment-reply {
  float: right; }
  .comment-reply:before {
    content: "";
    background: sprite(sprite-map("icons/*.png", 20px), comments) no-repeat;
    width: image-width(sprite-file(sprite-map("icons/*.png", 20px), comments));
    height: image-height(sprite-file(sprite-map("icons/*.png", 20px), comments));
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: 1px;
    margin-right: 8px;
    top: 3px; }

.comment-form .form-actions {
  margin-bottom: 0; }

.primary-fields,
.supplementary-fields {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  box-sizing: border-box;
  float: left; }
  .view-commons-question-answers .primary-fields, .view-commons-question-answers
  .supplementary-fields {
    display: block;
    float: none;
    width: auto;
    padding: 0; }

.primary-fields {
  padding-right: 10px;
  width: 66.666%; }

.supplementary-fields {
  padding-left: 10px;
  width: 33.333%; }

.form-actions {
  clear: both; }

/* Format the date range form items. */
.start-date-wrapper,
.end-date-wrapper {
  clear: none;
  width: auto;
  max-width: 49%; }
  .start-date-wrapper,
  .start-date-wrapper input,
  .start-date-wrapper .form-item .form-type-textfield,
  .start-date-wrapper .date-padding,
  .start-date-wrapper .form-type-textfield,
  .end-date-wrapper,
  .end-date-wrapper input,
  .end-date-wrapper .form-item .form-type-textfield,
  .end-date-wrapper .date-padding,
  .end-date-wrapper .form-type-textfield {
    float: none;
    display: inline-block;
    min-width: inherit; }
  .start-date-wrapper .form-item input,
  .end-date-wrapper .form-item input {
    margin-right: auto; }
  .start-date-wrapper .form-type-textfield,
  .end-date-wrapper .form-type-textfield {
    max-width: 60%; }
    .start-date-wrapper .form-type-textfield:last-child,
    .end-date-wrapper .form-type-textfield:last-child {
      max-width: 30%; }
  .start-date-wrapper .form-type-date-popup,
  .end-date-wrapper .form-type-date-popup {
    margin: 0; }
  @media only screen and (max-width: 768px) {
    .start-date-wrapper,
    .end-date-wrapper {
      display: block;
      max-width: inherit;
      margin: 10px auto; } }

.addressfield-container-inline > div.form-item {
  display: inline-block;
  float: none; }

.addressfield-container-inline input[type="text"] {
  width: auto; }

.addressfield-container-inline:after {
  content: "";
  clear: none;
  display: none; }

.logo-available {
  /*.field-name-field-date,
  .field-name-field-address,
  .field-name-field-offsite-url {
    margin-left: 65px;

    @include respond(only-small) {
      margin-left: 0;
    }
  }*/ }

.field-name-field-logo {
  max-width: 100%;
  position: relative; }

.field-name-field-date {
  font-weight: bold; }

.adr .region,
.node-type-event .adr .region,
.panel-display .region .adr .region {
  float: none;
  padding: 0; }

ul.node-action-links {
  display: inline-block;
  margin-top: 1.4em; }
  ul.node-action-links li {
    padding: 0;
    vertical-align: baseline; }
  ul.node-action-links li,
  ul.node-action-links a {
    margin: 0; }

.view-commons-contributors-group .views-field-picture {
  margin: 0 0 10px;
  position: static;
  line-height: 0; }
  .view-commons-contributors-group .views-field-picture img {
    width: 40px; }

.view-commons-contributors-group td {
  padding: 0; }

.view-commons-contributors-group tbody tr {
  background-color: transparent; }

/**
 * Author/Submit line
 */
.author-datetime {
  color: #858585;
  font-size: 0.88em; }

.node-content-type {
  color: #333333; }

/**
 * Featured/New Tags
 */
.tag.featured {
  width: 58px;
  height: 22px;
  display: inline-block;
  background-image: url("../img/tag-featured.png");
  background-repeat: no-repeat;
  margin: 6px 0 0 5px; }

.tag.new {
  width: 27px;
  height: 22px;
  display: inline-block;
  background-image: url("../img/tag-new.png");
  background-repeat: no-repeat;
  margin: 4px 0 0 5px; }

/**
 * Group nodes
 */
/* =============================================================================
 *   Regions
 * ========================================================================== */
/* =============================================================================
 *   Links
 * ========================================================================== */
a {
  text-decoration: none;
  color: #009eb2; }
  a:hover, a:focus {
    text-decoration: underline; }

/* =============================================================================
 *   Breadcrumbs
 * ========================================================================== */
#breadcrumb {
  margin: 10px 0;
  /* If the label is set to show in theme settings the label class is added */ }
  #breadcrumb .breadcrumb-label {
    font-size: 1em;
    display: inline;
    padding-right: 10px; }
    #breadcrumb .breadcrumb-label:after {
      content: ":"; }
  #breadcrumb ol {
    margin: 0;
    padding: 0; }
  #breadcrumb .with-breadcrumb-label ol {
    display: inline; }
  #breadcrumb li {
    list-style: none;
    display: inline; }

/* =============================================================================
 *   Pagers
 * ========================================================================== */
ul.pager {
  clear: both;
  margin: 0;
  text-align: center; }

.item-list ul.pager li {
  margin: 0; }

ul.pager li {
  background-image: none;
  display: inline;
  list-style-type: none;
  padding: .5em; }
  ul.pager li.pager-current {
    font-weight: 700; }

.block ul.pager li {
  margin: 0; }

/* =============================================================================
 *   Skip Navigation
 * ========================================================================== */
#skip-link {
  left: 50%;
  margin-left: -6.5em;
  margin-top: 0;
  padding: 0 0.5em;
  position: absolute;
  width: 12em;
  z-index: 50; }
  #skip-link a {
    background: #444;
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    display: block;
    line-height: 2;
    padding: 0;
    text-align: center;
    text-decoration: none; }
    #skip-link a:link, #skip-link a:visited {
      background: #444;
      background: rgba(0, 0, 0, 0.6);
      color: #ffffff;
      display: block;
      line-height: 2;
      padding: 0;
      text-align: center;
      text-decoration: none; }
    #skip-link a:hover, #skip-link a:focus, #skip-link a:active {
      outline: 0; }

/* =============================================================================
 *   Tabs (local tasks)
 * ========================================================================== */
#tasks {
  margin-bottom: 15px; }

ul.primary {
  border-bottom-color: #941b80;
  margin: 20px 0;
  padding: 0 0 0 5px; }
  ul.primary li {
    display: block;
    float: left;
    margin: 0 1px -1px; }
    ul.primary li a {
      background-color: #f5f5f5;
      border-color: #941b80;
      margin-right: 1px;
      padding: 0 10px;
      display: block;
      float: left;
      height: 1.5em;
      line-height: 1.5em; }
      ul.primary li a:hover, ul.primary li a:focus {
        background-color: #eee;
        border-color: #941b80; }
  ul.primary li.active a,
  ul.primary li.active a:hover,
  ul.primary li.active a:focus {
    background-color: #ffffff;
    border-bottom-color: #ffffff; }

ul.secondary {
  border-bottom: 1px solid #941b80;
  margin: 1em 0 0;
  padding: 0 .3em 1em; }
  ul.secondary li {
    border-right: 0;
    list-style: none;
    padding: 0 10px 0 0; }
    ul.secondary li a:hover, ul.secondary li a.active {
      border-bottom: none;
      text-decoration: underline; }

/* =============================================================================
 *   Action links
 * ========================================================================== */
ul.action-links {
  margin: 20px 0 0;
  list-style: none; }

/* =============================================================================
 *  Field Styling
 * ========================================================================== */
/**
 * Labels are h2 in Adaptivetheme. Use a strong selector to mitigate unwanted ineritance issues
 */
.field-label {
  font-size: 1em;
  font-weight: 700;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0; }

/**
 * Taxonomy
 */
.field-type-taxonomy-term-reference {
  /* The same bottom margin as p, blockquote, ul, ol and dl */
  margin-bottom: 1.5em; }
  .field-type-taxonomy-term-reference.field-label-inline .field-items {
    margin: 0;
    padding: 0; }
  .field-type-taxonomy-term-reference.field-label-inline .field-item {
    display: inline;
    list-style: none;
    padding: 0 10px 0 0; }

/**
 * Float left setting
 */
.ia-l .field-type-image figure,
.iat-l .field-type-image figure {
  margin: 5px 20px 15px 0; }

/**
 * Centered setting
 */
.ia-c .field-type-image figure,
.iat-c .field-type-image figure {
  margin: 5px auto 15px; }

/**
 * Float right setting
 */
.ia-r .field-type-image figure,
.iat-r .field-type-image figure {
  margin: 5px 0 15px 20px; }

/* =============================================================================
 *   Panels Styling
 * ========================================================================== */
.node-title,
.pane-title {
  font-size: 1.6em;
  font-weight: bold; }

.panel-display {
  min-width: 100%;
  /* margin-right: -$gutter-width;
   margin-left: -$gutter-width;*/ }
  .panel-display .region {
    box-sizing: border-box;
    padding-right: 10px;
    padding-left: 10px; }

.nodes {
  margin-top: 1em; }

.pane-commons-featured-panel-pane-1 .block-inner,
.pane-commons-events-upcoming .block-inner {
  margin: 0; }
  .pane-commons-featured-panel-pane-1 .block-inner .views-row-last,
  .pane-commons-events-upcoming .block-inner .views-row-last {
    border-bottom: none; }

/* =============================================================================
 *   Views Styling
 * ========================================================================== */
.views-view-grid {
  border: none;
  margin: 0; }
  .views-view-grid tbody tr {
    border-top: none;
    background: none; }
  .views-view-grid td {
    padding: 5px; }

.grid-grouping-title {
  font-size: 1em;
  color: #858585;
  margin-bottom: 10px; }

.pager a {
  margin: 0;
  padding-right: .75em;
  padding-left: .75em; }

.pane-commons-contributors-group-panel-pane-1 table, .pane-commons-contributors-group-panel-pane-1 tbody {
  margin: 0;
  border: none; }

.pane-commons-contributors-group-panel-pane-1 tbody tr {
  background-color: transparent;
  border: none; }

.pane-commons-contributors-group-panel-pane-1 td {
  padding: 0;
  margin: 0 1px 1px 0;
  float: left;
  line-height: 0; }
  .pane-commons-contributors-group-panel-pane-1 td a {
    position: relative;
    display: block; }
    .pane-commons-contributors-group-panel-pane-1 td a img {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
      opacity: 0.5; }
    .pane-commons-contributors-group-panel-pane-1 td a span {
      display: none;
      position: absolute;
      top: -100%;
      background-color: rgba(0, 0, 0, 0.85);
      white-space: nowrap;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      padding: 5px 8px;
      color: #fff;
      border-radius: 4px;
      z-index: 15; }
      .pane-commons-contributors-group-panel-pane-1 td a span:after {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;
        position: absolute;
        left: 10px;
        top: 100%; }
    .pane-commons-contributors-group-panel-pane-1 td a:hover img {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
    .pane-commons-contributors-group-panel-pane-1 td a:hover span {
      display: block; }

.region-two-66-33-second .view-commons-contributors-group table,
.region-two-66-33-second .view-commons-contributors-group tbody {
  margin: 0;
  border: none; }

.region-two-66-33-second .view-commons-contributors-group tbody tr {
  background-color: transparent;
  border: none; }

.region-two-66-33-second .view-commons-contributors-group td {
  padding: 0;
  line-height: 0; }
  .region-two-66-33-second .view-commons-contributors-group td.col-last a {
    margin-right: 0; }
  .region-two-66-33-second .view-commons-contributors-group td .user-picture {
    margin-bottom: 10px; }
    .region-two-66-33-second .view-commons-contributors-group td .user-picture img {
      width: 40px; }

#views-exposed-form-commons-bw-all-page-1 .views-submit-button {
  margin-left: 20px; }

#views-exposed-form-commons-bw-all-page-1 .views-exposed-widget {
  padding: 0; }
  #views-exposed-form-commons-bw-all-page-1 .views-exposed-widget .form-submit {
    margin-top: 0; }

.widget-changed .views-submit-button {
  position: static;
  visibility: visible; }

.commons-bw-create-choose {
  cursor: pointer; }
  .commons-bw-create-choose span {
    background: sprite(sprite-map("icons/*.png", 20px), ico_arrow_wht) no-repeat;
    width: image-width(sprite-file(sprite-map("icons/*.png", 20px), ico_arrow_wht));
    height: image-height(sprite-file(sprite-map("icons/*.png", 20px), ico_arrow_wht));
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: 1px;
    margin-right: 0; }

.commons-bw-create-choose-bg {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10; }
  .create-choose-open .commons-bw-create-choose-bg {
    display: block; }

.commons-bw-create-choose-holder {
  position: relative; }

.commons-bw-create-all-widget-types {
  position: absolute;
  display: none;
  left: -50px;
  top: -50px;
  z-index: 11;
  width: 210px;
  background: #fff;
  border: 1px solid #46ba31;
  border-radius: 6px;
  list-style: none; }
  .block-content .commons-bw-create-all-widget-types,
  .item-list .commons-bw-create-all-widget-types {
    margin: 0;
    padding: 0; }
  .commons-bw-create-all-widget-types li {
    border-bottom: 1px solid #b8b8b8;
    position: relative;
    font-size: 0.85em;
    color: #858585; }
    .commons-bw-create-all-widget-types li .last {
      border-bottom: none; }
    .item-list .commons-bw-create-all-widget-types li {
      margin: 0;
      padding: 10px 10px 10px 27px; }
    .commons-bw-create-all-widget-types li .commons-polls-create:before {
      background: sprite(sprite-map("icons/*.png", 20px), polls) no-repeat;
      width: image-width(sprite-file(sprite-map("icons/*.png", 20px), polls));
      height: image-height(sprite-file(sprite-map("icons/*.png", 20px), polls));
      display: inline-block;
      margin-right: 3px;
      position: relative;
      top: 1px;
      content: "";
      position: absolute;
      left: 10px;
      top: 13px; }
    .commons-bw-create-all-widget-types li .commons-posts-create:before {
      background: sprite(sprite-map("icons/*.png", 20px), posts) no-repeat;
      width: image-width(sprite-file(sprite-map("icons/*.png", 20px), posts));
      height: image-height(sprite-file(sprite-map("icons/*.png", 20px), posts));
      display: inline-block;
      margin-right: 3px;
      position: relative;
      top: 1px;
      content: "";
      position: absolute;
      left: 10px;
      top: 13px; }
    .commons-bw-create-all-widget-types li .commons-wikis-create:before {
      background: sprite(sprite-map("icons/*.png", 20px), wikis) no-repeat;
      width: image-width(sprite-file(sprite-map("icons/*.png", 20px), wikis));
      height: image-height(sprite-file(sprite-map("icons/*.png", 20px), wikis));
      display: inline-block;
      margin-right: 3px;
      position: relative;
      top: 1px;
      content: "";
      position: absolute;
      left: 10px;
      top: 13px; }
    .commons-bw-create-all-widget-types li .commons-events-create:before {
      background: sprite(sprite-map("icons/*.png", 20px), events) no-repeat;
      width: image-width(sprite-file(sprite-map("icons/*.png", 20px), events));
      height: image-height(sprite-file(sprite-map("icons/*.png", 20px), events));
      display: inline-block;
      margin-right: 3px;
      position: relative;
      top: 1px;
      content: "";
      position: absolute;
      left: 10px;
      top: 13px; }
    .commons-bw-create-all-widget-types li .commons-q-a-create:before {
      background: sprite(sprite-map("icons/*.png", 20px), qa) no-repeat;
      width: image-width(sprite-file(sprite-map("icons/*.png", 20px), qa));
      height: image-height(sprite-file(sprite-map("icons/*.png", 20px), qa));
      display: inline-block;
      margin-right: 3px;
      position: relative;
      top: 1px;
      content: "";
      position: absolute;
      left: 10px;
      top: 13px; }
    .commons-bw-create-all-widget-types li a {
      display: block;
      color: #46ba31;
      font-size: 1.3em; }
  .create-choose-open .commons-bw-create-all-widget-types {
    display: block; }

.quicktabs-style-commons-pills ul.quicktabs-tabs {
  border-top: 2px dashed #941b80;
  margin-bottom: 0;
  text-align: center; }
  .quicktabs-style-commons-pills ul.quicktabs-tabs li a:before {
    display: none !important; }
  .quicktabs-style-commons-pills ul.quicktabs-tabs li a .commons-bw-result-count {
    font-size: 1em;
    font-weight: bold; }
    .quicktabs-style-commons-pills ul.quicktabs-tabs li a .commons-bw-result-count:before {
      content: '';
      margin: 0 2px; }

.commons-bw-partial-node-form {
  margin-bottom: 1.7em;
  padding-left: 68px; }
  .commons-bw-partial-node-form.compact-form .form-item {
    margin: 0; }
  .commons-bw-partial-node-form.compact-form textarea {
    resize: none; }
  .commons-bw-partial-node-form .user-picture {
    margin-top: 2.6em; }
  @media only screen and (max-width: 480px) {
    .commons-bw-partial-node-form {
      padding-left: 0; }
      .commons-bw-partial-node-form .user-picture {
        display: none; } }
  .commons-bw-partial-node-form .field-type-image {
    margin-top: 1.7em; }
  .commons-bw-partial-node-form .field-name-og-group-ref .fieldset-outer-wrapper {
    margin-top: 1.7em;
    margin-bottom: 1.7em;
    padding-top: 1.7em;
    border-top: 1px solid #941b80; }

.partial-node-form-title {
  margin-top: 0;
  margin-bottom: .2em;
  margin-left: -68px;
  color: #941b80; }
  @media only screen and (max-width: 480px) {
    .partial-node-form-title {
      margin-left: 0; } }

.trigger-field,
.trigger-field .form-item {
  margin-top: 0; }

.compact-form .trigger-field input, .compact-form
.trigger-field textarea {
  height: 2.2em;
  overflow: hidden; }

.expandable-form-toggle {
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: .6em;
  overflow: hidden; }
  .expandable-form-toggle, .expandable-form-toggle:before {
    width: 1.4em;
    height: 1.4em;
    text-align: center; }
  .expanded-form .expandable-form-toggle {
    display: block; }
    .expanded-form .expandable-form-toggle:before {
      content: 'x';
      display: block; }
  .expandable-form-toggle:hover {
    text-decoration: none; }

.compact-form .full-form {
  float: right;
  margin-top: .5em; }
  .compact-form .full-form:after {
    content: "→" /*rtl:'←'*/;
    display: inline-block;
    margin-left: .2em; }

/* =============================================================================
 *   Block Styling
 * ========================================================================== */
/* =============================================================================
 *   Node Styling
 * ========================================================================== */
.featured-node-tooltip {
  position: absolute;
  left: 100%;
  top: 1px;
  background-color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  padding: 3px 5px;
  color: #fff;
  border-radius: 3px;
  display: none;
  margin-left: 5px;
  z-index: 1; }
  .featured-node-tooltip:before {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent rgba(0, 0, 0, 0.85) transparent transparent;
    position: absolute;
    left: -5px;
    top: 50%;
    margin-top: -5px; }
  .node-teaser.node-promoted:hover .featured-node-tooltip {
    display: block; }

.node .node-title {
  margin: 0; }

/**
 * Node forms
 */
.node-form {
  /* Polls have some bad, over specific, styles so this is what needs to be
     done. */ }
  .node-form #poll-choice-table .form-text {
    width: 100%; }

/* =============================================================================
 *   Comment Styling - Comments, comment wrapper, comment form
 * ========================================================================== */
/**
 * Wrapper for a single comment
 */
.comment {
  margin-bottom: 20px; }

/**
 * "New" marker for comments that are new for the current user
 */
.new {
  color: #c00; }

/**
 * Nested comments are indented
 */
.indented {
  margin-left: 40px; }

.has-rate-widget {
  min-height: 4em;
  margin-right: 6em;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .has-rate-widget {
      min-height: 0;
      margin-right: 0; } }

.rate-widget-commons_thumbs_up_down {
  float: right;
  position: absolute;
  top: 0;
  right: -6em;
  margin-top: 0; }
  @media only screen and (max-width: 768px) {
    .rate-widget-commons_thumbs_up_down {
      position: static;
      float: left;
      margin: 20px auto; } }

.related-information {
  clear: both;
  margin: 20px 0; }
  .related-information .field {
    float: left; }

/* =============================================================================
 *   Forms
 * ========================================================================== */
html.js input.form-autocomplete {
  background-position: 100% 5px; }

html.js input.throbbing {
  background-position: 100% -15px;
  /* LTR */ }

/**
 * Forms in tables.
 */
tr.odd .form-item, tr.even .form-item {
  white-space: normal; }

/**
 * Wrapper for a form element (or group of form elements) and its label
 */
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00; }

.form-item label {
  font-weight: 700; }

.form-item .description {
  font-size: 0.85em; }

/**
 * Highlight marks and form required mark
 */
.marker,
.form-required {
  color: #c00; }

/**
 * Password confirmation
 */
.password-parent,
.confirm-parent {
  margin: 0; }

#edit-field-topics-und {
  border-top: 2px groove #8A8A8A;
  border-left: 2px groove #8A8A8A; }

/* =============================================================================
 *   Tables
 * ========================================================================== */
table {
  margin: 10px 0;
  padding: 0;
  width: 100%; }
  table.sticky-header {
    z-index: 10; }

table,
thead,
tbody,
tr,
th,
td {
  border-color: #941b80; }

table,
td,
th {
  vertical-align: middle; }

caption,
th,
td {
  text-align: left; }

thead tr {
  font-weight: 700;
  background-color: #e5e5e5; }

td,
th {
  border-bottom: 0;
  margin: 0;
  padding: 5px 7px; }

tbody tr {
  border-top: 1px solid #941b80; }

tr {
  /* Table row striping */ }
  tr.odd {
    background: #ffffff; }
  tr.info, tr.even, tr:nth-child(2n+2) {
    border-bottom: 0;
    background-color: #f5f5f5; }
  tr.odd td.active {
    background-color: #eee; }
  tr.even td.active {
    background-color: #ebebeb; }

/**
 * Forum tables
 * Core sets white-space to nowrap, which makes no sense
 */
#forum td .created,
#forum td .posts,
#forum td .topics,
#forum td .last-reply,
#forum td .replies,
#forum td .pager {
  white-space: normal; }

/* =============================================================================
 *   Messages
 * ========================================================================== */
div.messages {
  margin-bottom: 10px;
  margin-top: 10px; }
  div.messages ul {
    margin-top: 0;
    margin-bottom: 0; }

/**
 * Unpublished nodes
 */
.node-unpublished p.unpublished,
.comment-unpublished p.unpublished {
  color: pink;
  color: rgba(239, 170, 170, 0.4);
  font-family: Impact, Haettenschweiler, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Bitstream Vera Sans Bold", "Arial Black", sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word; }

/* =============================================================================
 *    Maintenance pages
 * ========================================================================== */
.maintenance-page .container {
  padding: 40px 0; }

.db-offline .container {
  margin: 0 auto;
  padding: 40px 0;
  width: 100%;
  max-width: 960px; }

.db-offline div.messages {
  margin: 20px 0 0; }

.db-offline #content {
  padding: 20px 0; }

/* =============================================================================
 *   Misc overrides for contrib modules
 * ========================================================================== */
/**
 * Admin menu overrides
 */
#admin-menu {
  margin: 0;
  padding: 0; }

/**
 * Devel Module
 */
.dev-query {
  background: #eee;
  padding: 30px; }

/**
 * Styleguide module tweaks
 */
#styleguide-header {
  padding: 0 10px; }
  #styleguide-header .item-list {
    font-family: inherit;
    margin: 0 20px 20px 0;
    min-height: 260px;
    width: auto; }

/* =============================================================================
 *   Front Page specific
 * ========================================================================== */
body.front .region-three-33-top {
  font-size: 24px;
  font-weight: 300; }
  body.front .region-three-33-top h1 {
    color: #333333;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px; }
  body.front .region-three-33-top .commons_home-welcome-image {
    float: right; }

body.front.not-logged-in #block-system-main .pane-1 .pane-title {
  font-weight: 700;
  font-size: 38px;
  color: #333333; }

body.front.logged-in .view-commons-homepage-content > .view-content {
  padding: 10px; }
  body.front.logged-in .view-commons-homepage-content > .view-content > .views-row {
    position: relative; }

body.front.logged-in .view-commons-homepage-content .article.node .author-datetime, body.front.logged-in .view-commons-homepage-content .node-teaser.node-group .author-datetime {
  margin-bottom: 1.5em;
  clear: both; }

body.front.logged-in .view-commons-homepage-content .views-row-first {
  border-top: none; }

body.front.logged-in .view-commons-homepage-content .item-list {
  padding: 10px 0 0 0; }

body.front.logged-in #views-exposed-form-commons-homepage-content-panel-pane-1 .views-exposed-widgets {
  padding: 10px 0;
  margin-bottom: 0; }

body.front.logged-in .view-commons-homepage-content .article.node.no-user-picture .node-header,
body.front.logged-in .view-commons-homepage-content .article.node.no-user-picture .node-content,
body.front.logged-in .view-commons-homepage-content .article.node.no-user-picture .author-datetime,
body.front.logged-in .view-commons-homepage-content .article.node.no-user-picture .links, body.front.logged-in .view-commons-homepage-content .article.node.node-page .node-header,
body.front.logged-in .view-commons-homepage-content .article.node.node-page .node-content,
body.front.logged-in .view-commons-homepage-content .article.node.node-page .author-datetime,
body.front.logged-in .view-commons-homepage-content .article.node.node-page .links {
  margin-left: 0; }

#quicktabs-commons_follow_ui .flag-email-group a,
#quicktabs-commons_follow_ui .flag-email-node a,
#quicktabs-commons_follow_ui .flag-email-user a,
#quicktabs-commons_follow_ui .flag-email-term a {
  cursor: default; }
  #quicktabs-commons_follow_ui .flag-email-group a input,
  #quicktabs-commons_follow_ui .flag-email-node a input,
  #quicktabs-commons_follow_ui .flag-email-user a input,
  #quicktabs-commons_follow_ui .flag-email-term a input {
    cursor: default; }

#quicktabs-commons_follow_ui .flag-email-group a span,
#quicktabs-commons_follow_ui .flag-email-group span span,
#quicktabs-commons_follow_ui .flag-email-node a span,
#quicktabs-commons_follow_ui .flag-email-node span span,
#quicktabs-commons_follow_ui .flag-email-user a span,
#quicktabs-commons_follow_ui .flag-email-user span span,
#quicktabs-commons_follow_ui .flag-email-term a span,
#quicktabs-commons_follow_ui .flag-email-term span span {
  display: none; }

/**
 *  Placeholder styles
 */
/**
 * Search
 */
.search-form-page .form-type-textfield label {
  white-space: nowrap; }

.search-form-page .form-type-textfield input {
  width: auto; }

@media only screen and (max-width: 480px) {
  .search-form-page .form-type-textfield,
  .search-form-page .form-type-textfield input {
    display: block;
    width: 100%; } }

@media only screen and (min-width: 481px) {
  .search-form-page .form-item {
    margin-bottom: 0; } }

.action-item-search[type="submit"] {
  min-width: image-width("icons/icon-search.png");
  background: -owg-#941b80 image-url("icons/icon-search.png") no-repeat;
  background: -webkit-#941b80 image-url("icons/icon-search.png") no-repeat;
  background: -moz-#941b80 image-url("icons/icon-search.png") no-repeat;
  background: -o-#941b80 image-url("icons/icon-search.png") no-repeat;
  background: #941b80 image-url("icons/icon-search.png") no-repeat;
  background: -owg-image-url("icons/icon-search.png") no-repeat;
  background: -webkit-image-url("icons/icon-search.png") no-repeat;
  background: -moz-image-url("icons/icon-search.png") no-repeat;
  background: -o-image-url("icons/icon-search.png") no-repeat;
  background: image-url("icons/icon-search.png") no-repeat;
  border-color: #3e0b35;
  /*@include text-shadow(
                    -1px 1px 2px $text-shadow,
                    -1px -1px 2px $text-shadow,
                    1px -1px 2px $text-shadow,
                    1px 1px 2px $text-shadow
    );*/ }
  .action-item-search[type="submit"], .action-item-search[type="submit"]:link, .action-item-search[type="submit"]:visited {
    color: white; }
  .action-item-search[type="submit"]:hover {
    color: #fefcfe;
    background: #7e176d image-url("icons/icon-search.png") no-repeat;
    background: -owg-image-url("icons/icon-search.png") no-repeat;
    background: -webkit-image-url("icons/icon-search.png") no-repeat;
    background: -moz-image-url("icons/icon-search.png") no-repeat;
    background: -o-image-url("icons/icon-search.png") no-repeat;
    background: image-url("icons/icon-search.png") no-repeat;
    border-color: #130310;
    /*@include text-shadow(
                      -1px 1px 2px $hover-text-shadow,
                      -1px -1px 2px $hover-text-shadow,
                      1px -1px 2px $hover-text-shadow,
                      1px 1px 2px $hover-text-shadow
      );*/ }
  .action-item-search[type="submit"]:focus, .action-item-search[type="submit"]:active {
    background: #941b80 image-url("icons/icon-search.png") no-repeat;
    background: -owg-image-url("icons/icon-search.png") no-repeat;
    background: -webkit-image-url("icons/icon-search.png") no-repeat;
    background: -moz-image-url("icons/icon-search.png") no-repeat;
    background: -o-image-url("icons/icon-search.png") no-repeat;
    background: image-url("icons/icon-search.png") no-repeat;
    border-color: #3e0b35;
    /*@include text-shadow(
                      -1px 1px 2px $text-shadow,
                      -1px -1px 2px $text-shadow,
                      1px -1px 2px $text-shadow,
                      1px 1px 2px $text-shadow
      );*/ }
  [disabled].action-item-search[type="submit"] {
    color: #db3ac0;
    background: #941b80;
    border-color: #3e0b35;
    box-shadow: none;
    text-shadow: none; }

.action-item-search[type="submit"], .action-item-search[type="submit"]:hover, .action-item-search[type="submit"]:active, .action-item-search[type="submit"]:focus {
  background-position: 1.5em center; }

.action-item-search {
  float: right;
  margin-right: 0;
  margin-bottom: 0; }
  .action-item-search, .action-item-search[type="submit"] {
    padding-left: 3em; }
  @media only screen and (max-width: 480px) {
    .action-item-search {
      float: none;
      clear: both;
      display: block; } }
  @media only screen and (min-width: 481px) {
    .action-item-search {
      margin-top: 0; } }

.block-facetapi {
  margin-bottom: .7em;
  padding-bottom: .7em;
  border-bottom: 1px solid #941b80; }
  .block-facetapi:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none; }
  .block-facetapi .block-title {
    margin-bottom: 10px;
    color: #666666;
    font-size: 1.2em; }

.facetapi-facetapi-links,
.facetapi-date-range {
  margin: 0;
  padding: 0; }
  .facetapi-facetapi-links li.leaf,
  .facetapi-date-range li.leaf {
    margin-left: 0;
    list-style-type: none;
    list-style-image: none; }

@media only screen and (max-width: 480px) {
  .page-search .two-33-66 > .filters-processed {
    clear: none;
    position: absolute;
    right: 0;
    float: right;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    font-size: .88em;
    background: white;
    border: 1px solid #eee;
    z-index: 1; }
    .page-search .two-33-66 > .filters-processed .commons-pod {
      margin: 0;
      padding: 10px 0;
      border-radius: 0 0 0 3px; }
    .page-search .two-33-66 > .filters-processed .panel-pane {
      display: none; }
    .page-search .two-33-66 > .filters-processed .filter-trigger {
      display: block; }
  .page-search .two-33-66 > .expanded {
    margin-right: 0;
    width: 75%;
    height: auto;
    opacity: 1; }
    .page-search .two-33-66 > .expanded .commons-pod {
      padding: 10px; }
    .page-search .two-33-66 > .expanded .panel-pane {
      display: block; } }

@media only screen and (max-width: 480px) {
  .page-search .filter-overlay.expanded {
    display: block; } }

.facetapi-processed,
.block-content .facetapi-processed {
  margin-bottom: 0;
  padding-left: 0; }
  .facetapi-processed li.last,
  .block-content .facetapi-processed li.last {
    margin-bottom: 0; }

.search-results-title,
.search-results-wrapper > h2 {
  margin-top: .2em; }

.search-results {
  margin-top: 0; }
  .search-results,
  .block-content .search-results {
    margin-bottom: 0;
    padding: 0; }

.search-result {
  border-bottom: 1px solid #941b80;
  list-style-type: none; }
  .search-result,
  .block-content .search-result {
    padding-bottom: .7em;
    margin-bottom: .7em; }
    .search-result:last-child,
    .block-content .search-result:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0; }
  .search-result h2 {
    font-size: 1.429em;
    line-height: 1.2em;
    margin: .5em 0 0; }
  .search-result .title {
    margin-top: 0;
    margin-bottom: .2em; }
  .search-result .search-result-url {
    color: #666666;
    font-size: .85em;
    margin-bottom: 1em; }
  .apachesolr_search-results .search-result .title,
  .apachesolr_search-results .search-result .search-result-url,
  .apachesolr_search-results .search-result .search-result-date,
  .apachesolr_search-results .search-result .search-result-snippet-info {
    display: none; }
  .apachesolr_search-results .search-result .pane-node-flag-commons-follow-group-link {
    position: static;
    right: auto;
    top: auto;
    float: right; }

/* Search profiles */
.profile-search-result {
  box-sizing: border-box;
  display: inline-block;
  padding-bottom: 0;
  vertical-align: top;
  width: 48.5%;
  margin-right: 2%; }
  .profile-search-result:nth-child(even) {
    margin-right: 0; }
  @media only screen and (max-width: 480px) {
    .profile-search-result {
      width: 100%;
      margin-right: 0; } }
  @media only screen and (min-width: 769px) {
    .profile-search-result {
      width: 31.5%; }
      .profile-search-result:nth-child(even) {
        margin-right: 2%; }
      .profile-search-result:nth-child(3n) {
        margin-right: 0; } }
  .commons-pod .profile-search-result {
    width: 100%;
    margin-right: 0; }
    .commons-pod .profile-search-result .profile-actions {
      box-sizing: border-box;
      margin-right: 0;
      margin-left: 0;
      border: 1px solid #941b80;
      border-radius: 0; }
  .profile-search-result h4 {
    margin: 0; }

.profile-header,
.profile-content {
  margin-left: 72px; }

.profile-header {
  box-sizing: border-box; }
  .profile-header .user-picture {
    position: relative;
    top: -1.2em;
    float: left;
    margin-bottom: 0;
    margin-left: -72px; }

.profile-content {
  overflow: hidden;
  height: 2.8em;
  line-height: 1.4em; }
  .profile-content:before {
    content: "";
    float: left;
    width: 5px;
    height: 2.8em; }
  .profile-content > *:first-child {
    float: right;
    width: 100%;
    margin-left: -5px; }
  .profile-content:after {
    content: "\02026";
    float: right;
    position: relative;
    top: -1.4em;
    left: 100%;
    width: 3em;
    margin-left: -3em;
    padding-right: 5px;
    text-align: right;
    background: #fff; }

.associated-groups-title,
.associated-groups,
.group-item {
  display: inline;
  margin: 0;
  color: #858585;
  white-space: normal; }

.associated-groups-title {
  font-weight: normal; }

.associated-groups,
.block-content .associated-groups {
  padding-left: 0; }

.associated-groups-title,
.group-item {
  margin-right: .2em;
  font-size: .88em; }

.group-item:after {
  content: ","; }

.group-item:last-child {
  margin-right: 0; }
  .group-item:last-child:after {
    content: "."; }

.profile-actions {
  clear: both;
  width: 100%;
  margin-top: 10px;
  margin-left: -10px;
  margin-right: -10px;
  padding: 5px 10px;
  background: #f5f5f5;
  border-top: 1px solid #941b80;
  -moz-border-radius-bottomright: 0.4em;
  -webkit-border-bottom-right-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
  -moz-border-radius-bottomleft: 0.4em;
  -webkit-border-bottom-left-radius: 0.4em;
  border-bottom-left-radius: 0.4em; }
  .profile-actions .field {
    /* Make fields in the wrapper inline. */
    display: inline-block; }

/**
 * Notification settings
 */
.view-commons-follow-node .views-field-ops,
.view-commons-follow-node .views-field-ops-1,
.view-commons-follow-user .views-field-ops,
.view-commons-follow-user .views-field-ops-1,
.view-commons-follow-taxonomy-term .views-field-ops,
.view-commons-follow-taxonomy-term .views-field-ops-1 {
  width: 20%; }

.view-commons-follow-node .views-field-ops,
.view-commons-follow-user .views-field-ops,
.view-commons-follow-taxonomy-term .views-field-ops {
  text-align: right; }

.view-commons-follow-node .views-field-ops-1,
.view-commons-follow-user .views-field-ops-1,
.view-commons-follow-taxonomy-term .views-field-ops-1 {
  text-align: center; }

/**
 * Trusted contacts
 */
.views-field-field-membership-token a:first-child:after {
  content: "|";
  display: inline-block;
  padding: 0 .3em 0 .5em;
  color: #333333; }

.trusted-status-request:before {
  content: "\271a";
  position: relative;
  top: .1em;
  display: inline-block;
  margin-right: .2em;
  font-size: 1.4em;
  line-height: .6em; }

.message-contact:before {
  content: "";
  background: sprite(sprite-map("icons/*.png", 20px), message) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), message));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), message));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

/**
 * Private message
 */
.privatemsg-conversation .form-actions,
.privatemsg-conversation .form-actions [class*="action-item"] {
  margin-bottom: 0; }

.privatemsg-message-participants {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #941b80; }

.privatemsg-message {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-left: 72px;
  border-bottom: 1px solid #941b80; }
  .privatemsg-message .user-picture {
    position: relative;
    float: left;
    width: 50px;
    margin-left: -72px; }

.privatemsg-message-links {
  font-size: .88em;
  text-align: right; }

.message-delete:before {
  content: "\2715";
  display: inline-block;
  margin-right: .3em;
  color: #a3a3a3;
  font-weight: bold; }

/**
 * Commons Origins Pallete Choices
 */
#edit-commons-origins-palette .form-item-commons-origins-palette {
  clear: both;
  margin-bottom: 10px; }
  #edit-commons-origins-palette .form-item-commons-origins-palette input[type="radio"] {
    float: left; }
  #edit-commons-origins-palette .form-item-commons-origins-palette label {
    margin-left: 20px; }

/**
 * Login and signup form
 */
#user-login input[type="text"], #user-login input[type="password"], #user-register-form input[type="text"], #user-register-form input[type="password"] {
  width: 20em;
  max-width: 100%; }

/**
 * Add the color palettes
 */
.flexslider {
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: none; }
  .flexslider .slides {
    margin: 0;
    padding: 0; }

.optionset-default {
  background: none; }

.flex-control-paging {
  display: none; }
  @media only screen and (min-width: 481px) {
    .flex-control-paging {
      display: block; } }

.flex-direction-nav {
  display: block; }
  .flex-direction-nav a {
    display: block;
    font-size: 0;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    text-align: center;
    opacity: 1;
    line-height: 1; }
    .flex-direction-nav a:before {
      color: #fff; }
    .flex-direction-nav a:before {
      font-family: "icons";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      font-size: 1rem;
      line-height: 1;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      font-size: 1rem;
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      font-size: 32px;
      margin: 0;
      width: 100%;
      color: #fff;
      display: block; }
    .flex-direction-nav a.flex-disabled {
      opacity: 0; }
    .flex-direction-nav a.flex-next {
      right: .5rem;
      left: auto;
      float: left; }
      .flex-direction-nav a.flex-next:before {
        content: "" /*rtl:""*/; }
    .flex-direction-nav a.flex-prev {
      left: .5rem;
      right: auto;
      float: right; }
      .flex-direction-nav a.flex-prev:before {
        content: "" /*rtl:""*/; }
  .flex-direction-nav .flex-control-paging li a {
    background-color: rgba(0, 0, 0, 0.3); }
    .flex-direction-nav .flex-control-paging li a.flex-active {
      background-color: #009eb2; }

/**
 * Generic styles for the more link
 */
.more-link {
  float: right;
  overflow: hidden; }
  .more-link a {
    color: #858585;
    text-decoration: none !important;
    font-size: 0;
    line-height: 0;
    float: right;
    text-align: right;
    width: 100%; }
    .more-link a:after {
      font-family: "icons";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      font-size: 1rem;
      line-height: 1;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      font-size: 1rem;
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      content: "";
      font-size: 2rem;
      line-height: 32px; }

.item-list-pager .pager-load-more {
  margin: 0;
  padding: 1em 0; }
  .item-list-pager .pager-load-more li {
    padding: 0; }
  .item-list-pager .pager-load-more a {
    border: 2px solid #000;
    padding: .25em 1.5em;
    display: inline-block;
    font-weight: bold;
    color: #000 !important;
    position: relative;
    transition: all .25s ease;
    font-size: 18px;
    background: transparent !important; }
    .item-list-pager .pager-load-more a:before, .item-list-pager .pager-load-more a:after {
      content: '';
      border-top: 2px solid #000;
      margin-top: -1px;
      position: absolute;
      top: 50%;
      width: .33em; }
    .item-list-pager .pager-load-more a:hover {
      color: #941b80 !important;
      text-decoration: none;
      border-color: #941b80; }
      .item-list-pager .pager-load-more a:hover:before, .item-list-pager .pager-load-more a:hover:after {
        border-color: #941b80; }
    .item-list-pager .pager-load-more a:after {
      left: 0; }
    .item-list-pager .pager-load-more a:before {
      right: 0; }

.attachment-before {
  border-bottom: 2px dashed #941b80;
  padding: 0 5px; }

.view-filters {
  background: #f5f5f5;
  border-radius: 2px;
  border-bottom: 2px dashed #941b80;
  display: block;
  clear: both;
  vertical-align: top;
  margin-bottom: 0;
  font-size: .88em; }
  .view-filters form {
    margin: 0;
    padding: 5px; }
  .view-filters .views-exposed-widgets {
    margin: 0; }
  .view-filters .views-exposed-widget {
    margin: 0 1em 0 0; }
  .view-filters .views-widget-filter-keys label {
    display: none; }
  .view-filters .views-widget-filter-keys .views-widget {
    box-sizing: border-box;
    width: 100%;
    max-width: 300px; }
    .view-filters .views-widget-filter-keys .views-widget .form-text {
      color: #fff;
      background: #858585;
      border-radius: 5px; }
      .view-filters .views-widget-filter-keys .views-widget .form-text::-webkit-input-placeholder {
        color: #fff;
        opacity: 9 !important; }
      .view-filters .views-widget-filter-keys .views-widget .form-text:-moz-placeholder {
        color: #fff;
        opacity: 9 !important; }
      .view-filters .views-widget-filter-keys .views-widget .form-text::-moz-placeholder {
        color: #fff;
        opacity: 9 !important; }
      .view-filters .views-widget-filter-keys .views-widget .form-text:-ms-input-placeholder {
        color: #fff;
        opacity: 9 !important; }
  .view-filters .views-widget-sort-order {
    display: none; }
  .view-filters .view-content,
  .view-filters .view-empty {
    clear: both; }
  .view-filters .form-submit {
    margin-top: 0;
    margin-bottom: .1em; }
  .view-filters .hidden {
    display: none; }

.dynamic-filter-lists .views-exposed-widget,
.dynamic-filter-lists .views-widget,
.dynamic-filter-lists label,
.dynamic-filter-lists .form-select-wrapper {
  display: inline-block;
  line-height: 1.7em;
  vertical-align: baseline;
  white-space: nowrap; }

.dynamic-filter-lists .views-exposed-widget {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0; }
  .dynamic-filter-lists .views-exposed-widget label {
    font-weight: normal;
    color: #858585;
    margin: 0; }

.dynamic-filter-lists .views-submit-button {
  position: absolute;
  visibility: hidden;
  padding: 0;
  height: auto; }
  .dynamic-filter-lists .views-submit-button .form-submit {
    margin-top: 0; }

#edit-following-wrapper {
  display: none; }

.node-title.page-title {
  font-size: 2em;
  font-weight: bold;
  color: #000;
  font-family: "Nassim", sans-serif;
  margin-top: 0; }
  .node-title.page-title a {
    color: inherit;
    text-decoration: none !important; }

.node .field-label-inline {
  overflow: hidden; }
  .node .field-label-inline .field-label {
    float: left;
    margin: 0; }
  .node .field-label-inline .field-items {
    float: left; }

.node.node-full {
  position: relative; }
  .node.node-full .page-title {
    font-size: 2em;
    font-weight: bold;
    color: #000;
    font-family: "Nassim", sans-serif;
    margin-top: 0; }
    .node.node-full .page-title a {
      color: inherit;
      text-decoration: none !important; }
  .node.node-full .field-name-body {
    margin-top: 0; }
  .node.node-full .commons-pod {
    clear: both; }
  .node.node-full > nav {
    clear: both;
    border-top: 2px dashed #941b80;
    margin: 0; }
    .node.node-full > nav .links.inline {
      text-align: left;
      margin-top: 0;
      float: right; }
      .node.node-full > nav .links.inline li {
        text-align: left;
        float: left;
        margin: .5em; }
    .node.node-full > nav > .flag-wrapper {
      text-align: left;
      float: right;
      margin: .5em;
      clear: none; }
  .node.node-full .node-content, .node.node-full .submitted {
    padding: 0 1em; }
  .node.node-full .comment-wrapper {
    padding: 1em;
    border-top: 2px dashed #941b80;
    border-bottom: 2px dashed #941b80; }
  .node.node-full .node-content {
    overflow: hidden;
    *zoom: 1;
    padding-bottom: 1em; }
    .node.node-full .node-content > .form-type-item {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0; }
      .node.node-full .node-content > .form-type-item > label {
        display: none; }
  .node.node-full .flag-commons_follow_node {
    position: absolute;
    top: -2em;
    right: 0; }

.node.node-teaser {
  overflow: hidden;
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 2px dashed #941b80; }
  .node.node-teaser footer {
    display: table-cell;
    width: 25%;
    padding: 1em;
    text-align: center;
    vertical-align: top;
    border-right: 2px dashed #941b80; }
  .node.node-teaser .node-content {
    width: 75%;
    display: table-cell;
    vertical-align: top;
    padding: 1em; }
    .node.node-teaser .node-content .node-title {
      color: #941b80; }
  .node.node-teaser .group-text {
    float: left;
    width: 75%; }
  .node.node-teaser .group-image {
    float: left;
    width: 25%;
    padding-right: 1em;
    box-sizing: border-box; }

.node .pane-node-commons-groups-group-contributors-count-topics {
  font-weight: bold;
  color: #000; }
  .node .pane-node-commons-groups-group-contributors-count-topics a {
    color: inherit; }

.flag-inappropriate-node, .flag-inappropriate-comment {
  float: right; }

.commons-bw-partial-node-form {
  position: relative;
  border-bottom: 2px dashed #941b80;
  margin-bottom: 0;
  padding-left: 68px; }
  .commons-bw-partial-node-form .user-picture {
    position: absolute;
    left: 0;
    top: 0; }
  .commons-bw-partial-node-form.compact-form .form-item {
    margin: 0; }
  .commons-bw-partial-node-form.compact-form textarea {
    resize: none; }
  .commons-bw-partial-node-form .user-picture {
    margin-top: 2.6em; }
  @media only screen and (max-width: 480px) {
    .commons-bw-partial-node-form {
      padding-left: 0; }
      .commons-bw-partial-node-form .user-picture {
        display: none; } }
  .commons-bw-partial-node-form .field-type-image {
    margin-top: 1.7em; }
  .commons-bw-partial-node-form .field-name-og-group-ref .fieldset-outer-wrapper {
    margin-top: 1.7em;
    margin-bottom: 1.7em;
    padding-top: 1.7em;
    border-top: 1px solid #941b80; }

.page-node #page-title {
  display: none; }

.node-panel {
  padding: 0; }
  .node-panel.node-full .node-content, .node-panel.node-full .submitted {
    width: 100%;
    float: none; }
  .node-panel .submitted {
    display: none; }

.node-event .commons-events-form-float {
  float: none;
  margin: 0 0 1em;
  padding: 0;
  width: auto; }

.node-event.node-teaser .commons-events-form-float {
  display: none; }

.view-display-id-commons_events_upcoming_page .views-row article .commons-events-form-float {
  float: right;
  margin: -40px 0 0 0; }

.commons-events-form-float {
  float: right;
  margin-left: 15px; }
  .commons-events-form-float input {
    margin: 0; }

.commons-event-count {
  font-size: 1.2em;
  font-weight: bold; }

form.commons-events-form-float {
  box-sizing: border-box;
  float: none;
  margin: 1em 0;
  padding-left: 0;
  width: auto;
  display: none;
  text-align: left; }
  form.commons-events-form-float .commons-event-status {
    font-size: .88em;
    line-height: 1.4em;
    margin-top: .3em; }
  form.commons-events-form-float .form-text {
    width: auto; }

.node-group.node-teaser .panel-flexible {
  border-bottom: 2px dashed #941b80; }
  .node-group.node-teaser .panel-flexible .panels-flexible-region {
    padding: 1em; }
  .node-group.node-teaser .panel-flexible .panels-flexible-region.first {
    text-align: center;
    border-right: 2px dashed #941b80; }
    .node-group.node-teaser .panel-flexible .panels-flexible-region.first .panel-pane {
      margin: 0 0 5px; }

.node-group .field-name-field-group-logo {
  overflow: hidden;
  display: inline-block; }
  .node-group .field-name-field-group-logo img {
    display: inline-block;
    border-radius: 100%;
    float: left;
    margin-right: 1em; }

.node-group .pane-node-field-group-logo .field-name-field-group-logo a {
  overflow: hidden;
  display: inline-block; }
  .node-group .pane-node-field-group-logo .field-name-field-group-logo a img {
    display: inline-block;
    border-radius: 100%;
    float: left; }

.node-group .pane-node-title h2 {
  font-size: 1.75em;
  text-align: center;
  font-family: "Nassim", sans-serif;
  line-height: 1.2;
  margin: 0; }

.node-group .pane-node-flag-commons-follow-group-link {
  display: inline-block;
  position: absolute;
  top: .5em;
  right: 0;
  margin: 0; }
  @media only screen and (max-width: 768px) {
    .node-group .pane-node-flag-commons-follow-group-link {
      position: static;
      top: auto;
      right: auto; } }

.node-group .view-commons-groups-recent-content .views-row a {
  color: #000; }

.node-group .view-commons-contributors-group {
  overflow: hidden; }
  .node-group .view-commons-contributors-group .view-content {
    width: 100%;
    margin-right: -32px;
    padding-right: 32px;
    float: left;
    box-sizing: border-box; }
  .node-group .view-commons-contributors-group .more-link {
    width: 32px;
    float: left;
    text-align: center; }
  .node-group .view-commons-contributors-group .views-field-nothing a img {
    border-radius: 100%; }

.view-commons-groups-directory > .view-content > .views-row,
.view-commons-homepage-content .node-teaser.node-group > .view-content > .views-row {
  position: relative; }

.view-commons-groups-directory .pane-node-body,
.view-commons-homepage-content .node-teaser.node-group .pane-node-body {
  margin-bottom: 0; }

.comment .field-name-comment-body {
  clear: both; }

.node-form .field-name-body .fieldset-outer-wrapper {
  display: none; }

.node-form .field-name-og-group-ref legend, .node-form .field-name-og-group-ref .fieldset-description {
  display: none; }

.node-form .field-name-field-date-form .end-date-wrapper {
  position: relative;
  top: -2.7em; }

#front .panel-pane {
  clear: both;
  margin: 0;
  float: left;
  width: 100%;
  background: #fff; }
  #front .panel-pane .block-title {
    font-size: 2em;
    color: #009eb2; }

#front .field-name-field-body {
  font-size: 1.33em; }

#front .entity-bean > .content > .group-text {
  overflow: hidden;
  padding: 1em;
  background: #fff; }
  #front .entity-bean > .content > .group-text.no-padding {
    padding: 0; }
  #front .entity-bean > .content > .group-text .field-name-field-body {
    max-width: 600px; }

@media only screen and (min-width: 481px) {
  #front .move-bean-content .entity-bean > .content {
    background-size: 12px 12px !important;
    background-image: linear-gradient(45deg, transparent 49%, #b3b3b3 50%, #b3b3b3 51%, transparent 52%); }
    #front .move-bean-content .entity-bean > .content .group-text {
      margin-left: 25%; } }

#front .field-name-field-paragraphs {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background: #009eb2;
  color: #fff;
  padding: 20px; }
  #front .field-name-field-paragraphs .field-name-field-link a {
    border: 2px solid #009eb2;
    padding: .25em 1.5em;
    display: inline-block;
    font-weight: bold;
    color: #941b80 !important;
    position: relative;
    transition: all .25s ease;
    font-size: 18px;
    background: transparent !important;
    border-color: #fff;
    color: #fff; }
    #front .field-name-field-paragraphs .field-name-field-link a:before, #front .field-name-field-paragraphs .field-name-field-link a:after {
      content: '';
      border-top: 2px solid #009eb2;
      margin-top: -1px;
      position: absolute;
      top: 50%;
      width: .33em; }
    #front .field-name-field-paragraphs .field-name-field-link a:hover {
      color: #941b80 !important;
      text-decoration: none;
      border-color: #941b80; }
      #front .field-name-field-paragraphs .field-name-field-link a:hover:before, #front .field-name-field-paragraphs .field-name-field-link a:hover:after {
        border-color: #941b80; }
    #front .field-name-field-paragraphs .field-name-field-link a:after {
      left: 0; }
    #front .field-name-field-paragraphs .field-name-field-link a:before {
      right: 0; }
    #front .field-name-field-paragraphs .field-name-field-link a:before, #front .field-name-field-paragraphs .field-name-field-link a:after {
      border-color: #fff; }
  #front .field-name-field-paragraphs > .field-items {
    display: none !important;
    overflow: hidden; }
    @media only screen and (min-width: 481px) {
      #front .field-name-field-paragraphs > .field-items {
        display: table;
        width: 100%; } }
    @media only screen and (min-width: 481px) {
      #front .field-name-field-paragraphs > .field-items > .field-item {
        width: 33%;
        display: table-cell;
        padding: 0 20px;
        box-sizing: border-box;
        border-right: 2px dashed #fff; }
        #front .field-name-field-paragraphs > .field-items > .field-item:last-child {
          border-right: none; } }
  #front .field-name-field-paragraphs .component-title {
    color: #fff;
    text-align: center; }

#front .pane-bean-resources {
  border-top: 2px dashed #941b80;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  /*.group-image {
      @include wide;
      text-align: center;
      border-bottom: 2px dashed $primary-color;
    }*/ }
  #front .pane-bean-resources .group-text {
    text-align: center;
    margin: 0 auto; }

.page-resources .pane-bean-cta {
  text-align: center; }

.pane-commons-groups-commons-groups-create-group {
  text-align: center; }
  .pane-commons-groups-commons-groups-create-group a {
    margin: 0; }
    @media only screen and (max-width: 480px) {
      .pane-commons-groups-commons-groups-create-group a {
        float: none;
        display: block; } }

/**
 * Events Page
 */
.page-events .region-two-66-33-top {
  position: relative; }

.page-events .region-three-25-50-25-third .pane-views-panes {
  margin-right: 0; }

.page-events div.search-result-wrapper h3.title {
  text-decoration: none; }
  .page-events div.search-result-wrapper h3.title a {
    color: #009eb2; }

@media only screen and (max-width: 768px) {
  .page-events .three-25-50-25 > .filters-processed {
    clear: none;
    position: absolute;
    right: 0;
    float: right;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    font-size: .88em;
    background: white;
    border: 1px solid #eee;
    z-index: 1; }
    .page-events .three-25-50-25 > .filters-processed .commons-pod {
      margin: 0;
      padding: 10px 0;
      border-radius: 0 0 0 3px; }
    .page-events .three-25-50-25 > .filters-processed .panel-pane {
      display: none; }
    .page-events .three-25-50-25 > .filters-processed .filter-trigger {
      display: block; }
  .page-events .three-25-50-25 > .expanded {
    margin-right: 0;
    width: 75%;
    height: auto;
    opacity: 1; }
    .page-events .three-25-50-25 > .expanded .commons-pod {
      padding: 10px; }
    .page-events .three-25-50-25 > .expanded .panel-pane {
      display: block; } }

@media only screen and (max-width: 768px) {
  .page-events .filter-overlay.expanded {
    display: block; } }

.pane-commons-events-commons-events-create-event-link {
  text-align: center; }
  .pane-commons-events-commons-events-create-event-link a {
    margin: 0; }
    @media only screen and (max-width: 480px) {
      .pane-commons-events-commons-events-create-event-link a {
        float: none;
        display: block; } }

.view.listing .view-content {
  border-bottom: 2px dashed #941b80; }

.view.listing .views-row a {
  color: #000;
  font-weight: bold; }

/**
 * User profile
 */
.page-user .panel-display .region-three-33-top {
  padding-left: 150px;
  /* This feels hackish, but it is only for administrating the layout of the
         page. It is still not ideal as it is inflexible and only works with the
         current core functionality of commons. */ }
  @media only screen and (max-width: 480px) {
    .page-user .panel-display .region-three-33-top {
      padding-left: 10px; } }
  .page-user .panel-display .region-three-33-top .panels-ipe-portlet-wrapper:nth-last-child(-n+5) {
    display: inline-block; }

.page-user .pane-user-picture {
  float: left;
  margin-right: 1em; }
  @media only screen and (max-width: 480px) {
    .page-user .pane-user-picture {
      float: none;
      margin-right: 0;
      text-align: center; } }

.page-user .pane-user-field-facebook-url,
.page-user .pane-user-field-facebook-url a,
.page-user .pane-user-field-linkedin-url,
.page-user .pane-user-field-linkedin-url a,
.page-user .pane-user-field-twitter-url,
.page-user .pane-user-field-twitter-url a,
.page-user .pane-user-group-group,
.page-user .pane-user-group-group a,
.page-user .pane-user-flag-commons-follow-user-link,
.page-user .pane-user-flag-commons-follow-user-link a {
  display: inline-block; }

.page-user .pane-user-field-facebook-url a,
.page-user .pane-user-field-linkedin-url a,
.page-user .pane-user-field-twitter-url a {
  margin-right: 1em; }
  .page-user .pane-user-field-facebook-url a:before,
  .page-user .pane-user-field-linkedin-url a:before,
  .page-user .pane-user-field-twitter-url a:before {
    content: "";
    display: inline-block;
    vertical-align: middle; }

@media only screen and (max-width: 480px) {
  .page-user .pane-user-field-facebook-url a,
  .page-user .pane-user-field-linkedin-url a,
  .page-user .pane-user-field-twitter-url a {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden; }
  .page-user .pane-user-field-facebook-url a:before,
  .page-user .pane-user-field-linkedin-url a:before,
  .page-user .pane-user-field-twitter-url a:before {
    content: normal; } }

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .page-user .pane-user-field-facebook-url,
  .page-user .pane-user-field-linkedin-url,
  .page-user .pane-user-field-twitter-url {
    display: block; } }

.page-user .pane-user-field-facebook-url a:before {
  background: sprite(sprite-map("icons/*.png", 20px), facebook) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), facebook));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), facebook));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

@media only screen and (max-width: 480px) {
  .page-user .pane-user-field-facebook-url a {
    background: sprite(sprite-map("icons/*.png", 20px), facebook) no-repeat;
    width: image-width(sprite-file(sprite-map("icons/*.png", 20px), facebook));
    height: image-height(sprite-file(sprite-map("icons/*.png", 20px), facebook));
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: 1px; } }

.page-user .pane-user-field-linkedin-url a:before {
  background: sprite(sprite-map("icons/*.png", 20px), linkedin) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), linkedin));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), linkedin));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

@media only screen and (max-width: 480px) {
  .page-user .pane-user-field-linkedin-url a {
    background: sprite(sprite-map("icons/*.png", 20px), linkedin) no-repeat;
    width: image-width(sprite-file(sprite-map("icons/*.png", 20px), linkedin));
    height: image-height(sprite-file(sprite-map("icons/*.png", 20px), linkedin));
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: 1px; } }

.page-user .pane-user-field-twitter-url a:before {
  background: sprite(sprite-map("icons/*.png", 20px), twitter) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), twitter));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), twitter));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px; }

@media only screen and (max-width: 480px) {
  .page-user .pane-user-field-twitter-url a {
    background: sprite(sprite-map("icons/*.png", 20px), twitter) no-repeat;
    width: image-width(sprite-file(sprite-map("icons/*.png", 20px), twitter));
    height: image-height(sprite-file(sprite-map("icons/*.png", 20px), twitter));
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: 1px; } }

.page-user .pane-user-flag-commons-follow-user-link {
  clear: both; }

/**
 * Profile listings
 */
.profile-badges {
  overflow: hidden; }
  .profile-badges .profile-badge {
    float: left;
    list-style-type: none;
    text-align: center;
    box-sizing: border-box;
    padding: 0 2px;
    /*border-bottom: 1px dashed $border;
    padding: $gutter_width 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    &:last-child {
      border-bottom: none;
    }*/ }
    .profile-badges .profile-badge .profile-badge-picture {
      /*display: table-cell;
      width: 48px;
      vertical-align: middle;
      padding-right: 5px;
      text-align: center;
      img {
        max-width: 48px;
        height: auto;
      }*/ }
    .profile-badges .profile-badge .profile-badge-name {
      display: none;
      line-height: 1.2em;
      font-size: .88em;
      margin-top: .6em;
      vertical-align: middle; }
      .profile-badges .profile-badge .profile-badge-name a {
        color: #000; }

.profile {
  border-bottom: 2px solid #941b80; }
  .profile .actions {
    display: none; }
  .profile .field-name-realname {
    margin: 0 !important; }
    .profile .field-name-realname .field-label {
      display: none; }
    .profile .field-name-realname .field-item {
      font-size: 2em;
      font-weight: bold;
      color: #000;
      font-family: "Nassim", sans-serif;
      margin-top: 0; }
      .profile .field-name-realname .field-item a {
        color: inherit;
        text-decoration: none !important; }
  .profile .user-picture {
    float: none !important;
    margin: 0; }

.view-commons-groups-user-groups .views-row {
  overflow: hidden; }
  .view-commons-groups-user-groups .views-row .views-field-field-group-logo {
    float: left;
    margin-right: .5em; }
    .view-commons-groups-user-groups .views-row .views-field-field-group-logo img {
      border-radius: 100%; }

.pane-commons-activity-streams-user-activity-panel-pane-1 {
  padding: 1em; }
  .pane-commons-activity-streams-user-activity-panel-pane-1 .pane-title {
    text-align: center;
    font-size: 1.8em;
    border-bottom: 2px dashed #941b80; }
  .pane-commons-activity-streams-user-activity-panel-pane-1 .view-content {
    border-top: 2px dashed #941b80; }
  .pane-commons-activity-streams-user-activity-panel-pane-1 .views-row {
    padding: 1em;
    overflow: hidden;
    border-bottom: 1px dashed #941b80; }

.page-jobs .pane-bean-cta {
  text-align: center; }

.paragraphs-item-component .component-title {
  font-weight: bold;
  margin: 0 0 .5em;
  font-family: "Nassim", sans-serif;
  font-size: 1.75em;
  color: #941b80; }

.paragraphs-item-component .field-name-field-image {
  text-align: center; }

.paragraphs-item-component .field-name-field-link {
  text-align: center;
  margin: 1em auto; }
  .paragraphs-item-component .field-name-field-link a {
    border: 2px solid #009eb2;
    padding: .25em 1.5em;
    display: inline-block;
    font-weight: bold;
    color: #941b80 !important;
    position: relative;
    transition: all .25s ease;
    font-size: 18px;
    background: transparent !important; }
    .paragraphs-item-component .field-name-field-link a:before, .paragraphs-item-component .field-name-field-link a:after {
      content: '';
      border-top: 2px solid #009eb2;
      margin-top: -1px;
      position: absolute;
      top: 50%;
      width: .33em; }
    .paragraphs-item-component .field-name-field-link a:hover {
      color: #941b80 !important;
      text-decoration: none;
      border-color: #941b80; }
      .paragraphs-item-component .field-name-field-link a:hover:before, .paragraphs-item-component .field-name-field-link a:hover:after {
        border-color: #941b80; }
    .paragraphs-item-component .field-name-field-link a:after {
      left: 0; }
    .paragraphs-item-component .field-name-field-link a:before {
      right: 0; }

.entity-bean > h2 {
  display: none; }

.bean-default .field-name-field-link a {
  border: 2px solid #009eb2;
  padding: .25em 1.5em;
  display: inline-block;
  font-weight: bold;
  color: #941b80 !important;
  position: relative;
  transition: all .25s ease;
  font-size: 18px;
  background: transparent !important; }
  .bean-default .field-name-field-link a:before, .bean-default .field-name-field-link a:after {
    content: '';
    border-top: 2px solid #009eb2;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    width: .33em; }
  .bean-default .field-name-field-link a:hover {
    color: #941b80 !important;
    text-decoration: none;
    border-color: #941b80; }
    .bean-default .field-name-field-link a:hover:before, .bean-default .field-name-field-link a:hover:after {
      border-color: #941b80; }
  .bean-default .field-name-field-link a:after {
    left: 0; }
  .bean-default .field-name-field-link a:before {
    right: 0; }

.pane-bean-cta {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important; }

.menu-name-menu-social-media .menu {
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0; }
  .menu-name-menu-social-media .menu li {
    display: inline-block;
    margin: 1em 0 0; }
    .menu-name-menu-social-media .menu li a {
      padding: 0 1em; }
      .menu-name-menu-social-media .menu li a.sm {
        font-size: 0; }
        .menu-name-menu-social-media .menu li a.sm:before {
          font-family: "icons";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          text-align: center;
          /* opacity: .8; */
          /* For safety - reset parent styles, that can break glyph codes*/
          font-variant: normal;
          text-transform: none;
          /* fix buttons height, for twitter bootstrap */
          font-size: 1rem;
          line-height: 1;
          /* Animation center compensation - margins should be symmetric */
          /* remove if not needed */
          /* you can be more comfortable with increased icons size */
          /* font-size: 120%; */
          font-size: 1rem;
          /* Font smoothing. That was taken from TWBS */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          /* Uncomment for 3D effect */
          /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
          font-size: 1.5rem;
          border-radius: 32px;
          width: 32px;
          height: 32px;
          line-height: 32px;
          display: inline-block;
          background: #009eb2;
          color: #fff;
          transition: all .25s ease; }
        .menu-name-menu-social-media .menu li a.sm:hover {
          text-decoration: none; }
          .menu-name-menu-social-media .menu li a.sm:hover:before {
            background: #941b80; }
        .menu-name-menu-social-media .menu li a.sm.facebook:before {
          content: ""; }
        .menu-name-menu-social-media .menu li a.sm.twitter:before {
          content: ""; }
        .menu-name-menu-social-media .menu li a.sm.youtube:before {
          content: ""; }

.block {
  clear: both; }
  .sidebar .block, .block.sidebar {
    margin: 20px;
    border: 2px solid #941b80;
    background: #fff; }
    .sidebar .block .pane-title, .sidebar .block .block-title, .block.sidebar .pane-title, .block.sidebar .block-title {
      padding: 5px;
      font-size: 1.2em;
      border-bottom: 1px dashed #941b80;
      margin-bottom: 2px; }
    .sidebar .block .block-content, .block.sidebar .block-content {
      padding: 5px; }
      .sidebar .block .block-content .views-row, .block.sidebar .block-content .views-row {
        border-bottom: 1px dashed #941b80;
        padding: 10px 5px;
        font-size: .9em; }
        .sidebar .block .block-content .views-row a, .block.sidebar .block-content .views-row a {
          color: #000;
          font-weight: bold; }
  .block.plain {
    border: none;
    background: none; }
    .block.plain .pane-title {
      padding: 0; }

/**
 * "Search form" block
 */
.block-search {
  display: block;
  background-color: #009eb2;
  height: 32px;
  margin: 0 0 10px 0;
  position: relative; }
  .block-search .container-inline {
    overflow: hidden; }
    .block-search .container-inline label,
    .block-search .container-inline div {
      float: left;
      display: block;
      margin-top: 0;
      margin-bottom: 0; }
    .block-search .container-inline .form-item-custom-search-types {
      display: none !important;
      position: relative;
      z-index: 2;
      display: inline-block;
      padding: 0 .3em;
      line-height: 32px;
      float: left;
      border-right: 1px solid #fff;
      width: 64px; }
      .block-search .container-inline .form-item-custom-search-types .select-status {
        color: #fff; }
        .block-search .container-inline .form-item-custom-search-types .select-status:after {
          border-top-color: #fff; }
  .block-search .block-inner {
    margin-left: 0;
    margin-right: 0; }
  .block-search .form-item-search-block-form {
    box-sizing: border-box;
    float: left;
    width: 100%;
    margin: -32px -64px;
    padding: 0 32px 0 64px;
    line-height: 32px; }
  .block-search .form-text {
    width: 100%;
    max-width: 100%;
    color: #fff;
    background: transparent;
    border: none; }
    .block-search .form-text:focus {
      box-shadow: none; }
  .block-search .form-actions {
    position: absolute;
    right: 0;
    z-index: 2; }
  .block-search .form-actions,
  .block-search .form-submit {
    box-sizing: border-box;
    width: 32px;
    height: 32px; }
  .block-search .form-submit {
    font-size: 0;
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none; }
    .block-search .form-submit:before {
      font-family: "icons";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      font-size: 1rem;
      line-height: 1;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      font-size: 1rem;
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      content: "";
      font-size: 1rem;
      width: 32px;
      height: 32px;
      line-height: 32px;
      color: #fff; }
  @media only screen and (max-width: 768px) {
    .block-search {
      display: none; } }

.rate-widget-commons_thumbs_up_down {
  width: 6em;
  text-align: center; }
  .rate-widget-commons_thumbs_up_down .rate-info {
    font-size: 1em;
    font-weight: bold; }

.commons-q-a-rate-buttons .commons-q-a-rate-trigger {
  float: left;
  width: 50%; }

.commons-q-a-rate-buttons .rate-button {
  font-size: 0; }
  .commons-q-a-rate-buttons .rate-button:before {
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    font-size: 1rem;
    line-height: 1;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    font-size: 1rem;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    font-size: 1.2rem; }
  .commons-q-a-rate-buttons .rate-button.rate-number-up-down-btn-up:before {
    content: ""; }
  .commons-q-a-rate-buttons .rate-button.rate-number-up-down-btn-up.rate-voted:before {
    content: ""; }
  .commons-q-a-rate-buttons .rate-button.rate-number-up-down-btn-down:before {
    content: ""; }
  .commons-q-a-rate-buttons .rate-button.rate-number-up-down-btn-down.rate-voted:before {
    content: ""; }

/**
 * @file
 * Style overrides for the Commons Media feature.
 */
.field-name-field-media-form {
  margin-bottom: 10px; }

.field-name-field-media .field-add-more-submit,
#quicktabs-container-commons_bw .quicktabs-tabpage .field-name-field-media .field-add-more-submit {
  margin: 10px 0; }

table[id^="field-media-values"],
table[id^="field-media-values"] thead,
table[id^="field-media-values"] tbody,
table[id^="field-media-values"] tr,
table[id^="field-media-values"] th,
table[id^="field-media-values"] td {
  background-color: transparent;
  border: none; }

table[id^="field-media-values"] th {
  padding: 0; }
  table[id^="field-media-values"] th label {
    margin: 0; }

table[id^="field-media-values"] td.field-multiple-drag {
  width: 20px; }

table[id^="field-media-values"] a.tabledrag-handle .handle {
  margin: 0; }

table[id^="field-media-values"] .form-item {
  padding-left: 6.875em; }

table[id^="field-media-values"] .media-widget .button {
  display: block; }
  @media only screen and (min-width: 769px) {
    table[id^="field-media-values"] .media-widget .button {
      max-width: 50%; } }

.form-type-media .preview {
  background: sprite(sprite-map("icons/*.png", 20px), picture) no-repeat;
  width: image-width(sprite-file(sprite-map("icons/*.png", 20px), picture));
  height: image-height(sprite-file(sprite-map("icons/*.png", 20px), picture));
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px;
  margin-right: 10px; }

@media only screen and (max-width: 480px) {
  .form-type-media .preview {
    display: block;
    float: none;
    margin: 10px auto; }
  .form-type-media .button,
  .form-type-media input[type="submit"] {
    display: block;
    float: none;
    margin: 10px auto;
    width: 100%; } }

.media-wrapper {
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 1px solid #555555;
  min-width: 100%;
  max-width: 100%;
  box-shadow: 0 0 0 0.5625em rgba(0, 0, 0, 0.2);
  box-sizing: border-box; }
  .media-wrapper,
  .media-wrapper .ui-corner-all {
    border-radius: 0; }
  .media-wrapper .ui-dialog-title {
    display: block;
    margin: 0;
    line-height: 1.5em;
    font-size: 2.25em;
    font-family: "Helvetica Neue", helvetica, "Segoe UI", segoe, "Ubuntu", "Droid Sans", "Arial", sans-serif;
    font-weight: 200; }
  .media-wrapper .ui-dialog-titlebar {
    position: relative;
    padding: 0.70711em 1em 0;
    border: none;
    color: #848484;
    background: #303030; }
  .media-wrapper .ui-dialog-titlebar-close {
    position: absolute;
    float: none;
    display: block;
    top: 0.35355em;
    right: 0.35355em;
    width: 1em;
    height: 1em;
    overflow: hidden;
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 1em;
    color: white;
    background: none;
    font-weight: normal; }
    .media-wrapper .ui-dialog-titlebar-close:hover {
      text-decoration: none;
      border: none; }
    .media-wrapper .ui-dialog-titlebar-close:before {
      content: "\2715"; }
  .media-wrapper .ui-dialog-content {
    position: relative;
    border: 0;
    background: none;
    overflow: auto;
    zoom: 1; }
  .media-wrapper .ui-dialog-buttonpane {
    text-align: left;
    border-width: 1px 0 0 0;
    background-image: none;
    margin: .5em 0 0 0;
    padding: .3em 1em .5em .4em; }
    .media-wrapper .ui-dialog-buttonpane .ui-dialog-buttonset {
      float: right; }
    .media-wrapper .ui-dialog-buttonpane button {
      margin: .5em .4em .5em 0;
      cursor: pointer; }
  .media-wrapper .ui-resizable-se {
    width: 14px;
    height: 14px;
    right: 3px;
    bottom: 3px; }
  .media-wrapper .ui-draggable .ui-dialog-titlebar {
    cursor: move; }
  @media (min-width: 22.85714em) {
    .media-wrapper {
      min-width: 0; } }
  @media (min-width: 28.57143em) {
    .media-wrapper .ui-dialog-titlebar {
      padding-right: 2em;
      padding-left: 2em; } }

.view-commons-wikis-contributor-list .views-row {
  /*overflow: hidden;
    img {
      float: left;
      margin-right: 5em;
    }
    span {
      float: left;
      line-height: 50px;
    }*/ }
