.view-commons-wikis-contributor-list {
  .views-row {
    /*overflow: hidden;
    img {
      float: left;
      margin-right: 5em;
    }
    span {
      float: left;
      line-height: 50px;
    }*/
  }
}