$icon-size: 32px;
.flexslider {
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: none;
  .slides {
    margin: 0;
    padding: 0;
  }
}

&.optionset-default {
  background: none;

}

.flex-control-paging {
  display: none;
  @include respond(medium) {
    display: block;
  }
}

.flex-direction-nav {
  display: block;
  a {
    display: block;
    font-size: 0;
    width: $icon-size;
    height: $icon-size;
    margin-top: -$icon-size/2;
    text-align: center;
    opacity: 1;
    line-height: 1;
    &:before {
      color: $white;
    }
    &:before {
      @include icon;
      //content: $icon-angle-right;
      //@include rtlcss_prop_value('content', $icon-angle-right, $icon-angle-left);
      font-size: $icon-size;
      margin: 0;
      width: 100%;
      color: $white;
      display: block;

    }
    &.flex-disabled {
      opacity: 0;
    }
    &.flex-next {
      right: .5rem;
      left: auto;
      float: left;
      &:before {
        content: $icon-angle-right #{"/*rtl:"#{$icon-angle-left}"*/"};

      }
    }
    &.flex-prev {
      left: .5rem;
      right: auto;
      float: right;

      &:before {
        //content: $icon-angle-left;
        //@include rtlcss_prop_value('content', $icon-angle-left, $icon-angle-right);
        //content: $icon-angle-left #{"/*rtl:#{$icon-angle-right}*/"};
        content: $icon-angle-left #{"/*rtl:"#{$icon-angle-right}"*/"};
      }
    }
  }
  @include respond(medium) {
    //display: none;
  }

  .flex-control-paging {
    li {

      a {
        background-color: rgba($black, .3);
        &.flex-active {
          background-color: $primary-color;
        }
      }
    }

  }
}