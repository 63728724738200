.node-form {
  .field-name-body {
    .fieldset-outer-wrapper {
      display: none;
    }
  }
  .field-name-og-group-ref {
    legend, .fieldset-description {
      display: none;
    }
  }

  .field-name-field-date-form {
    .end-date-wrapper {
      position: relative;
      top: -2.7em;
    }
  }
}