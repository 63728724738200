/**
 * @file
 * Style overrides for the Commons Media feature.
 */

//@import "base";

.field-name-field-media-form {
  margin-bottom: $gutter-width;
}

.field-name-field-media .field-add-more-submit {
  &,
  #quicktabs-container-commons_bw .quicktabs-tabpage & {
    margin: $gutter-width 0;
  }
}

table[id^="field-media-values"] {
  &,
  thead,
  tbody,
  tr,
  th,
  td {
    background-color: transparent;
    border: none;
  }

  th {
    padding: 0;

    label {
      margin: 0;
    }
  }

  td {
    &.field-multiple-drag {
      width: 20px;
    }
  }

  a.tabledrag-handle .handle {
    margin: 0;
  }

  .form-item {
    padding-left: px-to-em(100px + $gutter-width);
  }

  .media-widget {
    .button {
      display: block;

      @include respond(large) {
        max-width: 50%;
      }
    }
  }
}

.form-type-media {
  .preview {
    @include sprite_url_height_width($icons, picture);
    margin-right: $gutter-width;
  }

  @include respond(only-small) {
    .preview {
      display: block;
      float: none;
      margin: 10px auto;
    }

    .button,
    input[type="submit"] {
      display: block;
      float: none;
      margin: 10px auto;
      width: 100%;
    }
  }
}

// Override the default jQuery Dialog to match styles of Ember admin theme.
// Once commons has a native style built and the module is set to use Commons
// Origins, this can be removed.
.media-wrapper {
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 1px solid #555555;
  min-width: 100%;
  max-width: 100%;
  box-shadow: 0 0 0 0.5625em rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  &,
  .ui-corner-all {
    border-radius: 0;
  }

  .ui-dialog-title {
    display: block;
    margin: 0;
    line-height: 1.5em;
    font-size: 2.25em;
    font-family: "Helvetica Neue", helvetica, "Segoe UI", segoe, "Ubuntu", "Droid Sans", "Arial", sans-serif;
    font-weight: 200;
  }

  .ui-dialog-titlebar {
    position: relative;
    padding: 0.70711em 1em 0;
    border: none;
    color: #848484;
    background: #303030;
  }

  .ui-dialog-titlebar-close {
    position: absolute;
    float: none;
    display: block;
    top: 0.35355em;
    right: 0.35355em;
    width: 1em;
    height: 1em;
    overflow: hidden;
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 1em;
    color: white;
    background: none;
    font-weight: normal;

    &:hover {
      text-decoration: none;
      border: none;
    }

    &:before {
      content: "\2715";
    }
  }

  .ui-dialog-content {
    position: relative;
    border: 0;
    background: none;
    overflow: auto;
    zoom: 1;
  }

  .ui-dialog-buttonpane {
    text-align: left;
    border-width: 1px 0 0 0;
    background-image: none;
    margin: .5em 0 0 0;
    padding: .3em 1em .5em .4em;

    .ui-dialog-buttonset {
      float: right;
    }

    button {
      margin: .5em .4em .5em 0;
      cursor: pointer;
    }
  }

  .ui-resizable-se {
    width: 14px;
    height: 14px;
    right: 3px;
    bottom: 3px;
  }

  .ui-draggable .ui-dialog-titlebar {
    cursor: move;
  }

  @media (min-width: 22.85714em) {
    min-width: 0;
  }

  @media (min-width: 28.57143em) {
    .ui-dialog-titlebar {
      padding-right: 2em;
      padding-left: 2em;
    }
  }
}
