.view.listing{
  .view-content{
    border-bottom: 2px dashed $border;
  }
  .views-row {
   //    border-bottom: 1px dashed $border;
    a {
      color: $black;
      font-weight: bold;
    }
    &.views-row-first {
    }
    &.views-row-last {
    }
  }
}