.node-title.page-title {
  @include page-title();
}

.node {
  .field-label-inline {
    overflow: hidden;
    .field-label {
      float: left;
      margin: 0;

    }
    .field-items {
      float: left;
    }
  }

  &.node-full {

    .page-title {

      @include page-title();
    }
    position: relative;

    .field-name-body {
      margin-top: 0;
    }

    .commons-pod {
      clear: both;
    }
    > nav {
      clear: both;
      border-top: 2px dashed $border;
      margin: 0;

      .links.inline {
        text-align: left;
        margin-top: 0;
        float: right;

        li {
          text-align: left;
          //margin-left: .5em;
          float: left;
          margin: .5em;
        }
      }
      > .flag-wrapper {
        text-align: left;
        float: right;
        margin: .5em;
        clear: none;
      }
    }
    .node-content, .submitted {
      padding: 0 1em;
      @include respond(large) {
        //padding-right: 25%;
      }
    }

    .comment-wrapper {
      padding: 1em;
      border-top: 2px dashed $border;
      border-bottom: 2px dashed $border;
    }
    //like button
    .node-content {
      @include clearfix;
      padding-bottom: 1em;

      > .form-type-item {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        > label {
          display: none;
        }
      }
    }

    //follow button
    .flag-commons_follow_node {
      position: absolute;
      top: -2em;
      right: 0;
    }
  }

  &.node-teaser {
    overflow: hidden;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 2px dashed $border;

    footer {
      display: table-cell;
      width: 25%;
      padding: 1em;
      text-align: center;
      vertical-align: top;
      border-right: 2px dashed $border;
    }
    .node-content {
      width: 75%;
      display: table-cell;
      vertical-align: top;
      padding: 1em;
      .node-title {
        color: $secondary-color;
      }
    }

    .group-text {
      float: left;
      width: 75%;
    }
    .group-image {
      float: left;
      width: 25%;
      padding-right: 1em;
      box-sizing: border-box;
    }
  }
  .pane-node-commons-groups-group-contributors-count-topics {
    font-weight: bold;
    color: $black;
    a {
      color: inherit;
    }

  }
}

.flag-inappropriate-node, .flag-inappropriate-comment {
  float: right;
}

.commons-bw-partial-node-form {
  position: relative;
  border-bottom: 2px dashed $border;
  margin-bottom: 0;
  padding-left: 68px;
  .user-picture {
    position: absolute;
    left: 0;
    top: 0;
  }

  &.compact-form {
    .form-item {
      margin: 0;
    }

    textarea {
      resize: none;
    }
  }

  .user-picture {
    margin-top: 2.6em;
  }

  @include respond(only-small) {
    padding-left: 0;

    .user-picture {
      display: none;
    }
  }

  .field-type-image {
    margin-top: 1.7em;
  }

  .field-name-og-group-ref {
    .fieldset-outer-wrapper {
      margin-top: 1.7em;
      margin-bottom: 1.7em;
      padding-top: 1.7em;
      border-top: 1px solid $border;
    }
  }
}

.page-node {
  #page-title {
    display: none;
  }
}