.node-event {
  .commons-events-form-float {
    float: none;
    margin: 0 0 1em;
    padding: 0;
    width: auto;
  }
  &.node-teaser {
    .commons-events-form-float {
      display: none;
    }
  }
}

.view-display-id-commons_events_upcoming_page .views-row {
  @extend %pod;

  article {
    @extend %pod-content;

    .commons-events-form-float {
      float: right;
      margin: -40px 0 0 0;
    }
  }
}

.commons-events-form-float {
  float: right;
  margin-left: 15px;

  input {
    margin: 0;
  }
}

.commons-event-count {
  font-size: 1.2em;
  font-weight: bold;
}

form.commons-events-form-float {

  box-sizing: border-box;
  float: none;
  margin: 1em 0;
  padding-left: 0;
  width: auto;
  display: none;
  text-align: left;
  .commons-event-status {
    font-size: .88em;
    line-height: 1.4em;
    margin-top: .3em;
  }

  .form-text {
    width: auto;
  }

}