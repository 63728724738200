.rate-widget-commons_thumbs_up_down {
  width: 6em;
  text-align: center;

  .rate-info {
    font-size: 1em;
    font-weight: bold;
  }
}

.commons-q-a-rate-buttons {

  .commons-q-a-rate-trigger {
    float: left;
    width: 50%;
  }
  .rate-button {
    font-size: 0;

    &:before {
      @include icon;
      font-size: 1.2rem;
    }

    &.rate-number-up-down-btn-up {
      &:before {
        content: $icon-thumbs-up;
      }
      &.rate-voted {
        &:before {
          content: $icon-thumbs-up-alt;
        }
      }
    }

    &.rate-number-up-down-btn-down {
      &:before {
        content: $icon-thumbs-down;
      }
      &.rate-voted {
        &:before {
          content: $icon-thumbs-down-alt;
        }
      }
    }
  }
}