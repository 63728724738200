@font-face {
    font-family: 'Nassim';
    src:url('../fonts/nassim/Nassim.woff') format('woff'),
        url('../fonts/nassim/Nassim.ttf') format('truetype'),
        url('../fonts/nassim/Nassim.eot'),
        url('../fonts/nassim/Nassim.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Nassim';
    src:url('../fonts/nassim/NassimBold.woff') format('woff'),
        url('../fonts/nassim/NassimBold.ttf') format('truetype'),
        url('../fonts/nassim/NassimBold.eot'),
        url('../fonts/nassim/NassimBold.eot?#iefix') format('embedded-opentype');
    font-weight: bold;
    font-style: normal;
}
