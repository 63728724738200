/**
 * Generic styles for the more link
 */

.more-link {
  float: right;
  overflow: hidden;

  a {
    color: $light-gray;
    text-decoration: none !important;
    font-size: 0;
    line-height: 0;
    float: right;
    text-align: right;
    width: 100%;
    &:after {
      @include icon;
      content: $icon-angle-double-left;
      font-size: 2rem;
      line-height: 32px;
    }

  }
}

.item-list-pager {
  .pager-load-more {
    margin: 0;
    padding: 1em 0;
    li {
      padding: 0;
    }
    a {
      @include cta($black, $black);
    }
  }

}

.view {
  .view-content {

  }
}

.attachment-before {
  border-bottom: 2px dashed $border;
  padding: 0 5px;
}

.view-filters {
  background: $ultralight;
  border-radius: 2px;
  border-bottom: 2px dashed $border;
  display: block;
  clear: both;
  vertical-align: top;
  margin-bottom: 0;
  font-size: .88em;
  form {
    margin: 0;
    padding: 5px;
  }
  .views-exposed-widgets {
    margin: 0;
  }
  .views-exposed-widget {
    margin: 0 1em 0 0;
  }
  .views-widget-filter-keys {
    label {
      display: none;
    }
    .views-widget {
      box-sizing: border-box;
      width: 100%;
      max-width: 300px;
      .form-text {
        color: $white;
        background: $light-gray;
        border-radius: 5px;
        @include placeholder {
          color: $white;
          opacity: 9 !important;
        }
      }
    }
  }

  .views-widget-sort-order {
    display: none;
  }

  .view-content,
  .view-empty {
    clear: both;
  }

  .form-submit {
    margin-top: 0;
    margin-bottom: .1em;
  }

  .hidden {
    display: none;
  }
}

.dynamic-filter-lists {
  .views-exposed-widget,
  .views-widget,
  label,
  .form-select-wrapper {
    display: inline-block;
    line-height: 1.7em;
    vertical-align: baseline;
    white-space: nowrap;
    // height: 1.7em;
    // overflow: hidden;
  }

  .views-exposed-widget {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;

    label {
      font-weight: normal;
      color: $light_gray;
      margin: 0;
    }
  }

  .views-submit-button {
    position: absolute;
    visibility: hidden;
    padding: 0;
    height: auto;

    .form-submit {
      margin-top: 0;
    }
  }
}

#edit-following-wrapper {
  display: none;
}