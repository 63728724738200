/**
 * User profile
 */

.page-user {
  .panel-display {
    .region-three-33-top {
      padding-left: 120px + $gutter_width * 3;

      @include respond(only-small) {
        padding-left: $gutter_width;
      }

      /* This feels hackish, but it is only for administrating the layout of the
         page. It is still not ideal as it is inflexible and only works with the
         current core functionality of commons. */
      .panels-ipe-portlet-wrapper {
        &:nth-last-child(-n+5) {
          display: inline-block;
        }
      }
    }
  }

  .pane-user-picture {
    float: left;
    margin-right: 1em;
    //margin-left: -(120px + $gutter_width * 2);

    @include respond(only-small) {
      float: none;
      margin-right: 0;
      text-align: center;
    }
  }

  .pane-user-field-facebook-url,
  .pane-user-field-linkedin-url,
  .pane-user-field-twitter-url,
  .pane-user-group-group,
  .pane-user-flag-commons-follow-user-link {
    &,
    a {
      display: inline-block;
    }
  }

  .pane-user-field-facebook-url,
  .pane-user-field-linkedin-url,
  .pane-user-field-twitter-url {
    a {
      margin-right: 1em;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
      }
    }

    @include respond(only-small) {
      a {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
      }

      a {
        &:before {
          content: normal;
        }
      }
    }

    @include respond(only-medium) {
      display: block;
    }
  }

  .pane-user-field-facebook-url {
    a {
      &:before {
        @include sprite_url_height_width($icons, facebook);
      }
    }

    @include respond(only-small) {
      a {
        @include sprite_url_height_width($icons, facebook);
      }
    }
  }

  .pane-user-field-linkedin-url {
    a {
      &:before {
        @include sprite_url_height_width($icons, linkedin);
      }
    }

    @include respond(only-small) {
      a {
        @include sprite_url_height_width($icons, linkedin);
      }
    }
  }

  .pane-user-field-twitter-url {
    a {
      &:before {
        @include sprite_url_height_width($icons, twitter);
      }
    }

    @include respond(only-small) {
      a {
        @include sprite_url_height_width($icons, twitter);
      }
    }
  }

  .pane-user-flag-commons-follow-user-link {
    clear: both;
  }

  .pane-views-panes {
    @extend %pod;

    .block-inner {
      @extend %pod-content;
    }
  }
}

/**
 * Profile listings
 */
.profile-badges {

  overflow: hidden;
  .profile-badge {
    float: left;
    list-style-type: none;
    text-align: center;
    box-sizing: border-box;
    padding: 0 2px;
    /*border-bottom: 1px dashed $border;
    padding: $gutter_width 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    &:last-child {
      border-bottom: none;
    }*/

    .profile-badge-picture {
      /*display: table-cell;
      width: 48px;
      vertical-align: middle;
      padding-right: 5px;
      text-align: center;
      img {
        max-width: 48px;
        height: auto;
      }*/
    }

    .profile-badge-name {

      display: none;
      line-height: 1.2em;
      font-size: .88em;
      margin-top: .6em;
      vertical-align: middle;
      a {
        color: $black;
      }

    }
  }
}

.profile {
  border-bottom: 2px solid $border;
  .actions {
    display: none;
  }

  .field-name-realname {
    margin: 0 !important;
    .field-label {
      display: none;
    }
    .field-item {
      @include page-title();
    }
  }

  .user-picture {
    float: none !important;
    margin: 0;
  }
}

.view-commons-groups-user-groups {
  .views-row {
    overflow: hidden;
    .views-field-field-group-logo {
      float: left;
      margin-right: .5em;
      img {
        border-radius: 100%;
      }
    }
  }
}

.pane-commons-activity-streams-user-activity-panel-pane-1 {
  padding: 1em;
  .pane-title {
    text-align: center;
    font-size: 1.8em;
    border-bottom: 2px dashed $border;
  }
  .view-content {
    border-top: 2px dashed $border;
  }
  .views-row {
    padding: 1em;
    overflow: hidden;
    border-bottom: 1px dashed $border;
  }
}