/**
 * Footer wrapper
 */

#footer {
  border-top: 2px dashed $secondary-color;
  padding: 1em 0 0;
  @include wide;

  .region-footer {
    padding: 1em 0;
    border-top: 2px dashed $secondary-color;

    .block {
      @include row;
    }
  }
}

#l10n-client {
  z-index: 100000;
}
