// @file
// General logic for responsive layouts.

$screen-small: 480px !default;
$screen-large: 768px !default;

@mixin respond($media) {
  @if $media == only-small {
    @media only screen and (max-width: $screen-small) { @content; }
  }
  @else if $media == small-medium {
    @media only screen and (max-width: $screen-large) { @content; }
  }
  @else if $media == only-medium {
    @media only screen and (min-width: $screen-small + 1) and (max-width: $screen-large) { @content; }
  }
  @else if $media == medium {
    @media only screen and (min-width: $screen-small + 1) { @content; }
  }
  @else if $media == large {
    @media only screen and (min-width: $screen-large + 1) { @content; }
  }
}
