@mixin wide() {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@mixin row() {
  max-width: 1280px;
  margin: 0 auto;
}

@mixin bg(){
  background-size: 12px 12px !important;
  background-image: linear-gradient(45deg, transparent 49%, $bg_gray 50%, $bg_gray 51%, transparent 52%);
}