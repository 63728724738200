%divider-left {
  border-left: 1px solid darken($primary-color, 3%);
  //box-shadow: darken($primary-color, 13%) -1px 0px 0px;
}

%divider-right {
  border-right: 1px solid darken($primary-color, 13%);
  //box-shadow: darken($primary-color, 3%) 1px 0px 0px;
}

%pod {
  //background-color: $white;
  //border: 1px solid $bg_gray;
  //border-radius: 3px;
  //box-shadow: $light_gray 1px 1px 4px;
  margin: 0 0 $gutter-width;
}

%pod-content {
  padding: 0;//$gutter-width;
}

%node-space-right {
  > .node-content {
    margin-right: 10em;

    @include respond(small-medium) {
      margin-right: 0;
    }
  }
}
