/**
 * Wraps all header elements - branding and .region-header
 */

.header-wrapper {
  overflow: hidden;
}

#header {
  position: relative;
  box-sizing: border-box;
  text-align: left;
  padding-right: $gutter_width;
  padding-left: $gutter_width;
  white-space: nowrap;

  @include respond(small-medium) {
    padding: 0;
  }

  display: table;
  table-layout: fixed;

}

/* =============================================================================
 *   Branding
 * ========================================================================== */

/**
 * Wraps all the branding elements, logo, name and slogan
 */

#branding {
  box-sizing: border-box;
  text-align: left;
  position: relative;

  width: 25%;
  display: table-cell;
  padding-top: $gutter_width;
  vertical-align: bottom;
  .people-search-active & {

    @include respond(only-small) {
    }

    @include respond(only-medium) {
    }
  }
}

/**
 * Logo
 */

#logo {
  position: relative;
  display: block;
  z-index: 3;
  width: 75%;
  margin: 0 auto;
  background: $page;
  .slogan {
    display: block;
    background: $white;

  }
  @include respond(large) {

    //left: -$gutter_width;

  }
}

/**
 * Wrapper for the site name and slogan (hgroup)
 */

#name-and-slogan {
  display: block;
  box-sizing: border-box;

  @include respond(only-small) {
    display: none;
  }

  .people-search-active & {

    @include respond(only-medium) {
    }
  }
}

/**
 * Site name (h1)
 */

#site-name {
  margin: .3em 0 0;
  font-family: 'Trebuchet MS';
  font-weight: 700;

  a {
    &:link,
    &:visited {
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  @include respond(medium) {
    font-size: 1.4em;
  }
}

/**
 * Site slogan (h2)
 */

#site-slogan {
  margin: 0 0 .3em;
  font-size: 1em;
  color: $white;
}

#site-name,
#site-slogan {
  display: block;
  overflow: hidden;
  padding-right: $gutter_width;
  line-height: 1.2em;
  white-space: normal;
}

/* =============================================================================
 *   Page content header
 * ========================================================================== */

/**
 * The main page title (h1)
 */

#page-title,
.search-results-title {
  margin: 0 0 .5em;
  font-weight: bold;
  font-size: 2.75em;
  line-height: 1.2em;
  color: $black;
  text-align: left;
}

.page-messages-view,
.page-flag-confirm {
  #page-title {
    /* Some pages require a smaller title to prevent the equivalent of
       "yelling". */
    font-size: 2em;
  }
}

#page-top {
  margin: 20px 0 0;
}

#header {
  #menu-bar.nav, .region-header {

  }
  #menu-bar.nav {
    border-top: 2px solid $border;
    clear: both;
    width: 100%;
  }
  .region-header {
    max-width: 320px;
    min-width: 240px;
    width: 50%;
    float: right;
  }
  .nav-header {
    box-sizing: border-box;

    width: 75%;
    display: table-cell;
    vertical-align: bottom;

  }
}

/**
 * Header region, embedded in the #header
 */

.region-header {

}