$icon-size: 32px;
.menu-name-menu-social-media {
  .menu {
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin: 1em 0 0;
      a {
        padding: 0 1em;
        &.sm {

          font-size: 0;
          &:before {
            @include icon;
            font-size: 1.5rem;
            border-radius: $icon-size;
            width: $icon-size;
            height: $icon-size;
            line-height: $icon-size;
            display: inline-block;
            background: $primary-color;
            color: $white;
            transition: all .25s ease;
          }
          &:hover {
            text-decoration: none;
            &:before {
              background: $secondary-color;
            }
          }

          &.facebook {
            &:before {
              content: $icon-facebook;
            }
          }

          &.twitter {
            &:before {
              content: $icon-twitter;
            }
          }

          &.youtube {
            &:before {
              content: $icon-youtube-play;
            }
          }
        }
      }
    }
  }
}