.pane-commons-bw-group {
  @extend %pod;

  > .block-inner {
    @extend %pod-content;
  }
}

.pane-commons-groups-commons-groups-create-group {

  text-align: center;

  a {
    @extend %button-common;
    @extend %button-default-layout;
    @extend %button-primary;
    margin: 0;

    @include respond(only-small) {
      float: none;
      display: block;
    }
  }
}

