/* =============================================================================
 *   Primary, Secondary and Menu Bar region menus
 * ========================================================================== */

#primary-menu-bar {
  display: inline-block;
}

/**
 * Use one of the following id's for granular control:
 *  - #menu-bar (menu bar region)
 *  - #primary-menu (main menu)
 *  - #secondary-menu (secondary/user menu)
 * You can target all of these with .nav or div[id$="menu-bar"]
 */

#menu-bar.nav {

  h3.block-title {
    display: none;
  }

  ul.menu {
    margin: 0;
    padding: 0;
  }

  ul.menu li {
    display: inline-block;
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;

    &:hover,
    &.active-trail {
      .arrow {
        &:before {
          content: "";
          display: block;
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 0 7px 8px 7px;
          border-color: transparent transparent lighten($primary-color, 10%) transparent;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0px;
          left: 1px;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 0 6px 6px 6px;
          border-color: transparent transparent $primary-color transparent;
        }
      }
    }

    a {
      position: relative;
      z-index: 1;
      box-sizing: border-box;
      display: block;
      white-space: nowrap;
      padding: 0 1em;
      margin-top: $gutter_width;
      line-height: 1;
      color: $black;
      font-size: 1.33em;
      font-weight: bold;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -$gutter_width/2;
        height: $gutter_width;
        width: 3px;
        background: $border;

      }
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: $secondary-color;
      }

      &.active {
        color: $secondary-color;
      }

      .arrow {
        width: 17px;
        height: 9px;
        position: absolute;
        left: 50%;
        bottom: -1px;
        margin: 0 0 0 -8px;
      }
    }
    &:first-child {
      a {
        padding-left: 0;
        &:after {
          display: none;
        }
      }
    }
  }

  .block {
    margin: 0;
  }

  @include respond(only-small) {
    border-right: 0;

    ul.menu li {
      a {
        font: 0/0 a;
        width: 40px;
        height: 50px;
      }
    }
  }

  @include respond(only-medium) {
    border-right: none;

    ul.menu li {
      a {
        padding-right: px-to-em(7px);
        padding-left: px-to-em(7px);
      }
    }
  }
}

.main-menu-home a {
  //background: url('../img/icons/icon-home.png') no-repeat center 10px;
}

.main-menu-groups a {
  //background: url('../img/icons/icon-groups.png') no-repeat center 10px;
}

.main-menu-events a {
  //background: url('../img/icons/icon-events.png') no-repeat center 10px;
}

.main-menu-people a {
  //background: url('../img/icons/icon-people.png') no-repeat center 10px;
}

#menu-bar.nav ul.menu li.main-menu-search {
  display: none;

  a {
    //background: url('../img/icons/icon-search.png') no-repeat center 10px;
  }

  @include respond(small-medium) {
    display: inline-block;
  }
}

#block-commons-utility-links-commons-utility-links {
  margin: 0;

  .block-inner {
    margin: 0;
  }
}

ul.commons-utility-links {
  list-style: none;
  text-align: right;
  margin: .2em 0;
  padding: 0;
  line-height: 1;

  .block-content & {
    padding: 0;
  }

  li {
    display: inline-block;

    &.first {
      .logged-in & {
        margin-right: 17px;
        font-weight: bold;
        float: left;
      }
    }

    &.last {
      border-left: 1px solid $primary-color;
      margin-left: 9px;

      a {
        margin-left: 9px;
      }
    }
  }
}

.unread-invitations {
  a {
    @include sprite_url_height_width($icons, icon-person);
  }
}

.unread-messages,
.no-unread-messages {
  a {
    //@include sprite_url_height_width($icons, icon-envelope);
  }
}

.unread-invitations,
.unread-messages,
.no-unread-messages {
  a {
    position: relative;
    top: .3em;
    display: inline-block;
    margin-top: -.2em;
    margin-right: .8em;
  }
}

.no-unread-messages {
  a {
    /*top: -.1em;
    margin-top: 0;
    text-indent: -100%;
    white-space: nowrap;
    overflow: hidden;
    @include opacity(.4);*/
    display: none;
  }
}

.notification-count {
  position: absolute;
  top: -.2em;
  right: -.6em;
  display: inline-block;
  padding: 0 .2em;
  font-size: .88em;
  border-radius: .4em;
  box-shadow: 0 0 2px $primary-color;
  background: $green;
  //@include background(linear-gradient($green, darken($green, 20)));
}

/* =============================================================================
 *   Superfish
 * ========================================================================== */

ul.sf-menu {
  margin-bottom: 0;

  a {
    border-left: 0;
    border-top: 0;
    padding: 0 10px;
    text-decoration: none;
    height: 2.5em;
    line-height: 2.5em;
  }

  li {
    &:hover,
    &.sfHover {
      outline: 0;
    }
  }

  a {
    &:focus,
    &:hover,
    &:active {
      outline: 0;
    }
  }
}

/**
 * Superfish blocks
 */

.block-superfish {
  ul {
    margin: 0 !important;
    padding: 0 !important;
  }

  li {
    margin: 0 !important;
    padding: 0 !important;
  }
}

/**
 * Vertical style
 */

.sf-vertical {
  width: 100%;

  li {
    width: 100%;

    &:hover ul,
    &.sfHover ul {
      left: 100%;
      top: 0;
      margin: 0;
      padding: 0;
    }

    a {
      padding: 0 10px;
    }
  }
}

/**
 * Navbar style
 */

.sf-navbar {
  padding-bottom: 0 !important;
}

/**
 * Sensible padding for the default style
 */

.sf-menu.sf-style-default a {
  padding: 0 10px;
}

/* =============================================================================
 *   Menus, usually blocks
 * ========================================================================== */

ul.menu {
  padding-left: 15px;

  ul {
    padding-left: 15px;

    li {
      margin: 0;
    }
  }
}

/* If li.content exists it's a problem, so reset the padding */
.block .menu li.content {
  padding: 0;
}

/* =============================================================================
 *   Book navigation menu
 * ========================================================================== */

.book-navigation {
  .page-up {
    /* Prevent text wrapping to a new line, assumes English "up" is used (two characters) */
    min-width: 2em;
    white-space: nowrap;
  }

  .menu {
    margin-left: 0;
  }
}