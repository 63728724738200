.bean-cta {
  .field-name-field-link {
    a {
      @extend %button-default-layout;
      @extend %button-default;
    }
  }
}

.pane-bean-cta {
  border: none!important;
  padding: 0!important;
  box-shadow: none!important;
}